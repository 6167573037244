import AmazonConnectContainer from "containers/AmazonConnectContainer";
import React from "react";
import { Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import { SContainer, SRow, SOutletContainer } from "./styles";
import { isMobile } from "react-device-detect";

// This is the component that renders the content of the main route component
const XLDashboardRightContainer = () => {
  return (
    <>
      <SContainer fluid>
        <div style={{ display: "flex", height: "-webkit-fill-available" }}>
          {!isMobile ? <AmazonConnectContainer /> : null}
          <SOutletContainer>
            <Outlet />
          </SOutletContainer>
        </div>
      </SContainer>
    </>
  );
};

export default XLDashboardRightContainer;
