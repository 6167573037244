const flattenObject = (obj: Record<string, any>, overrideDuplicateKeys = false) => {
  const result: Record<string, any> = {};

  for (const i in obj) {
    if (obj[i] && typeof obj[i] === "object" && !Array.isArray(obj[i]) && !obj[i].ref?.tagName) {
      const current = flattenObject(obj[i]);
      for (const j in current) {
        // no duplicate keys
        if (overrideDuplicateKeys) {
          result[j] = current[j];
        } else {
          result[i + "." + j] = current[j];
        }
      }
    } else {
      result[i] = obj[i];
    }
  }
  return result;
};

export default flattenObject;
