import React, { ReactNode } from "react";

export const recursiveMap = <T, C extends ReactNode>(
  children: C | C[],
  fn: (element: ReactNode) => T,
) => {
  return React.Children.map(children, (child): any => {
    if (!React.isValidElement(child)) {
      // if not valid element, just return it to not mess up
      return child;
    } else if (child.props.children) {
      // if it has children, first recursively apply map on children
      //@ts-ignore
      child = React.cloneElement(child, { children: recursiveMap(child.props.children, fn) });
    }

    return fn(child);
  });
};
