import GoogleMapReact from "google-map-react";
import React from "react";
import styled from "styled-components";
import { Coords } from "./types";

type Props = {
  apiKey: string;
  center: Coords;
  children: React.ReactNode;
  className?: string;
  zoom?: number;
};

const UnStyledGoogleMaps: React.FC<Props> = ({
  apiKey,
  center,
  children,
  className,
  zoom = 14,
}) => {
  return (
    <div className={className}>
      <GoogleMapReact bootstrapURLKeys={{ key: apiKey }} center={center} zoom={zoom}>
        {children}
      </GoogleMapReact>
    </div>
  );
};

export const GoogleMaps = styled(UnStyledGoogleMaps)`
  height: 100%;
  width: 100%;
`;
