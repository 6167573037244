import { IUser } from "xl-types";
import { createContext } from "react";
import AttributeDictionary = connect.AttributeDictionary;

export interface IContactTraceRecord {
  id: string;
  customer_type: string;
  customer_phone_number: string;
  queue: string;
  contact_id: string;
  customer?: IUser;
  contact_attributes_json: Record<string, any>;
}

export interface ICallTriggeredFromBMT {
  bmtIdToLink?: string;
  contactPhoneNumber?: string;
}

export enum SoftPhoneAgentStatus {
  ROUTABLE = "routable",
  NOT_ROUTABLE = "not_routable",
  OFFLINE = "offline",
}

export interface IAgent {
  status: SoftPhoneAgentStatus;
}

export interface ICustomerRequestItem {
  id: string;
  pkid: number;
  request_type: string;
  friendly_name: string;
  bmt_id: number;
  primary_client_name: string;
  time_modified: string;
  type_string: string;
}

export type ICallCentreState = {
  contactId?: string;
  contactTraceRecord?: IContactTraceRecord | null;
  showAmazonConnectContainer?: boolean;
  contactAttributes?: AttributeDictionary | null;
  callTriggeredFromBMT?: ICallTriggeredFromBMT | null;
  matchedCustomer?: IUser | null;
  showMatchedUser?: boolean;
  showNewUserForm?: boolean;
  agent?: IAgent;
  userType?: "user_landlord" | "user_tenant" | null;
  initiationMethod?: string | null;
  customerRequestItems?: ICustomerRequestItem[];
  matchedCustomerIds?: string;
};

export type ICallCentreStateUpdate = Partial<ICallCentreState>;

export interface ICallCentreContext {
  callCentreState: ICallCentreState;
  handleSetCallCentreState: (update: ICallCentreStateUpdate) => void;
}

export const callCentreDefaults = {
  callCentreState: {
    showAmazonConnectContainer: false,
    showMatchedUser: false,
    showNewUserForm: false,
    userType: null,
    contactAttributes: null,
    contactId: "",
    callTriggeredFromBMT: null,
    matchedCustomer: null,
    contactTraceRecord: null,
    customerRequestItems: [],
    matchedCustomerIds: "",
  },
  handleSetCallCentreState: () => {},
};

export const CallCentreContext = createContext<ICallCentreContext>(callCentreDefaults);
