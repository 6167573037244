import React, { useState } from "react";
import { SiTwilio } from "@react-icons/all-files/si/SiTwilio";

import { useForwardCallToCallCentre } from "hooks/useForwardCallToCallCentre";
import { Dropdown } from "react-bootstrap";
import { AiFillPhone, AiOutlineAmazon } from "react-icons/ai";
import * as Styled from "./styles";
import { isMobile } from "react-device-detect";
import { Button, DialogActions, DialogTitle, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useMutation } from "@tanstack/react-query";
import * as Api from "api";
export enum CALLING_METHODS {
  TWILIO,
  AMAZON_CONNECT,
}

export const OutboundPhoneCallDropdown = (props: {
  phoneNumber: string;
  bmtId: string;
  showAmazonConnectContainer?: boolean;
  onConfirmed?: (type?: CALLING_METHODS) => void;
}) => {
  const handleSetCallCentreState = useForwardCallToCallCentre(props.phoneNumber, props.bmtId);

  const { mutate: makeTwilioOutboundCall } = useMutation({
    mutationFn: async ({ data }: { data: { bmt_id: string; to_number: string } }) => {
      const { data: respData } = await Api.activity.makeOutboundCall(data);
      return respData;
    },
    mutationKey: ["make-outbound-call"],
    onSuccess: data => {
      Notification.requestPermission().then(perm => {
        const msgTitle = `Connecting to ${data.data.to_number}`;
        const msgBody =
          "You will receive a call shortly, once the call is accepted by the customer.";
        perm === "granted"
          ? new Notification(msgTitle, {
              body: msgBody,
            })
          : alert(`${msgTitle}. ${msgBody}`);
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [callingMethod, setCallingMethod] = useState<CALLING_METHODS>();

  const handleConfirmMakeCallDialog = () => {
    if (callingMethod === CALLING_METHODS.TWILIO) {
      makeTwilioOutboundCall({
        data: { bmt_id: props.bmtId, to_number: props.phoneNumber },
      });
    }
    if (callingMethod === CALLING_METHODS.AMAZON_CONNECT) {
      handleSetCallCentreState(props.showAmazonConnectContainer);
    }
    setIsConfirmationOpen(false);
    props.onConfirmed && props.onConfirmed(callingMethod);
  };

  return (
    <>
      <Dialog open={isConfirmationOpen} fullWidth maxWidth="sm">
        <DialogTitle id="alert-dialog-title">Call {props.phoneNumber}</DialogTitle>
        <DialogActions>
          <Grid container spacing={2} style={{ flexDirection: "row-reverse" }}>
            <Grid item xs={6} md={2}>
              <Button
                onClick={handleConfirmMakeCallDialog}
                fullWidth={true}
                variant="contained"
                color="success"
              >
                Yes
              </Button>
            </Grid>

            <Grid item xs={6} md={2}>
              <Button
                variant="contained"
                color="error"
                fullWidth={true}
                onClick={() => {
                  setIsConfirmationOpen(false);
                }}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {isMobile ? (
        <a
          href="#"
          onClick={evt => {
            setCallingMethod(CALLING_METHODS.TWILIO);
            setIsConfirmationOpen(true);
            evt.preventDefault();
          }}
        >
          <AiFillPhone />
        </a>
      ) : (
        <Dropdown className={"d-inline"}>
          <Styled.DropdownIconToggle>
            <AiFillPhone />
          </Styled.DropdownIconToggle>

          <Dropdown.Menu>
            <Dropdown.Item
              href="#"
              onClick={() => {
                setCallingMethod(CALLING_METHODS.AMAZON_CONNECT);
                setIsConfirmationOpen(true);
              }}
            >
              <AiOutlineAmazon /> AmazonConnect
            </Dropdown.Item>
            <Dropdown.Item
              href="#"
              onClick={() => {
                setCallingMethod(CALLING_METHODS.TWILIO);
                setIsConfirmationOpen(true);
              }}
            >
              <SiTwilio /> Twilio
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
};
