import styled from "styled-components/macro";
import { FormStateTypes } from "./index";

export const FormView = styled.form`
  position: relative;
  background: var(--white) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 8px 10px #00000024; */
  margin-bottom: var(--md);
  display: flex;
  flex-direction: column;
`;

type MessageSectionProps = {
  type: FormStateTypes;
};

export const MessageSectionView = styled.section<MessageSectionProps>`
  color: ${props =>
    props.type === FormStateTypes.ERROR
      ? "var(--colors-danger)"
      : props.type === FormStateTypes.INFO
      ? "var(--colors-accent)"
      : "var(--colors-success)"};
`;

export const FormTitleSize = styled.h2``;

export const SubmittingOverlayView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;
