import React from "react";

import { XLFormFragmentGroup, IXLFormFragmentProps } from "containers/XLFormPage";
import { FieldValues } from "react-hook-form";
import { LSRCommercialFragment } from "components/PropertyCreation/FormFragments";

export const LSRCommercialFragmentGroup = <T extends FieldValues = Record<string, any>>(
  props: IXLFormFragmentProps<T>,
) => {
  return (
    <XLFormFragmentGroup {...props}>
      <LSRCommercialFragment
        disableFieldsOnEdit={["long_term_monthly_rent", "available_from_date", "vacant_from_date"]}
      />
    </XLFormFragmentGroup>
  );
};
