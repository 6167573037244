import { lazy } from "react";
import { Navigate } from "react-router-dom";

const LoginPage = lazy(() => import("../pages/login"));

export const unauthenticatedRoutes = [
  {
    path: `/login/:userType`,
    element: <LoginPage />,
  },
  { path: "*", element: <Navigate to="/login/admin" /> },
];
