import { useQuery } from "@tanstack/react-query";
import React, { createContext, useState } from "react";
import * as Api from "../api";

export interface IServiceRequestDetailContext {
  isFlowTaskContainerExpertMode: boolean;
  clientFacingUsers?: { id: string; fullname: string }[];
  setIsFlowTaskContainerExpertMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const ServiceRequestDetailContext = createContext<IServiceRequestDetailContext>({
  isFlowTaskContainerExpertMode: false,
  setIsFlowTaskContainerExpertMode: () => {},
  clientFacingUsers: [],
});

const ServiceRequestDetailContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: internalUsersData } = useQuery({
    enabled: true,
    queryKey: ["internal-users"],
    queryFn: async () => {
      const res = await Api.user.getInternalUsers();
      return res.data;
    },
  });

  const clientFacingUsers = internalUsersData?.map(user => ({
    id: user.id,
    fullname: user.full_name,
  }));

  const [isFlowTaskContainerExpertMode, setIsFlowTaskContainerExpertMode] = useState(false);

  return (
    <ServiceRequestDetailContext.Provider
      value={{
        isFlowTaskContainerExpertMode,
        setIsFlowTaskContainerExpertMode,
        clientFacingUsers: clientFacingUsers,
      }}
    >
      {children}
    </ServiceRequestDetailContext.Provider>
  );
};

export { ServiceRequestDetailContextProvider, ServiceRequestDetailContext };
