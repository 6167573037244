import { clsx } from "clsx";
import { TableCell } from "../table-cell";
import { TableRow } from "../table-row";
import { Column, Row } from "../types";

export const RenderRow =
  <T extends Row>(columns: Column<T>[]) =>
  (row: T, index: number) => {
    const cells = columns.map(({ rowProps, ...column }, idx) => {
      const uid = `row-${index}-${idx}`;

      if (!row[column.name]) {
        throw `row ${row.id} must contain a column configuration`;
      }

      return (
        <TableCell
          className={clsx(row.className, column.className)}
          key={uid}
          align={rowProps?.align}
          padding={rowProps?.padding}
          size={rowProps?.size}
          uid={uid}
        >
          {(column.renderCell && column.renderCell(row)) ?? row[column.name]}
        </TableCell>
      );
    });
    return (
      <TableRow key={`row-${index}`} id={row.id}>
        {cells}
      </TableRow>
    );
  };
