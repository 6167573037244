import { Col, Row, Container } from "react-bootstrap";
import styled from "styled-components/macro";
import Board from "react-trello";
import { Button } from "@mui/material";

// part 1 - can be used outside Trello Board
export const BoardContainer = styled(Container)`
  height: 100%;
`;

// part 2 - for internal consumption only in local index.jsx
export const SHeaderText = styled.h4`
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
  color: var(--white);
`;

export const SStickButton = styled(Button)`
  color: var(--inactive);
  margin-left: 20px;
  &:first-of-type {
    margin-left: 0px;
  }
`;

export const SRow = styled(Row)``;

export const SCol = styled(Col)``;

export const SHeader = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const STrelloBoard = styled(Board)`
  background-color: transparent;
  /* padding: 5px; */
  color: #393939;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: auto !important;

  width: 100%;
  overflow-y: auto;
  > div {
    width: 100%;
    max-width: vw;
    overflow-y: auto;
  }
  .smooth-dnd-container.horizontal {
    width: 300px;
    display: flex;
  }

  .react-trello-lane {
    background-color: transparent;
    border-right: 2px solid var(--inactive);
    max-width: 285px;
    padding: 16px;
    > header:first-of-type {
      white-space: pre-wrap;
      justify-content: grid;
      width: auto;
      display: grid;
      grid-template-columns: 1fr 20px;
      color: var(--white);

      span {
        &:last-of-type {
          width: 36px;
          font-size: 16px;
        }
        white-space: pre-wrap;
      }
    }
    > div {
      max-height: 100%;
      overflow: unset;
    }
  }
`;

export const SearchInputWrapper = styled.div`
  padding-left: 1.5rem;
  margin-top: 2rem;
  width: 880px;
  max-width: 100%;
`;

export const FloatingOptions = styled.div`
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: end;
`;

export const FloatingOptionsTrigger = styled.button`
  all: unset;
  background-color: rgb(193, 208, 234);
  color: var(--main);
  border-radius: 10000px;
  padding: 1rem;
  display: flex;
  align-items: center;
`;
