import { TableCell } from "../table-cell";
import { TableRow } from "../table-row";
import { Column } from "../types";

export const RenderGroupedColumns = <T,>(columns: Column<T>[], index: number) => {
  return (
    <TableRow key={`col-${index}-header`}>
      {columns.map(({ children, className, columnSpan, name }, idx: number) => {
        const uid = `col-${index}-${idx}`;
        return (
          <TableCell key={name} className={className} columnSpan={columnSpan} id={name} uid={uid}>
            {children}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
