import { axios } from "libconfigs/axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface ISeeTestsCall {
  id: string;
  shouldSeeTests: boolean;
}

interface ISeeNonCommercialBaseListingNonRelatedData {
  id: string;
  shouldSeeNonCommercialBaseListingRelatedData: boolean;
}

const setDeskPhoneAgentStatus = () =>
  axios.post(`/amazon-connect-actions/set-desk-phone-agent-status/`, {});

const useSetDeskPhoneAgentStatus = () => {
  return useMutation(setDeskPhoneAgentStatus, {
    onSuccess: (data: any) => {
      const msgTitle = `Changing agent status to ${data.data.new_status}`;
      const msgBody = "Your status should be changed shortly.";
      Notification.requestPermission().then(perm => {
        perm === "granted"
          ? new Notification(msgTitle, {
              body: msgBody,
            })
          : alert(`${msgTitle}. ${msgBody}`);
      });
    },
  });
};

const seeTestsSRs = ({ id, shouldSeeTests }: ISeeTestsCall) =>
  axios.patch(`/user/all/${id}/`, { is_allowed_to_see_test_service_requests: shouldSeeTests });

const useSeeTestsSRs = () => {
  const queryClient = useQueryClient();

  return useMutation(seeTestsSRs, {
    onSuccess: () => {
      queryClient.invalidateQueries(["trello-board-data", "data"]);
    },
  });
};

const seeNonCommercialBaseListingRelatedData = ({
  id,
  shouldSeeNonCommercialBaseListingRelatedData,
}: ISeeNonCommercialBaseListingNonRelatedData) =>
  axios.patch(`/user/user/${id}/`, {
    internaluseradditionalprofile: {
      is_allowed_to_view_non_commercial_baselisting_related_data:
        shouldSeeNonCommercialBaseListingRelatedData,
    },
  });

const useSeeNonCommercialBaseListingRelatedData = () => {
  const queryClient = useQueryClient();

  return useMutation(seeNonCommercialBaseListingRelatedData, {
    onSuccess: () => {
      queryClient.invalidateQueries(["trello-board-data", "data"]);
    },
  });
};

export { useSeeTestsSRs, useSetDeskPhoneAgentStatus, useSeeNonCommercialBaseListingRelatedData };
