import styled from "styled-components";
import { IconProps } from "./types";
import { SVGRenderer } from "./svg-renderer";

const UnstyledAvailableMarker: React.FC<IconProps> = ({
  className,
  color = "rgb(0, 136, 22)",
  size,
}) => {
  return (
    <div className={className}>
      <SVGRenderer size={size}>
        <g
          transform={"translate(0.000000,98.000000) scale(0.100000,-0.100000)"}
          fill={color}
          stroke={"none"}
        >
          <path
            d={`M290 934 c-173 -47 -286 -220 -266 -408 11 -108 40 -153 218 -333
l162 -164 159 158 c186 186 217 235 225 357 17 271 -227 463 -498 390z m207
-349 c34 -88 63 -163 63 -167 0 -5 -9 -8 -21 -8 -15 0 -25 13 -41 50 l-21 50
-71 0 -71 0 -18 -50 c-15 -42 -22 -50 -42 -50 l-25 0 24 63 c98 257 109 278
135 275 22 -3 31 -20 88 -163z`}
          />
          <path d={"M379 621 l-27 -71 55 0 c62 0 61 -3 19 93 l-21 48 -26 -70z"} />
        </g>
      </SVGRenderer>
    </div>
  );
};

export const AvailableMarker = styled(UnstyledAvailableMarker)``;
