import { ListItemButton as MUIListListemButton, SxProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  styles?: SxProps;
  className?: string;
};

const UnstyledListItemButton: React.FC<Props> = ({ children, disabled, styles, className }) => {
  return (
    <MUIListListemButton sx={styles} disabled={disabled} className={className}>
      {children}
    </MUIListListemButton>
  );
};

export const ListItemButton = styled(UnstyledListItemButton)``;
