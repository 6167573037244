import { urlBase64ToUint8Array } from "./url-base-64-to-uint-8-array";
import { baseUrlApi } from "xl-config";

export const registerPushNotifications = (registration: ServiceWorkerRegistration) => {
  registration.pushManager
    .getSubscription()
    .then(async subscription => {
      if (subscription) {
        return subscription;
      }

      const response = await fetch(`${baseUrlApi}/notifications/web-push/vapid/`);
      const { key } = await response.json();
      const applicationServerKey = urlBase64ToUint8Array(key);

      return registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey,
      });
    })
    .then(subscription => {
      fetch(`${baseUrlApi}/notifications/web-push/`, {
        method: "post",
        headers: {
          Authorization: `JWT ${localStorage.getItem("access_token")}`,
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          subscription,
        }),
      });
    });
};
