import { SxProps, Typography as MUITypography } from "@mui/material";
import styled from "styled-components";
import { FillColor } from "../types";

export type Props = {
  body?: string;
  children?: string;
  className?: string;
  color?: FillColor;
  styles?: SxProps;
  variant?:
    | "body1"
    | "body2"
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "overline"
    | "subtitle1"
    | "subtitle2";
};

const UnStyledTypography: React.FC<Props> = ({
  children,
  className,
  color,
  body = "Area Intelligence Tab",
  styles,
  variant = "body1",
}) => {
  return (
    <MUITypography className={className} color={color} sx={styles} variant={variant}>
      {body ?? children}
    </MUITypography>
  );
};

export const Typography = styled(UnStyledTypography)``;
