import * as React from "react";
import { TableHead as MUITableHead } from "@mui/material";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const TableHead: React.FC<Props> = ({ children, className }) => {
  return <MUITableHead className={className}>{children}</MUITableHead>;
};
