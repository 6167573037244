import styled from "styled-components/macro";
import { FormModes } from "containers/GenericForm";

export interface ISectionViewProps {
  $width?: string;
  $iconPadding?: boolean;
  $noMargin?: boolean;
  $inputGroupItem?: boolean;
}

export const SectionView = styled.div<ISectionViewProps>`
  margin-bottom: ${props => (props.$noMargin ? "unset" : "var(--xs)")};
  position: relative;
  display: grid;
  grid-template-columns: ${props => (props.$iconPadding && !props.$width ? "40px 1fr" : "1fr")};
  grid-template-rows: repeat(2, minmax(25px, auto));
  align-items: center;
  color: var(--input-color);
  flex: 1;
  position: relative;
  > div,
  label {
    grid-column: ${props =>
      props.$iconPadding ? "2 / span 2" : props.$inputGroupItem ? "1 / span 2" : "unset"};
  }
`;

export interface IInputFieldProps {
  $isInvalid?: boolean;
  $iconPadding?: boolean;
  $formMode?: string;
  $inputGroupItem?: boolean;
}

export const baseInputStyle = (
  props: IInputFieldProps | ICustomInputWrapperViewProps, // can either be ISInputFieldProps or ISCustomInputWrapperProps
) => {
  return `
  z-index: 100;
  background: transparent;
  position: relative;
  outline: none;
  width: 100%;
  padding: var(--sm) var(--sm);
  border-radius: var(--xxs) !important;
  grid-column: ${
    props.$iconPadding ? "2 / span 2" : props.$inputGroupItem ? "1 / span 2" : "unset"
  };
  border: var(--input-border-size) solid ${
    props.$isInvalid ? "var(--input-border-error)" : "var(--input-border-color)"
  } !important;

  color: ${props.$isInvalid ? "var(--danger)" : "var(--input-color)"} !important;

  
  &:hover, &:focus {
    border-color: ${props.$isInvalid ? "var(--danger)" : "var(--color-main)"} !important;
    & + .input-placeholder {
      top: -9px;
      font-size: var(--sm);
      color: ${props.$isInvalid ? "var(--danger)" : "var(--color-main)"};
      background: white;
      padding: 0 2px;
    }
  }
  
  &:focus {
    outline: none;
  }
  &:valid {
    background-color: transparent !important;
  }
  
  ${
    props.$formMode === FormModes.EDIT
      ? `
  &:hover, &:focus {
    border-width: 2px !important;
    border-color: red;
    box-shadow: unset !important
    & ~ .edit-icon-container {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
    & + .input-placeholder {
      top: -9px;
      font-size: var(--sm);
      color: var(--color-main);
      background: white;
      padding: 0 2px;
    }
    & ~ .help-text {
      color: var(--color-main);        
    }
  }
  &:hover {
    & ~ .edit-icon-container {
      .edit {
        display: block;
      }.cancel {
        display: none;
      }
    }
  }

  &:focus {
    & ~ .edit-icon-container {
      .edit {
        display: none
      }
      .cancel {
        display: block;
      }
    }
  }`
      : ``
  }
  `;
};

export const InputFieldView = styled.input<IInputFieldProps>`
  ${props => baseInputStyle(props)}
`;

export interface IHelpTextViewProps {
  $isError?: boolean;
  $iconPadding?: boolean;
}

export const HelpTextView = styled.div<IHelpTextViewProps>`
  margin-left: var(--sm);
  color: ${props => (props.$isError ? "var(--danger)" : "var(--grey)")};
  font-size: var(--sm);
  grid-column: 2;
`;

export interface IPlaceholderViewProps {
  $isActive?: boolean;
  $isInvalid?: boolean;
  $iconPadding?: boolean;
  $width?: string;
  $formMode?: string;
  $placeTop?: boolean;
}

export const PlaceholderView = styled.span<IPlaceholderViewProps>`
  z-index: 110;
  padding: 0 2px;
  color: ${props => (props.$isInvalid ? "var(--danger)" : "var(--input-placeholder-color)")};
  position: absolute;
  top: ${props => (props.$placeTop ? "-9px !important" : props.$isActive ? "-9px" : "16px")};
  left: ${props => (!props.$iconPadding ? "var(--sm)" : "var(--sm)")};
  transition: all 0.2s ease-in-out;
  font-size: ${props => (props.$isActive ? "var(--sm)" : "15px")};
  background: #fff;
  opacity: unset;
  &:hover {
    top: -9px;
    font-size: var(--sm);
    color: var(--color-main);
    background-color: white;
    padding: 0 2px;
  }
`;

export const IconWrapperView = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1.5em;
    height: 1.5em;
    color: var(--color-main);
  }
`;

export type ICustomInputWrapperViewProps = IInputFieldProps;

export const CustomInputWrapperView = styled.span<ICustomInputWrapperViewProps>`
  input {
    ${props => baseInputStyle(props)}
    padding-top: var(--sm) !important;
    padding-bottom: var(--sm) !important;
    width: 100% !important;
    height: auto !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }

  .flag-dropdown {
    border: var(--input-border-size) solid
      ${props => (props.$isInvalid ? "var(--input-border-error)" : "var(--input-border-color)")} !important;
  }
`;

export const InputModeIconWrapperView = styled.span`
  position: absolute;
  display: none;
  top: 15px;
  right: 10px;
  .edit,
  .cancel {
    /* opacity: 0; */
    /* transition: all 0.2s linear; */
  }
  svg {
    color: var(--colors-main);
  }
  :hover {
    cursor: pointer;
  }
  .edit {
    z-index: 10;
  }
  .cancel {
    z-index: 110;
  }
`;

export const ParsedTime = styled.div`
  font-size: var(--sm);

  span {
    font-size: inherit;
    padding: 3px;
    background-color: #7070701b;
  }
`;
