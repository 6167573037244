import { IActivity, BMTActivity } from "xl-types";
import { axios } from "../../libconfigs";

export const geActivities = async (id: string) => {
  const { data, status } = await axios.get<BMTActivity>(`base-message-thread/${id}/`);
  return { status, data };
};

export const geActivitiesV2 = async (bmtId: string) => {
  const { data, status } = await axios.get<{ activities: IActivity[] }>(
    `base-message-thread/${bmtId}/activity-list/`,
  );

  return { status, data };
};

export const makeOutboundCall = async (body: { bmt_id: string; to_number: string }) => {
  const { data, status } = await axios.post(`/twilio-inbound-webhook/make-outbound-call/`, body);
  return { status, data };
};

export const getReplyTemplates = async () => {
  const { data, status } = await axios(`/reply-template/`);
  return { status, data };
};
