import styled from "styled-components";

export const UserSnackbarMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--main);

  a {
    color: #df1660;
  }
`;
