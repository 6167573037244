import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import * as Api from "api";
import { LinkedObjectContentType } from "components/Notes";
import { axios } from "libconfigs/axios";
import { IInternalCommentApi } from "./types";

export const useGetInternalUsers = () =>
  useQuery(["internal-users"], () => Api.user.getInternalUsers());

export interface IInternalTaskApi {
  object_id: string;
  content_object_class_name_str: string | LinkedObjectContentType;
  text: string;
  absolute_trigger?: {
    next_trigger_time?: string;
    is_finished?: boolean;
    created_by?: string;
    trigger_time?: string;
  };
}

const addInternalTask = ({ data }: { data: IInternalTaskApi }) => {
  return axios.post<IInternalTaskApi>(`/task/`, data);
};

export const useAddInternalTask = () => {
  const queryClient = useQueryClient();

  return useMutation(addInternalTask, {
    onSuccess: async () => {
      queryClient.invalidateQueries(["sr-internal-tasks"]);
    },
  });
};

const addInternalComment = ({ data }: { data: IInternalCommentApi }) => {
  return axios.post("/internal-comment/", data);
};

export const useAddInternalComment = () => {
  const queryClient = useQueryClient();

  return useMutation(addInternalComment, {
    onSuccess: async () => {
      queryClient.invalidateQueries(["sr-internal-comments"]);
      queryClient.invalidateQueries(["get-activities"]);
    },
  });
};

export const useUpdateInternalComment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: Record<string, any> }) =>
      axios.put(`/internal-comment/${id}/`, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["get-activities"]);
        queryClient.invalidateQueries(["sr-internal-comments"]);
      },
    },
  );
};

export const useUpdateTask = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, data }: { id: string; data: Record<string, any> }) => axios.put(`/task/${id}/`, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(["sr-internal-tasks"]);
      },
    },
  );
};
