import { Controller, ControllerProps, Path, FieldValues } from "react-hook-form";
import { TextFieldProps, Typography } from "@mui/material";
import {
  DropZoneContainer,
  SelectFileView,
  SelectFileViewThumbs,
  SelectFileViewThumbsImg,
} from "./styles";
import { useEffect, useState } from "react";
import { useDropzone, DropzoneOptions, Accept } from "react-dropzone";
import { AiOutlinePlus } from "@react-icons/all-files/ai/AiOutlinePlus";
import { IXenialetsAPIAttachment } from "types/attachments";

export type CustomUploadInputProps<F extends FieldValues> = {
  name: Path<F>;
  control?: any; //TODO: Aleem construct useful type for props.
  multiple?: boolean;
  accept?: Accept;
  uploadedFiles?: IXenialetsAPIAttachment[];
  rules?: ControllerProps["rules"];
  required?: boolean;
  label?: TextFieldProps["label"];
  showCheckbox?: boolean;
};

type SelectedFiles = File & { preview: string; url?: string; file?: string };

function ReactDropZoneWithPreviews({
  dropZoneOptions,
  uploadedFiles,
  selectedFiles,
  name,
}: {
  dropZoneOptions: DropzoneOptions;
  uploadedFiles: SelectedFiles[];
  selectedFiles: SelectedFiles[];
  name: string;
}) {
  const [files, setFiles] = useState<SelectedFiles[]>(selectedFiles || []);
  const { getRootProps, getInputProps } = useDropzone({
    ...dropZoneOptions,
    onDrop: (acceptedFiles, rejectedFiles, event) => {
      if (typeof dropZoneOptions.onDrop === "function") {
        dropZoneOptions.onDrop(acceptedFiles, rejectedFiles, event);
      }
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  const isImage = (file: SelectedFiles) =>
    [".jpeg", ".jpg", ".png"].some(
      ext => file?.url?.toLowerCase().includes(ext) || file?.name?.toLowerCase().includes(ext),
    );
  const thumbs = [...uploadedFiles, ...files]?.map((file: SelectedFiles) => (
    <SelectFileViewThumbs key={file.name || file.preview || file.url}>
      <div>
        {isImage(file) ? (
          <SelectFileViewThumbsImg
            src={file.preview || file.url}
            alt="file_preview"
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        ) : (
          <Typography variant="caption">{file.name || file.file}</Typography>
        )}
      </div>
    </SelectFileViewThumbs>
  ));

  useEffect(() => {
    return () => files.forEach((file: SelectedFiles) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <DropZoneContainer>
      <div>
        {thumbs}
        <SelectFileView
          {...getRootProps({ className: "dropzone" })}
          data-testid={"dropzone-select"}
        >
          <aside>
            <input {...getInputProps()} data-testid={name} />
            <AiOutlinePlus fontSize={30} />
          </aside>
        </SelectFileView>
      </div>
    </DropZoneContainer>
  );
}

export const CustomUploadInput = <TFieldValues extends FieldValues>({
  name,
  control,
  rules,
  required,
  accept,
  uploadedFiles,
}: CustomUploadInputProps<TFieldValues>) => {
  const validationRules: ControllerProps["rules"] = {
    ...rules,
    ...(required && {
      required: rules?.required || "This field is required",
    }),
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={validationRules}
      render={({ field }) => {
        return (
          <ReactDropZoneWithPreviews
            dropZoneOptions={{
              onDrop(acceptedFiles) {
                field.onChange(
                  acceptedFiles.map((file: any) => {
                    file.fieldName = name;
                    return file;
                  }),
                );
              },
              accept,
            }}
            selectedFiles={field.value}
            uploadedFiles={uploadedFiles as SelectedFiles[]}
            name={name}
          />
        );
      }}
    />
  );
};
