import React from "react";

import { XLFormFragmentGroup } from "containers/XLFormPage";
import { FieldValues } from "react-hook-form";
import { DocumentsFragment } from "components/PropertyCreation/FormFragments";
import { IDocumentsFragmentGroupProps } from "./types";

export const DocumentUploadFragmentGroup = <T extends FieldValues = Record<string, any>>(
  props: IDocumentsFragmentGroupProps<T>,
) => {
  const { uploadedEpcs, uploadedOthers, ...fragmentGroupProps } = props;
  return (
    <XLFormFragmentGroup {...fragmentGroupProps}>
      <DocumentsFragment uploadedEpcs={uploadedEpcs} uploadedOthers={uploadedOthers} />
    </XLFormFragmentGroup>
  );
};
