import { AxiosError, AxiosResponse } from "axios";
import { XLFormModes } from "containers/XLForm";
import { HTTPMethods } from "hooks/useMakeRequest";

import { IFormGroupRegistration, XLFormStateTypes } from ".";

const getHTTPURLForRequest = (
  mode: XLFormModes,
  formGroupRegistration: IFormGroupRegistration<any>,
) => (mode === XLFormModes.EDIT ? formGroupRegistration.editURL : formGroupRegistration.createURL);

const getHTTPMethodForRequest = (
  mode: XLFormModes,
  formGroupRegistration: IFormGroupRegistration<any>,
) =>
  mode === XLFormModes.EDIT ? formGroupRegistration.editMethod : formGroupRegistration.createMethod;

const getFormMessageForRequest = (
  mode: XLFormModes,
  formGroupRegistration: IFormGroupRegistration<any>,
) =>
  mode === XLFormModes.EDIT
    ? formGroupRegistration.successEditMessage || `Updated ${formGroupRegistration.name} data`
    : formGroupRegistration.successCreateMessage || `Created ${formGroupRegistration.name} data`;

export async function makeNetworkRequest(
  formMode: XLFormModes,
  formGroupRegistration: IFormGroupRegistration<any>,
  makeRequestOnSubmit: any,
  hookFormData: Record<any, any>,
  previousSubmitResponses: Record<string, any>,
  setFormState: React.Dispatch<
    React.SetStateAction<
      | {
          message: string;
          type: XLFormStateTypes;
        }
      | undefined
    >
  >,
): Promise<
  | {
      success: boolean;
      error: AxiosResponse<any> | { data: any } | undefined;
    }
  | undefined
> {
  // If any of the registrations wants to skip on either create or edit, continue the loop onto the next registration
  if (formMode === XLFormModes.CREATE && formGroupRegistration.skipCreate) return;
  if (formMode === XLFormModes.EDIT && formGroupRegistration.skipEdit) return;

  setFormState({
    message: `Submitting ${formGroupRegistration.name} data`,
    type: XLFormStateTypes.INFO,
  });

  // just pick the data using the data prefix
  let modifiedDataBeforeSubmit = hookFormData[formGroupRegistration.dataPrefix];

  if (formGroupRegistration.beforeSubmit) {
    // If the beforeSubmit is registered in this form registration
    // call the function and pass in all the data that we have access to
    modifiedDataBeforeSubmit = formGroupRegistration.beforeSubmit(
      // Pick the correct data for this form group using the prefix
      modifiedDataBeforeSubmit,
      // Send the complete form data as well for ease of access
      hookFormData,
      // Submit responses from  previous submit api requests
      previousSubmitResponses,
    );
  }

  try {
    // The http url to send the request to, this can be different based on the mode of the request
    const backendHTTPURL = getHTTPURLForRequest(formMode, formGroupRegistration);

    // The http method to send the request with, this can be different based on the mode of the request
    const backendHTTPMethod = getHTTPMethodForRequest(formMode, formGroupRegistration);

    let response: AxiosResponse<any> | { data: any };

    if (formGroupRegistration.customNetworkRequest) {
      response = await formGroupRegistration.customNetworkRequest(modifiedDataBeforeSubmit);
    } else {
      response = await makeRequestOnSubmit({
        data: {
          configuration: {
            url: backendHTTPURL as string,
            method: backendHTTPMethod as HTTPMethods,
          },
          requestBody: modifiedDataBeforeSubmit,
        },
      });
    }

    // If this request is successful, store the submit response as part of the previousSubmitResponses object
    previousSubmitResponses[formGroupRegistration.name as string] = response.data;

    if (formGroupRegistration.afterSubmit) {
      formGroupRegistration.afterSubmit(
        // Send the the response data for ease of access
        response.data as never,
        // Send the complete form data as well for ease of access
        hookFormData,
        // Submit responses from  previous submit api requests
        previousSubmitResponses,
      );
    }

    setFormState({
      message: getFormMessageForRequest(formMode, formGroupRegistration),
      type: XLFormStateTypes.SUCCESS,
    });
    return { success: true, error: { data: undefined } };
  } catch (error) {
    setFormState({
      message: `Unable to ${formMode} ${formGroupRegistration.name}. Please try again`,
      type: XLFormStateTypes.ERROR,
    });
    const { response } = error as AxiosError<any>;
    return { success: false, error: response };
  }
}
