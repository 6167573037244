import { useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { CallCentreContext, ICallCentreContext } from "contexts/callCentreContext";
import DialPad from "components/DialPad";
import { BrowserView } from "react-device-detect";

const AmazonConnectContainer = () => {
  const { callCentreState } = useContext<ICallCentreContext>(CallCentreContext);

  return (
    <div style={{ display: callCentreState.showAmazonConnectContainer ? "block" : "none" }}>
      <Row style={{ width: 300 }}>
        <Col md={12}>
          <BrowserView>{process.env.REACT_APP_TEST_MODE !== "true" && <DialPad />}</BrowserView>
        </Col>
      </Row>
    </div>
  );
};

export default AmazonConnectContainer;
