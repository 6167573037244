import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";
import { Box, Grid } from "@mui/material";
import { CustomUploadInput } from "components/XLFormComponents/CustomUploadInput";
import { IXenialetsAPIAttachment } from "types/attachments";

export const DocumentsFragment = (
  props: IXLFormFragmentProps & {
    uploadedOthers: IXenialetsAPIAttachment[];
    uploadedEpcs: IXenialetsAPIAttachment[];
  },
) => {
  const { XLFormFragment } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  return (
    <XLFormFragment>
      <Box sx={{ my: "10px" }}>
        <Grid container>
          <Grid md={1} xs={6} item>
            EPC
          </Grid>
          <Grid md={11} xs={6} item>
            <CustomUploadInput
              uploadedFiles={props.uploadedEpcs}
              control={props.useFormReturn?.control}
              name="epcs"
              accept={{ "application/pdf": [] }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ my: "10px" }}>
        <Grid container>
          <Grid md={1} xs={6} item>
            Others
          </Grid>
          <Grid md={11} xs={6} item>
            <CustomUploadInput
              control={props.useFormReturn?.control}
              name="others"
              uploadedFiles={props.uploadedOthers}
              accept={{ "application/pdf": [] }}
            />
          </Grid>
        </Grid>
      </Box>
    </XLFormFragment>
  );
};
