import { clsx } from "clsx";
import { v4 as uuidv4 } from "uuid";
import { TableProps } from "xl-components";
import { ukCoords } from "xl-config";
import {
  IAgreedLettingsUI,
  IAreaInteligenceUI,
  IDynamicMapUI,
  IMarketIntelligence,
  IRightMoveListingsUI,
  ITwelveMonthsTable,
} from "xl-types";
import { MonthsHeaderRenderer, TableCellNumericRenderer, TableCellRender } from "xl-views";
import { createGroupedColumns } from "./create-grouped-columns";
import { createRightMoveTableData } from "./create-right-move-table-data";
import { getColumnConfig } from "./get-column-config";

const yearlyRangeColumns = [
  {
    children: null,
    name: "bedroomCount",
  },
  {
    children: null,
    name: "rent",
  },
];
const rightMoveListingsColumns = [
  {
    children: null,
    name: "bedroomCount",
  },
  {
    children: null,
    name: "propertyCount",
  },
  {
    children: null,
    name: "rent",
  },
];
const monthsColumn = [
  {
    children: <MonthsHeaderRenderer value={"Months"} />,
    name: "month",
  },
];
const defaultMapHeader = [
  {
    label: "Listings",
    value: "AVAILABLE",
  },
  {
    label: "Agreed Lettings",
    value: "LET_AGREED",
  },
];

const dateFormatter = new Intl.DateTimeFormat("en-GB", {
  day: "2-digit",
  month: "long",
  year: "numeric",
});

const mapItemDateFormatter = new Intl.DateTimeFormat("en-GB", {
  month: "short",
  year: "numeric",
});

const mapItemCurrencyFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 0,
});

export const areaIntelligenceTransformer = ({
  data,
  refetch_status_message,
}: IMarketIntelligence): IAreaInteligenceUI => {
  const {
    bedroom_count: queryBedroomCount,
    last_scan_date,
    postcode,
    postcode_sector,
    results,
  } = data;
  const {
    agreed_listings,
    map_data,
    rental_range_12_month,
    rm_live_listings,
    rm_new_instructions,
  } = results;
  const withTitle = createGroupedColumns(agreed_listings, queryBedroomCount);

  const date = Date.parse(last_scan_date);
  const scanDate = date ? dateFormatter.format(new Date(last_scan_date)) : "does not exist";
  const mapHasCenter = map_data.center.latitude && map_data.center.longitude;

  return {
    agreedLettingsTable: agreed_listings.reduce<IAgreedLettingsUI>(
      (acc, { price_data, month }) => {
        acc.months.rows.push({
          id: uuidv4(),
          month: <TableCellRender value={month} styles={["agreed-lettings"]} />,
        });

        acc.letsAgreed.rows.push(
          price_data.reduce<TableProps.Row>(
            (acc2, { bedroom_count, property_count }) => {
              const name = getColumnConfig(bedroom_count);
              acc2[name] = (
                <TableCellRender
                  value={property_count}
                  isHighlighted={queryBedroomCount === bedroom_count}
                  styles={["agreed-lettings"]}
                />
              );
              return acc2;
            },
            { id: uuidv4() },
          ),
        );

        acc.rentalRange.rows.push(
          price_data.reduce<TableProps.Row>(
            (acc2, { bedroom_count, max_price, min_price }) => {
              const name = getColumnConfig(bedroom_count);
              acc2[name] = (
                <TableCellNumericRenderer
                  isHighlighted={queryBedroomCount === bedroom_count}
                  start={min_price}
                  end={max_price}
                  styles={["price", "agreed-lettings"]}
                />
              );
              return acc2;
            },
            { id: uuidv4() },
          ),
        );

        acc.averageRentalRange.rows.push(
          price_data.reduce<TableProps.Row>(
            (acc2, { avg_price, bedroom_count }) => {
              const name = getColumnConfig(bedroom_count);
              acc2[name] = (
                <TableCellNumericRenderer
                  isHighlighted={queryBedroomCount === bedroom_count}
                  start={avg_price}
                  styles={["price", "agreed-lettings"]}
                />
              );
              return acc2;
            },
            { id: uuidv4() },
          ),
        );

        return acc;
      },
      {
        averageRentalRange: {
          columns: withTitle({
            children: "Average Rental",
            columnSpan: 3,
            name: "averageRental",
          }),
          rows: [],
        },
        months: {
          columns: monthsColumn,
          rows: [],
        },
        letsAgreed: {
          columns: withTitle({
            children: "Lets Agreed",
            columnSpan: 3,
            name: "letsAgreed",
          }),
          rows: [],
        },
        rentalRange: {
          columns: withTitle({
            children: "Rental Range",
            columnSpan: 3,
            name: "rentalRange",
          }),
          rows: [],
        },
      },
    ),
    map: {
      center: mapHasCenter
        ? {
            lat: map_data.center.latitude,
            lng: map_data.center.longitude,
          }
        : ukCoords,
      bedroomCount: rental_range_12_month.map(({ bedroom_count }) => {
        return {
          label: `${bedroom_count} bed`,
          value: `${bedroom_count}`,
        };
      }),
      ...map_data.data.reduce<{
        data: IDynamicMapUI[];
        postcodeMap: { [k: string]: IDynamicMapUI[] };
      }>(
        (acc, val) => {
          const dateString =
            val.scan_type_enum === "LET_AGREED" ? val.let_agreed_on : val.first_listed_on;

          const mapItem = {
            actualPrice: mapItemCurrencyFormatter.format(val.actual_price ?? 0),
            address: val.address,
            bedroomCount: val.bedroom_count,
            clickable: true,
            date: Date.parse(dateString)
              ? mapItemDateFormatter.format(new Date(dateString))
              : "Invalid Date",
            isCenter: val.postcode === postcode,
            latitude: val.latitude,
            longitude: val.longitude,
            postcode: val.postcode,
            propertyUrl: val.property_url,
            scanTypeEnum: val.scan_type_enum,
            scanType: val.scan_type,
          };

          if (acc.postcodeMap[val.postcode]) {
            acc.postcodeMap[val.postcode].push(mapItem);
          } else {
            acc.data.push(mapItem);
            acc.postcodeMap[val.postcode] = [mapItem];
          }

          return acc;
        },
        {
          data: [],
          postcodeMap: {},
        },
      ),
      tables: defaultMapHeader,
      zoom: mapHasCenter ? 14 : 6,
    },
    postcodeSector: postcode_sector,
    refetchMessage: refetch_status_message ?? "",
    rightMoveListingsTable: rm_live_listings.price_data.reduce<IRightMoveListingsUI>(
      createRightMoveTableData(queryBedroomCount),
      {
        columns: rightMoveListingsColumns,
        rows: [],
      },
    ),
    rightMoveNewInstructions: rm_new_instructions.price_data.reduce<IRightMoveListingsUI>(
      createRightMoveTableData(queryBedroomCount),
      {
        columns: rightMoveListingsColumns,
        rows: [],
      },
    ),
    scanDate: `Scan ${scanDate}`,
    twelveMonthsTable: rental_range_12_month.reduce<ITwelveMonthsTable>(
      (acc, { avg_price, bedroom_count, max_price, min_price }) => {
        const isHighlighted = queryBedroomCount === bedroom_count;
        acc.averageRentalTable.rows.push({
          bedroomCount: <TableCellRender styles={["dark-text"]} value={`${bedroom_count} Bed`} />,
          className: clsx({ "is-highlighted": isHighlighted }),
          id: uuidv4(),
          rent: <TableCellNumericRenderer isHighlighted={isHighlighted} start={avg_price} />,
        });

        acc.rentalRangeTable.rows.push({
          bedroomCount: <TableCellRender styles={["dark-text"]} value={`${bedroom_count} Bed`} />,
          className: clsx({ "is-highlighted": isHighlighted }),
          id: uuidv4(),
          rent: (
            <TableCellNumericRenderer
              end={max_price}
              isHighlighted={isHighlighted}
              start={min_price}
              styles={["right-move"]}
            />
          ),
        });

        return acc;
      },
      {
        averageRentalTable: { columns: yearlyRangeColumns, rows: [] },
        rentalRangeTable: { columns: yearlyRangeColumns, rows: [] },
      },
    ),
  };
};
