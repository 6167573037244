import styled from "styled-components";
import { formatCurrency } from "xl-utils";
import { clsx } from "clsx";
import React from "react";

type Props = {
  className?: string;
  end?: number;
  start: number;
  isHighlighted?: boolean;
  styles?: string[];
};

const UnStyledTableCellNumericRenderer: React.FC<Props> = ({
  className,
  start,
  end,
  isHighlighted,
  styles = [],
}) => {
  const startPrice = formatCurrency(start ?? 0);
  const endPrice = formatCurrency(end ?? 0);
  return (
    <span className={className}>
      <span
        className={clsx("table-cell", "start", ...styles, {
          highlight: isHighlighted,
        })}
      >
        {startPrice}
      </span>
      {end && (
        <React.Fragment>
          <span className={"hyphen"}>-</span>
          <span
            className={clsx("table-cell", "end", ...styles, {
              highlight: isHighlighted,
            })}
          >
            {endPrice}
          </span>
        </React.Fragment>
      )}
    </span>
  );
};

export const TableCellNumericRenderer = styled(UnStyledTableCellNumericRenderer)`
  .hyphen {
    margin-left: 8px;
    margin-right: 8px;
  }

  .table-cell {
    display: inline-block;

    font-size: 12px;
    color: rgb(96, 106, 107);
    white-space: nowrap;

    border-style: none;

    scale: 1.2;
    transform-origin: left;

    &.price {
      width: 62px;
      text-align: left;

      &.start {
        text-align: right;
      }
    }

    &.right-move {
      width: 54px;
    }

    &.highlight {
      color: rgb(25, 47, 84);

      scale: 1.4;

      &.right-move {
        width: 58px;
      }
    }

    &.agreed-lettings {
      color: rgb(0, 0, 0);
      font-family: "Courier New", Courier, monospace;
      font-size: 16px;

      scale: unset;
    }

    @media (max-width: 768px) {
      text-align: left;

      scale: 1.2;

      &.highlight :not(.agreed-lettings) {
        scale: 1.4;
      }
    }
  }
`;
