import React, { useEffect, useState } from "react";
import { FormStateTypes, GenericForm } from "containers/GenericForm";
import { useForm } from "react-hook-form";
import TextAreaField from "components/FormInputs/TextAreaField";
import * as Styled from "./style";
import { BMTTaskFormInputs, BMTTaskFormTypes, IBMTTaskFormComponentProps } from "./types";
import InputField from "components/FormInputs/InputField";
import {
  useAddInternalComment,
  useAddInternalTask,
  useGetInternalUsers,
  useUpdateInternalComment,
  useUpdateTask,
} from "./queries";
import { IUser } from "xl-types";
import * as chrono from "chrono-node";
import { Button } from "@mui/material";

export const BMTTaskFormComponent = (props: IBMTTaskFormComponentProps) => {
  const hooksForm = useForm<BMTTaskFormInputs>({
    defaultValues: props.defaultValues,
  });
  const {
    mutate: mutateTask,
    isLoading: taskLoading,
    isError: taskError,
    isSuccess: taskSucces,
  } = useAddInternalTask();

  const {
    mutate: mutateComment,
    isLoading: commentLoading,
    isError: commentError,
    isSuccess: commentSuccess,
  } = useAddInternalComment();

  const { mutate: updateComment, error: updateCommentError } = useUpdateInternalComment();
  const { mutate: updateTask, error: updateTaskError } = useUpdateTask();

  const [formState, setFormState] = useState<{
    type: FormStateTypes;
    message: string;
  }>({ type: FormStateTypes.INFO, message: "" });

  const { data: internalUsersData } = useGetInternalUsers();

  // uwc-debug
  useEffect(() => {
    if (commentSuccess || taskSucces) {
      setFormState({
        type: FormStateTypes.SUCCESS,
        message: taskSucces ? "Task submitted successfully!" : "Comment successfully submitted",
      });
      hooksForm.reset();
      if (typeof props.onSubmitted === "function") {
        props.onSubmitted();
      }
    }
    if (taskLoading || commentLoading) {
      setFormState({
        type: FormStateTypes.INFO,
        message: taskLoading ? "Adding new task..." : "Adding a new comment",
      });
    }
    if (taskError || commentError || updateCommentError || updateTaskError) {
      setFormState({
        type: FormStateTypes.ERROR,
        message: taskError ? "Error in saving new task" : "Error while saving new comment",
      });
    }
  }, [
    commentError,
    commentLoading,
    commentSuccess,
    hooksForm,
    taskError,
    taskLoading,
    taskSucces,
    updateCommentError,
    updateTaskError,
  ]);

  const convertMentionsFullNameToIds = (fullNames: string[]) => {
    return fullNames.map(name => {
      const u = internalUsersData?.data.find(u => u.full_name === name);
      return u?.id as string;
    });
  };

  const handleFormSubmit = async (data: BMTTaskFormInputs) => {
    const dataObject: {
      text: string;
      user_mentions?: string[];
      content_object_class_name_str: string;
      object_id: string;
      absolute_trigger?: {
        trigger_time?: string;
      };
    } = {
      text: data.text,
      content_object_class_name_str:
        props.defaultValues?.content_object_class_name_str || props.contentType,
      object_id: props.defaultValues?.object_id || props.objectId,
    };

    if (data.trigger_time) {
      const time = chrono.parseDate(data.trigger_time).toISOString();
      dataObject.absolute_trigger = {
        trigger_time: time,
      };
    }

    if (data.text_user_mentions && data.text_user_mentions.length) {
      const mentionsFullNames = data.text_user_mentions.map(u => u.id);
      const mentionNamesToIds = convertMentionsFullNameToIds(mentionsFullNames);

      dataObject.user_mentions = mentionNamesToIds;
    }

    if (props.defaultValues?.id) {
      const updateData = {
        ...dataObject,
        content_object_class_name_str: props.defaultValues.content_object_class_name_str,
        object_id: props.defaultValues.object_id,
      };

      props.type === BMTTaskFormTypes.COMMENT
        ? updateComment(
            {
              id: props.defaultValues.id,
              data: updateData,
            },
            {
              onSuccess: () => {
                setFormState({
                  type: FormStateTypes.SUCCESS,
                  message: "Comment has been updated",
                });

                setTimeout(() => {
                  hooksForm.reset();
                  if (props.onSubmitted) {
                    props.onSubmitted();
                  }
                }, 500);
              },
            },
          )
        : updateTask(
            {
              id: props.defaultValues.id,
              data: updateData,
            },
            {
              onSuccess: () => {
                setFormState({
                  type: FormStateTypes.SUCCESS,
                  message: "Task has been updated",
                });

                setTimeout(() => {
                  hooksForm.reset();
                  if (props.onSubmitted) {
                    props.onSubmitted();
                  }
                }, 500);
              },
            },
          );
    } else {
      props.type === BMTTaskFormTypes.COMMENT
        ? mutateComment({ data: dataObject })
        : mutateTask({ data: dataObject });
    }
  };

  return (
    <GenericForm
      hooksForm={hooksForm}
      onFormSubmit={handleFormSubmit}
      state={formState}
      autoComplete="task-form"
      defaultValues={props.defaultValues}
      formMode={props.formMode}
    >
      <Styled.TitleView>{props.title}</Styled.TitleView>
      <TextAreaField
        users={internalUsersData?.data as IUser[]}
        placeholder={props.fieldlaceholder || "Notes"}
        autoComplete="Note-form"
        name={"text"}
        helpText="0/32500 characters used"
        inputPlaceholder="Add a note"
        rows={155}
        data-testid={props.type !== BMTTaskFormTypes.COMMENT ? "sr-task-notes" : "sr-notes"}
        required
        maxLength={32500}
        style={{ maxWidth: "unset" }}
        registerOptions={{ required: "Note is required" }}
        defaultValue={props.defaultValues?.text}
      />
      {props.type !== BMTTaskFormTypes.COMMENT && (
        <InputField
          testId="task-time"
          placeholder="Time Trigger"
          autoComplete="landlord-form"
          name="trigger_time"
          showTimeParser
          registerOptions={{ required: "Note is required" }}
        />
      )}
      <Styled.ButtonSectionView>
        <Button
          variant="contained"
          data-testid={props.type !== BMTTaskFormTypes.COMMENT ? "save-task-notes" : "save-notes"}
          type="submit"
          disabled={commentLoading || taskLoading}
        >
          {props.butnText || "Save"}
        </Button>
      </Styled.ButtonSectionView>
    </GenericForm>
  );
};
