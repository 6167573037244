import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from "react";
import { IComposerTabs, IWhatsAppMessagePhoneNumberStatusLog } from "./types";

interface IComposerModalContext {
  activeComposeType: IComposerTabs | undefined;
  activeButton: IComposerTabs | undefined;
  setActiveComposeType: Dispatch<SetStateAction<IComposerTabs | undefined>>;
  setActiveButton: Dispatch<SetStateAction<IComposerTabs | undefined>>;
  activityObject: any;
  setActivityObject: Dispatch<SetStateAction<any>>;
  whatsAppNumberMessageLog: IWhatsAppMessagePhoneNumberStatusLog[];
  setWhatsAppNumberMessageLog: Dispatch<SetStateAction<IWhatsAppMessagePhoneNumberStatusLog[]>>;
  getLatestWhatsAppMessageForNumber: (
    phoneNumber: string,
  ) => IWhatsAppMessagePhoneNumberStatusLog | null;
}

interface IComposeModalProvider {
  children: ReactNode;
}

const ComposerModalContext = createContext<IComposerModalContext>({
  activeButton: undefined,
  activeComposeType: undefined,
  activityObject: undefined,
  whatsAppNumberMessageLog: [],
  setActiveButton: () => {},
  setActiveComposeType: () => {},
  setActivityObject: () => {},
  setWhatsAppNumberMessageLog: () => [],
  getLatestWhatsAppMessageForNumber: () => null,
});

const ComposeModalProvider = ({ children }: IComposeModalProvider) => {
  const [activeComposeType, setActiveComposeType] = useState<IComposerTabs | undefined>(undefined);
  const [activeButton, setActiveButton] = useState<IComposerTabs | undefined>(undefined);
  const [activityObject, setActivityObject] = useState<any>(undefined);
  const [whatsAppNumberMessageLog, setWhatsAppNumberMessageLog] = useState<
    IWhatsAppMessagePhoneNumberStatusLog[]
  >([]);

  /**
   * Get the latest message for a given phone number from the WhatsApp message log.
   * @param {string} phoneNumber - The phone number for which to get the latest message.
   * @returns {Object|null} - The latest message object or null if no messages exist for the phone number.
   */
  const getLatestWhatsAppMessageForNumber = (phoneNumber: string) => {
    const messagesForNumber = whatsAppNumberMessageLog.filter(
      item => item.toNumber === phoneNumber,
    );

    if (messagesForNumber.length === 0) return null;

    messagesForNumber.sort((a, b) => {
      if (!a.timestamp || !b.timestamp) {
        return !a.timestamp && !b.timestamp ? 0 : !a.timestamp ? 1 : -1;
      }

      const aDate = new Date(a.timestamp);
      const bDate = new Date(b.timestamp);

      return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
    });
    return messagesForNumber[messagesForNumber.length - 1];
  };

  return (
    <ComposerModalContext.Provider
      value={{
        activeButton,
        activeComposeType,
        activityObject,
        whatsAppNumberMessageLog,
        setActiveButton,
        setActiveComposeType,
        setActivityObject,
        setWhatsAppNumberMessageLog,
        getLatestWhatsAppMessageForNumber,
      }}
    >
      {children}
    </ComposerModalContext.Provider>
  );
};

const useComposeModal = () => {
  return useContext(ComposerModalContext);
};

export { ComposeModalProvider, useComposeModal };
