import { TableCell } from "../table-cell";
import { Column } from "../types";

export const RenderColumns = <T,>({ children, columnSpan, name }: Column<T>, index: number) => {
  const uid = `col-0-${index}`;
  return (
    <TableCell key={name} columnSpan={columnSpan} id={name} uid={uid}>
      {children}
    </TableCell>
  );
};
