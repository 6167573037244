import { registerValidServiceWorker } from "./register-valid-service-worker";

export const checkValidServiceWorker = (swUrl: string) => {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl)
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      if (
        response.status === 404 ||
        response?.headers?.get("content-type")?.indexOf("javascript") === -1
      ) {
        // No service worker found. Probably a different app. Reload the page.
        window.navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidServiceWorker(swUrl);
      }
    })
    .catch(() => {
      console.error("No internet connection found. App is running in offline mode.");
    });
};
