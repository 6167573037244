import { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ErrorBoundary } from "./containers/ErrorBoundary";
import ErrorBoundaryFallback from "./components/ErrorBoundaryFallback";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MaterialUITheme } from "@mui/material";
import "amazon-connect-streams";
import {
  CallCentreContext,
  callCentreDefaults,
  ICallCentreState,
  ICallCentreContext,
} from "./contexts/callCentreContext";

import { AuthContextProvider } from "contexts/AuthContext";
import { AppRoutes } from "router";
import { ServiceRequestDetailContextProvider } from "contexts/ServiceRequestDetailContext";
import XLDashboard from "./pages/xl-dashboard";
import { SidebarMenuContext } from "./contexts/SidebarMenuContext";
import { SnackbarProvider } from "./contexts/SnackbarContext";
import { ComposeModalProvider } from "./containers/ComposerModal";
import { muiTheme } from "./containers/Layout/mui-theme";

import { SDefaultTheme } from "./containers/Layout/styles";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

function App() {
  const [callCentreState, setAmazonConnectState] = useState<ICallCentreContext["callCentreState"]>(
    callCentreDefaults.callCentreState,
  );
  const [shouldOpenTrelloCardInSeparateTab, setShouldOpenTrelloCardInSeparateTab] = useState(false);
  const handleSetCallCentreState = (val: ICallCentreState) => {
    setAmazonConnectState(prevState => ({
      ...prevState,
      ...val,
    }));
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <ThemeProvider theme={SDefaultTheme}>
        <MaterialUITheme theme={muiTheme}>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <ServiceRequestDetailContextProvider>
                {/*  Call centre context  */}
                <CallCentreContext.Provider
                  value={{
                    callCentreState,
                    handleSetCallCentreState,
                  }}
                >
                  <SidebarMenuContext.Provider
                    value={{
                      shouldOpenTrelloCardInSeparateTab,
                      toggleShouldOpenTrelloCardInSeparateTab: () =>
                        setShouldOpenTrelloCardInSeparateTab(state => !state),
                    }}
                  >
                    <BrowserRouter>
                      <SnackbarProvider>
                        <Suspense fallback={<XLDashboard isLoader />}>
                          <ComposeModalProvider>
                            <AppRoutes />
                          </ComposeModalProvider>
                        </Suspense>
                      </SnackbarProvider>
                    </BrowserRouter>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </SidebarMenuContext.Provider>
                </CallCentreContext.Provider>
              </ServiceRequestDetailContextProvider>
            </AuthContextProvider>
          </QueryClientProvider>
        </MaterialUITheme>
      </ThemeProvider>
    </ErrorBoundary>
  );
}

export default App;
