import * as Styled from "./style";

export const ComposeTabContentActions = ({
  onBackButtonClick,
  onCloseComposeContent,
}: {
  onCloseComposeContent?: () => void;
  onBackButtonClick?: () => void;
}) => {
  return (
    <>
      <Styled.AbsoluteDiv $position="right">
        <Styled.ComposerTabContentActionIcons $show={true}>
          <Styled.CloseIcon
            onClick={onCloseComposeContent}
            data-testid="composer-modal-close-icon"
          />
        </Styled.ComposerTabContentActionIcons>
      </Styled.AbsoluteDiv>

      <Styled.AbsoluteDiv $position="left">
        <Styled.ComposerTabContentActionIcons $show={true}>
          <Styled.BackIcon onClick={onBackButtonClick} data-testid="composer-modal-back-icon" />
        </Styled.ComposerTabContentActionIcons>
      </Styled.AbsoluteDiv>
    </>
  );
};
