import React from "react";
import { Box } from "@mui/material";
import { AsyncAutocomplete } from "components/XLFormComponents/AsyncAutocomplete";

import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";

export const PropertySuitableLandlordFragment = (props: IXLFormFragmentProps) => {
  const { XLFormFragment, getPrefixedFieldName } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  return (
    <XLFormFragment>
      <Box sx={{ mb: 2 }}>
        <AsyncAutocomplete
          matchId
          label="Suitable Landlord"
          name="llsr_attributed_to"
          urlToFetchOptionsOnLoad="/user/landlord/?having-suitable-landlord-lead-service-request=True"
          textFieldProps={{}}
          optionIdFieldName="latest_landlord_lead_service_request"
          onValueChanged={value => {
            props?.useFormReturn?.setValue(
              getPrefixedFieldName("landlord_group"),
              value.landlord_group,
            );
          }}
          required
          autocompleteProps={{
            getOptionLabel: option => {
              return option.full_name;
            },
          }}
        />
      </Box>
    </XLFormFragment>
  );
};
