import React from "react";
import { LinkedObjectContentType } from "components/Notes";
import { useComposeModal } from "./context";
import { BMTTaskFormComponent } from "containers/Forms/BMTTaskForm";
import { IUserUI } from "xl-types";

interface Props {
  linkedObjectContentType: LinkedObjectContentType;
  serviceRequestId: string;
  onCompleted?: () => void;
}

export const TaskFormTabItem = ({
  serviceRequestId,
  linkedObjectContentType,
  onCompleted,
}: Props) => {
  const { activityObject, setActivityObject } = useComposeModal();
  return (
    <BMTTaskFormComponent
      key={`compose-task-${activityObject ? activityObject.id : "no-content"}`}
      title={"Add Task"}
      objectId={serviceRequestId}
      contentType={linkedObjectContentType}
      defaultValues={
        activityObject && {
          text: activityObject.content,
          text_user_mentions: activityObject?.user_mentions?.map((u: IUserUI) => ({
            id: u.id,
            display: u.fullName,
          })),
          trigger_time: activityObject?.triggerTime,
        }
      }
      onSubmitted={() => {
        setActivityObject(undefined);
        onCompleted && onCompleted();
      }}
    />
  );
};
