import { LinkedObjectContentType } from "components/Notes";
import { ITabbedFormSetProps } from "containers/BaseMessageThreadDetail/TabbedFormSet";
import { Dispatch, SetStateAction, ReactNode } from "react";
import { IBaseMessageThread } from "types/baseMessageThread";
import { IGenericServiceRequestAPIData } from "types/serviceRequest";
import { IActivityEmailUI } from "xl-types";

export interface IWhatsAppMessagePhoneNumberStatusLog {
  toNumber: string;
  status?: string;
  timestamp: string | null;
}

export enum ComposerType {
  // Platform = "Platform",
  Call = "Call",
  SMS = "SMS",
  Email = "Email",
  WhatsApp = "WhatsApp",
  Task = "Task",
  Note = "Note",
}

export interface IComposerTypeComponentProps
  extends Omit<ITabbedFormSetProps, "base_message_thread"> {
  baseMessageThread?: IBaseMessageThread;
  linkedObjectContentType: LinkedObjectContentType;
  serviceRequest: IGenericServiceRequestAPIData;
  lastInboundMessage?: IActivityEmailUI;
  onCompleted?: () => void;
}

export interface IComposerTabs {
  label: ComposerType;
  // this is the component that would be rendered for each tab
  // the props of this must satisfy the IComposerTypeComponentProps interface, but can render whatever it wants to
  Component: React.FC<IComposerTypeComponentProps>;
}

export interface IComposerModalContext {
  activeComposeType: IComposerTabs | undefined;
  activeButton: IComposerTabs | undefined;
  setActiveComposeType: Dispatch<SetStateAction<IComposerTabs | undefined>>;
  setActiveButton: Dispatch<SetStateAction<IComposerTabs | undefined>>;
  activityObject: any;
  setActivityObject: Dispatch<SetStateAction<any>>;
  whatsAppNumberMessageLog: IWhatsAppMessagePhoneNumberStatusLog[];
  setWhatsAppNumberMessageLog: Dispatch<SetStateAction<IWhatsAppMessagePhoneNumberStatusLog[]>>;
  getLatestWhatsAppMessageForNumber: (
    phoneNumber: string,
  ) => IWhatsAppMessagePhoneNumberStatusLog | null;
}

export interface IComposeModalProvider {
  children: ReactNode;
}
