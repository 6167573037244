import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./service-worker";
import * as Styled from "./styles";

ReactDOM.render(<Styled.AppView />, document.getElementById("root"));

reportWebVitals();
serviceWorker.register();
