import { toast, TypeOptions } from "react-toastify";

export const sendToastNotification = (msg: string, type: TypeOptions = "default") => {
  toast(msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    type: type,
  });
};
