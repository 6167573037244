import React from "react";
import { XLFormFragmentGroup, IXLFormFragmentProps } from "containers/XLFormPage";
import { FieldValues } from "react-hook-form";
import { BaseListingLikeDescriptionAndSummaryFragment } from "components/PropertyCreation/FormFragments";

export const BaseListingSummaryAndDescriptionFragmentGroup = <
  T extends FieldValues = Record<string, any>,
>(
  props: IXLFormFragmentProps<T>,
) => {
  return (
    <XLFormFragmentGroup {...props}>
      <BaseListingLikeDescriptionAndSummaryFragment />
    </XLFormFragmentGroup>
  );
};
