import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "libconfigs/axios";
import { IXeniaLetsAPIBaseListing } from "types/baseListing";
import { ILSRAPIData } from "types/lsr";

import { IXeniaLetsAPISubListing } from "types/subListing";

const useGetPropertyFeatures = () =>
  useQuery(["fetch-property-features"], () => axios("/property-features/"), { enabled: true });

const useGetPropertyDetails = (id?: string) =>
  useQuery(["property-details-with-id", id], () => axios(`/properties/${id}/`), {
    enabled: !!id,
  });

/** Getting detail of BaseListing , aka "Listing" */
export const useGetBaseListingDetails = (baseListingId?: string) =>
  useQuery(
    ["baselisting-detail", baseListingId],
    () => axios.get<IXeniaLetsAPIBaseListing>(`/base-listing/${baseListingId}/`),
    {
      enabled: !!baseListingId,
    },
  );

export const useGetSubListingDetails = (id: string, baseUrl = "/rightmove-listing/") =>
  useQuery(
    ["sub-listing-details-with-id", id],
    () => axios.get<IXeniaLetsAPISubListing>(`${baseUrl}${id}/`),
    {
      enabled: !!id,
    },
  );

export const useGetLSRDetails = (lsrId?: string) =>
  useQuery(
    ["fetch-lsr-details-with-id", lsrId],
    () => axios.get<ILSRAPIData>(`/letting-service-request/${lsrId}/`),
    {
      enabled: !!lsrId,
    },
  );

const useCreateLSR = (url: string) => {
  return useMutation(
    ["create-lsr-using-property-id"],
    (data: {
      available_from_date: string;
      long_term_monthly_rent: string;
      property: string;
      llsrs_attributed_to: string[];
      vacant_from_date: string;
    }) => axios.post<ILSRAPIData>(url, data),
  );
};
export { useGetPropertyFeatures, useGetPropertyDetails, useCreateLSR };
