import React from "react";
import "./layout.css";
import { SDefaultTheme, GlobalStyle, SToastContainer } from "./styles";

const Layout = ({ children }: { children: React.ReactElement[] | React.ReactElement }) => {
  return (
    <>
      <GlobalStyle theme={SDefaultTheme} />

      <SToastContainer
        position="top-left"
        autoClose={false}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {children}
    </>
  );
};

export default Layout;
