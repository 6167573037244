import React from "react";

import { LinkedObjectContentType } from "components/Notes";
import { BMTTaskFormComponent } from "containers/Forms/BMTTaskForm";
import { BMTTaskFormTypes } from "containers/Forms/BMTTaskForm/types";
import { useComposeModal } from "./context";

interface INoteTabItemProps {
  linkedObjectContentType: LinkedObjectContentType;
  serviceRequestId: string;
  onCompleted?: () => void;
}

export const NoteTabItem = (props: INoteTabItemProps) => {
  const { activityObject, setActivityObject } = useComposeModal();
  return (
    <BMTTaskFormComponent
      key={`compose-notes-${activityObject ? activityObject.id : "no-content"}`}
      type={BMTTaskFormTypes.COMMENT}
      title={"Add Note"}
      objectId={props.serviceRequestId}
      contentType={props.linkedObjectContentType}
      defaultValues={
        activityObject && {
          text: String(activityObject.content),
          text_user_mentions: [],
          id: String(activityObject.id),
        }
      }
      onSubmitted={() => {
        setActivityObject(undefined);
        props?.onCompleted && props.onCompleted();
      }}
    />
  );
};
