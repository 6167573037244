import styled from "styled-components";
import { Container } from "react-bootstrap";

export const DropZoneContainer = styled(Container)`
  display: flex;
  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const SelectFileView = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const SelectFileViewThumbs = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
  flex-direction: row;

  > div {
    display: flex;
    min-width: 0;
    overflow: hidden;
    flex-direction: column;
  }
`;

export const SelectFileViewThumbsImg = styled.img`
  display: block;
  width: auto;
  height: 100%;
`;
