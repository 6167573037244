import { FormGroup, SxProps } from "@mui/material";
import styled from "styled-components";
import { FormControlLabel } from "../FormControlLabel";
import { Checkbox, CheckboxProps } from "../Checkbox";

type CheckboxDataProps = Omit<CheckboxProps, "onSelect" | "checked">;

export type Props = {
  className?: string;
  data: CheckboxDataProps[];
  direction?: "horizontal" | "vertical";
  onSelect: (p: number[]) => void;
  selected?: number[];
  styles?: SxProps;
};

const UnStyledCheckboxGroup: React.FC<Props> = ({
  className,
  data,
  direction = "vertical",
  onSelect,
  selected = [],
  styles,
}) => {
  const handleSelect =
    (index: number) =>
    ({ checked }: { checked: boolean }) => {
      const set = new Set([...selected]); // TODO: Think of more efficient way
      checked ? set.add(index) : set.delete(index);
      onSelect([...set]);
    };

  const renderFormControlLabel = ({ label, ...props }: CheckboxDataProps, index: number) => {
    return (
      <FormControlLabel
        key={label}
        label={label}
        component={
          <Checkbox
            {...{
              ...props,
              checked: selected.some(val => val === index),
              onSelect: handleSelect(index),
            }}
          />
        }
      />
    );
  };

  return (
    <FormGroup className={className} row={direction === "horizontal"} sx={styles}>
      {data.map(renderFormControlLabel)}
    </FormGroup>
  );
};

export const CheckboxGroup = styled(UnStyledCheckboxGroup)``;
