import { IUser, IUserUI } from "xl-types";

export const userModel = (aUser: IUser): IUserUI => {
  const user = aUser ?? {};
  const { userprofile, internaluseradditionalprofile } = user ?? {};
  const {
    amazon_connect_deskphone_status,
    is_allowed_to_view_non_commercial_baselisting_related_data,
  } = internaluseradditionalprofile ?? {};

  return {
    email: user.email,
    firstName: user.first_name,
    fullName: user.full_name,
    id: user.id,
    internalUserAdditionalProfile: {
      amazonConnectDeskphoneStatus: amazon_connect_deskphone_status,
      isAllowedToViewNonCommercialBaselistingRelatedData:
        is_allowed_to_view_non_commercial_baselisting_related_data,
    },
    isAllowedToSeeTestServiceRequests: user.is_allowed_to_see_test_service_requests,
    isStaff: user.is_staff,
    isSuperuser: user.is_superuser,
    lastName: user.last_name,
    pkid: user.pkid,
    userType: user.user_type,
    userName: user.username,
    userProfile: {
      address: userprofile?.address,
      addressLine1: userprofile?.address_line_1,
      addressLine2: userprofile?.address_line_2,
      city: userprofile?.city,
      county: userprofile?.county,
      dateOfBirth: userprofile?.date_of_birth,
      gender: userprofile?.gender,
      id: userprofile?.id,
      phoneNumber: userprofile?.phone_number,
      postCode: userprofile?.post_code,
    },
  };
};
