import BMTReplyForm from "containers/BaseMessageThreadDetail/TabbedFormSet/BMTReplyForm";
import { OutgoingDeliveryMethod } from "types/baseMessageThread";
import React from "react";
import { IComposerTypeComponentProps } from "./types";

interface IBMTFormTabItemProps extends IComposerTypeComponentProps {
  deliveryMethods: OutgoingDeliveryMethod[];
}

export const BMTFormTabItem = ({
  deliveryMethods,
  replyTemplates,
  baseMessageThread,
  handleRefreshThread,
  bmtReplyFormRecipientList,
  bmtReplyFormSubject,
  onCompleted,
  lastInboundMessage,
}: IBMTFormTabItemProps) => {
  return (
    <BMTReplyForm
      deliveryMethods={deliveryMethods}
      replyTemplates={replyTemplates}
      baseMessageThread={baseMessageThread}
      lastInboundMessage={lastInboundMessage}
      handleRefreshThread={handleRefreshThread}
      bmtReplyFormRecipientList={bmtReplyFormRecipientList}
      bmtReplyFormSubject={bmtReplyFormSubject}
      onCompleted={onCompleted}
    />
  );
};
