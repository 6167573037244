import { axiosInstance } from "api/api_base";

import loopsync from "loopsync";
import { PropertyFormTypes } from "types/property";

const handleUploadDocument = (
  objectId: string,
  formType = PropertyFormTypes.PROPERTY,
  file: any,
  category = "106",
  fileType = "100",
  url = `/generic-attachment/document/`,
  done = (e?: Error) => {},
) => {
  const body: Record<string, any> = {
    file_type: fileType,
    category,
    object_id: objectId,
  };

  if (formType === PropertyFormTypes.BASELISTING) {
    body["content_object_class_name_str"] = "BaseListing";
  } else if (formType === PropertyFormTypes.SUBLISTING) {
    body["content_object_class_name_str"] = "SubListing";
  } else {
    body["content_object_class_name_str"] = "Property";
  }

  if (category) {
    body.category = category;
  }
  if (fileType !== "310") {
    if (fileType === "200") {
      body.original_image = file;
    } else {
      body.file = file;
    }
  } else {
    body.url = file.url;
  }

  const fd = new FormData();
  for (const key in body) {
    if (Object.prototype.hasOwnProperty.call(body, key)) {
      const value = body[key];
      fd.append(key, value);
    }
  }

  axiosInstance
    .post(url, fd)
    .then(res => {
      done();
    })
    .catch(e => {
      done();
    });
};

export const uploadAllFilesToAPI = (
  filesForUpload: any[],
  data: {
    objectId: string;
    formType: PropertyFormTypes;
  },
) => {
  return new Promise((resolve, reject) => {
    loopsync(
      filesForUpload,
      (
        mappedFile: File & { fieldName: string; url: string; caption: string },
        done: () => void,
      ) => {
        if (mappedFile.fieldName === "property.epcs") {
          handleUploadDocument(
            data.objectId,
            data.formType,
            mappedFile,
            "106",
            "100",
            `/generic-attachment/document/`,
            done,
          );
        } else if (mappedFile.fieldName === "property.others") {
          handleUploadDocument(
            data.objectId,
            data.formType,
            mappedFile,
            "108",
            "100",
            `/generic-attachment/document/`,
            done,
          );
        } else if (mappedFile.fieldName === "property.attachment_images") {
          handleUploadDocument(
            data.objectId,
            data.formType,
            mappedFile,
            "",
            "200",
            `/generic-attachment/image/`,
            done,
          );
        } else if (mappedFile.fieldName === "property.floor_plans") {
          handleUploadDocument(
            data.objectId,
            data.formType,
            mappedFile,
            "102",
            "100",
            `/generic-attachment/document/`,
            done,
          );
        } else if (mappedFile.url && mappedFile.caption) {
          handleUploadDocument(
            data.objectId,
            data.formType,
            { url: mappedFile.url, caption: mappedFile.caption },
            "102",
            "310",
            `/generic-attachment/url/`,
            done,
          );
        } else {
          done();
        }
      },
      () => {
        resolve([]);
      },
    );
  });
};
