import { RiTrelloFill } from "@react-icons/all-files/ri/RiTrelloFill";

export const menuItems = [
  {
    heading: "Service Requests",
    items: [
      {
        label: "Landlord Lead",
        path: "/home/trello-boards/landlord-lead-service-requests",
        icon: RiTrelloFill,
      },
      {
        label: "Letting",
        path: "/home/trello-boards/letting-service-requests",
        icon: RiTrelloFill,
      },
      {
        label: "Tenant Enquiry",
        path: "/home/trello-boards/tenant-enquiry-service-requests",
        icon: RiTrelloFill,
      },
    ],
  },
];
