import React from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { Coords } from "./types";

const size = "14px";
const padding = "8px";

export type Props = Partial<Coords> & {
  children?: React.ReactNode;
  className?: string;
  markerSize?: number;
  onClose?: () => void;
};

const UnStyledInfoWindow: React.FC<Props> = ({ children, className, onClose }) => {
  return (
    <div className={className}>
      {onClose && (
        <div className={"close"}>
          <Button
            onClick={onClose}
            styles={{
              padding: 0,
              width: size,
              minWidth: "unset",
              height: size,
              fontSize: "12px",
            }}
            title={"x"}
          />
        </div>
      )}
      {children}
      <div className={"triangle"} />
    </div>
  );
};

export const InfoWindow = styled(UnStyledInfoWindow)`
  position: absolute;
  top: calc(${padding} * -0.5);
  left: 0;
  z-index: 1; // No other choice but to bring the Info Window to front

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${padding};

  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 2px 8px 1px rgba(28, 28, 28, 0.5);

  transform: ${({ markerSize }) => `translate(
    -50%,
    calc(-100% - (${markerSize} * 0.5)))`};

  .close {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 8px;
    width: 100%;
  }

  .triangle {
    width: 18px;
    height: 8px;

    background: inherit;
    clip-path: polygon(50% 100%, 0 0, 100% 0%);

    transform: translateY(calc(100% + (${padding} - 2px)));
  }
`;
