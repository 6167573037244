import { DevTool } from "@hookform/devtools";
import { Box, Button, Typography } from "@mui/material";
import { BoxProps } from "@mui/system";
import { useMutation } from "@tanstack/react-query";
import { XLFormModes } from "containers/XLForm";
import { recursiveMap } from "containers/XLForm/recursiveMap";
import { HTTPMethods } from "hooks/useMakeRequest";
import { axios } from "libconfigs/axios";

import React, { ReactNode, useState } from "react";
import { FieldValues, useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import { FormContainer } from "react-hook-form-mui";
import flattenObject from "utils/flattenObject";
import { makeNetworkRequest } from "./makeNetworkRequest";
import * as Styled from "./styles";

export enum XLFormStateTypes {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

/***
 * Form mode create - POST only, needs backend Url
 * form mode edit - PUT or PATCH, needs backend Url
 * form mode view - No method, no need for backend Url
 */

interface IUseXLFormPageOptions<T extends FieldValues = Record<any, any>> {
  /** This is a message that is shown when the form is successfully submitted */
  finalSuccessMessage?: string;

  /** This is BOTH the title that is shown at top of the form UI and also the success or error message refers to this. this is the object's type name like "User" or "Tenant Enquiry" etc*/
  title: string;

  /** A display text for the submit button */
  createButtonText?: string;

  /** A display text for the submit button */
  editButtonText?: string;

  /** The different modes that this form can exist, see @XLFormModes */
  mode: XLFormModes;

  /** Provides a standard white background, padding=2 margin=2 - but you can override it with another MUI sx to have a custom layout of the form */
  boxProps?: BoxProps;

  children?: ReactNode | ReactNode[];

  /** A hook function that can do pre submit validation after all fields have been certified as valid */
  beforeAllSubmit?: ((data: T) => T) | ((data: T) => { isInvalid: boolean; message: string });

  /** A post submit hook function with access to all the created entities based on the API requests result from each formRegistrationOption */
  afterAllSubmit?: (data: T, submitResponses: FieldValues & Record<string, any>) => void;

  testid?: string;
}
export interface IFormGroupRegistration<T> {
  editURL?: string;
  createURL?: string;
  dataPrefix: string;
  editMethod?: HTTPMethods;
  createMethod?: HTTPMethods;
  skipEdit?: boolean;
  skipCreate?: boolean;
  name?: string;
  order: number;
  successCreateMessage?: string;
  successEditMessage?: string;
  customNetworkRequest?: (data: any) => Promise<{ data: any }>;
  beforeSubmit?: (
    t: T,
    rawData?: Record<string, Record<string, any>>,
    submitResponses?: Record<string, T & Record<string, any>>,
  ) => typeof t;
  afterSubmit?: (
    t: T,
    rawData?: Record<string, Record<string, any>>,
    submitResponses?: Record<string, T & Record<string, any>>,
  ) => void;
}

const useXLFormPage = <T extends FieldValues = Record<string, any>>({
  formGroupRegistrations,
  ...useFormProps
}: UseFormProps<T> & {
  formGroupRegistrations: IFormGroupRegistration<T>[];
}) => {
  const useFormReturn = useForm<T>(useFormProps);
  const [formState, setFormState] = useState<{ message: string; type: XLFormStateTypes }>();

  const XLFormPage = <T extends FieldValues = Record<any, any>>({
    beforeAllSubmit,
    afterAllSubmit,
    title,
    createButtonText,
    editButtonText,
    finalSuccessMessage,
    children,
    testid,
    boxProps = {
      sx: {
        bgcolor: "white",
      },
    },
    mode,
  }: IUseXLFormPageOptions<T>) => {
    const { mutateAsync: makeRequestOnSubmit } = useMutation(
      ({
        data,
      }: {
        data: { requestBody: FieldValues; configuration: { url: string; method: HTTPMethods } };
      }) =>
        axios[data.configuration.method.toLowerCase() as "get" | "post" | "put"]<T>(
          data.configuration.url as string,
          data.requestBody,
        ),
    );

    const getPrefixedFieldName = (prefix: string, name: string) => `${prefix}.${name}`;

    // Holds all the submit re

    const handleFormSubmit = async (data: any) => {
      const beforeAllModifiedData = beforeAllSubmit ? beforeAllSubmit(data) : data;
      if (beforeAllModifiedData.isInvalid) {
        setFormState({ type: XLFormStateTypes.ERROR, message: beforeAllModifiedData.message });
        // don't continue the submission process if the beforeSubmit returns a false data, which means that the custom validation fails or data transformation fails
        return;
      }

      const submitResponses: Record<string, any> = {};

      let experiencedNetworkFailure = false;

      for (const formGroupRegistration of formGroupRegistrations) {
        const requestResponse = await makeNetworkRequest(
          mode,
          formGroupRegistration,
          makeRequestOnSubmit,
          beforeAllModifiedData,
          submitResponses,
          setFormState,
        );

        // If we experience any error at any point of the network request, we want to break out of this loop
        if (requestResponse?.error?.data) {
          experiencedNetworkFailure = true;

          if (requestResponse?.error?.data) {
            const flattenedError = flattenObject(requestResponse?.error?.data);
            Object.keys(flattenedError).map(key => {
              useFormReturn.setError(
                getPrefixedFieldName(formGroupRegistration.dataPrefix, key) as any,
                {
                  message: flattenedError[key],
                },
              );
            });
          }

          break;
        }
      }

      if (experiencedNetworkFailure) return;

      afterAllSubmit && afterAllSubmit(beforeAllModifiedData, submitResponses);

      if (finalSuccessMessage) {
        setFormState({
          message: finalSuccessMessage,
          type: XLFormStateTypes.SUCCESS,
        });
      }

      useFormReturn.reset(undefined, { keepValues: true });
    };
    return (
      <Box {...boxProps}>
        <FormContainer
          FormProps={{ "aria-label": "form" }}
          formContext={useFormReturn as UseFormReturn}
          handleSubmit={useFormReturn.handleSubmit(handleFormSubmit)}
        >
          <Box
            sx={{ background: "var(--white) 0% 0% no-repeat padding-box" }}
            margin="var(--md)"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            {title && <Typography variant="subtitle1">{title}</Typography>}
            {recursiveMap(children, child => {
              const childProps = (child as React.ReactElement).props;

              return React.cloneElement(child as React.ReactElement, {
                ...childProps,
                useFormReturn,
                formMode: mode,
              });
            })}
            {useFormReturn.formState.isSubmitting && <Styled.SubmittingOverlayView />}
            <Button
              type="submit"
              size="large"
              data-testid={testid}
              disabled={useFormReturn.formState.isSubmitting || !useFormReturn.formState.isValid}
              variant="contained"
            >
              {mode === XLFormModes.CREATE ? createButtonText || "Save" : editButtonText || "Edit"}
            </Button>
            {process.env.NODE_ENV === "development" &&
            process.env.REACT_APP_TEST_MODE !== "true" ? (
              <DevTool control={useFormReturn.control} />
            ) : null}
            <Styled.MessageSectionView
              $type={formState?.type as XLFormStateTypes}
              data-testid="form-state-message"
            >
              {formState?.message}
            </Styled.MessageSectionView>
            {process.env.NODE_ENV === "development" &&
            process.env.REACT_APP_TEST_MODE !== "true" ? (
              <div>
                {" "}
                Showing errors in development mode:
                <pre>{JSON.stringify(useFormReturn.formState.errors)}</pre>
              </div>
            ) : null}
          </Box>
        </FormContainer>
      </Box>
    );
  };
  return { XLFormPage, useFormReturn };
};

export { useXLFormPage };

export * from "./XLFormFragment";
export * from "./XLFormFragmentGroup";
