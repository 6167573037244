import axios, { AxiosRequestConfig } from "axios";
import { makeUseAxios } from "axios-hooks";
import { AppConstant } from "../xlconstants";

const authRequestInterceptor = (config: AxiosRequestConfig) => {
  if (config && config.headers) {
    // todo: check if access token is expired and if yes get new with refresh token, store in localstorate and send this newly got access token in request header
    // config.headers.authorization = "TOKEN_HERE";
    config.headers.Accept = "application/json";
  }

  return config;
};

export const axiosInstance = axios.create({
  baseURL: AppConstant.BASE_API_URL,
  headers: {
    Authorization: "JWT " + localStorage.getItem("access_token"),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axiosInstance.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  response => {
    // ADD RESPONSE INTERCEPTORS HERE, IF THERE'S ANY
    return response.data;
  },
  error => {
    const { response } = error;

    if (response?.status === 401) {
      performLogout();
    }

    return Promise.reject(error);
  },
);

const useAxios = makeUseAxios({
  axios: axiosInstance,
  defaultOptions: {
    manual: false,
    useCache: false,
    ssr: true,
    autoCancel: true,
  },
});

export const performLogout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  window.location.replace("/login/admin");
};
export default useAxios;
