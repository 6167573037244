import { Select as MUISelect, MenuItem as MUIMenuItem, SelectChangeEvent } from "@mui/material";

import React from "react";
import styled from "styled-components";

interface Option {
  label: string;
  value: string;
}

interface Props {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  options: Option[];
  className?: string;
  label?: string;
  placeholder?: string;
  id?: string;
}

const UnstyledSelect: React.FC<Props> = ({ options, onChange, id, label, placeholder, value }) => {
  return (
    <MUISelect
      id={id}
      data-testid={`SelectInput-select-${id}`}
      placeholder={placeholder}
      label={label}
      onChange={onChange}
      value={value}
    >
      {options.map(({ label, value }, index) => {
        return (
          <MUIMenuItem data-testid={`MenuItem-item-${id}`} key={`${label}-${index}`} value={value}>
            {label}
          </MUIMenuItem>
        );
      })}
    </MUISelect>
  );
};

export const Select = styled(UnstyledSelect)``;
