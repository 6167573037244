import { Checkbox as MUICheckbox, SxProps } from "@mui/material";
import styled from "styled-components";
import { FillColor, Size } from "../types";

export type Props = {
  checked?: boolean;
  checkedIcon?: React.ReactNode;
  className?: string;
  color?: FillColor;
  disabled?: boolean;
  disableRippleAnimation?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  label?: string;
  onSelect?: (p: { checked: boolean }) => void;
  required?: boolean;
  size?: Exclude<Size, "large">;
  styles?: SxProps;
  unCheckedIcon?: React.ReactNode;
  value?: string;
};

const UnstyledCheckbox: React.FC<Props> = ({
  checked = false,
  checkedIcon,
  className,
  color,
  disabled,
  disableRippleAnimation,
  inputProps,
  label,
  onSelect,
  required,
  size,
  styles,
  unCheckedIcon,
  value,
}) => {
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect({ checked: target.checked });
  };

  return (
    <MUICheckbox
      className={className}
      checked={checked}
      checkedIcon={checkedIcon}
      color={color}
      disabled={disabled}
      disableRipple={disableRippleAnimation}
      icon={unCheckedIcon}
      inputProps={{ "aria-label": label, ...inputProps }}
      onChange={handleChange}
      required={required}
      size={size}
      sx={styles}
      value={value}
    />
  );
};

export const Checkbox = styled(UnstyledCheckbox)``;
