import { SxProps } from "@mui/system";
import React from "react";
import { Button } from "xl-components";

export interface ICopyToClipboardProps {
  buttonStyles?: SxProps;
  contentToCopy: string;
  Component: React.ReactNode;
  onCopy: () => void;
}

export const CopyToClipboard = ({
  buttonStyles,
  contentToCopy,
  Component,
  onCopy,
}: ICopyToClipboardProps) => {
  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(contentToCopy);
      onCopy();
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Button onClick={handleClick} styles={buttonStyles} variant="text">
      {Component}
    </Button>
  );
};
