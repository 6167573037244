import * as React from "react";
import { TableCell as MUITableCell } from "@mui/material";
import { Align, Padding, Size } from "../types";

type Props = {
  align?: Align;
  children: React.ReactNode;
  className?: string;
  columnSpan?: number;
  id?: string;
  label?: string;
  padding?: Padding;
  size?: Exclude<Size, "large">;
  uid: string;
};

export const TableCell: React.FC<Props> = ({
  align = "center",
  children,
  columnSpan = 1,
  className,
  id,
  label,
  padding,
  size,
}) => {
  return (
    <MUITableCell
      align={align}
      aria-label={label}
      className={className}
      colSpan={columnSpan}
      id={id}
      padding={padding}
      size={size}
    >
      {children}
    </MUITableCell>
  );
};
