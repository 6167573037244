import { Divider, Grid, InputAdornment } from "@mui/material";
import { Box } from "@mui/system";

import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";

import React, { useCallback, useEffect, useState } from "react";

import { AutocompleteElement, RadioButtonGroup, SelectElement } from "react-hook-form-mui";
import { BiLabel } from "react-icons/bi";
import { FaCouch } from "react-icons/fa";
import { MdOutlineBathroom, MdOutlineBedroomParent } from "react-icons/md";
import { CouncilTaxBand, PropertySubTypes } from "types/property";

export const PropertyLikeDetailsFragment = (
  props: IXLFormFragmentProps & {
    mappedPropertyFeatures: Record<string, { id: string; name: string }[]>;
  },
) => {
  const [propertySubTypeOptions, setPropertySubtypeOptions] = useState<
    {
      id: string;
      label: string;
    }[]
  >([]);

  const { XLFormFragment, getPrefixedFieldName } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  const propertyType = props?.useFormReturn?.watch(getPrefixedFieldName(`property_type`));

  useEffect(() => {
    if (propertyType) {
      const subtypes = PropertySubTypes[propertyType].map(subType => ({
        id: subType.value,
        label: subType.label,
      }));
      setPropertySubtypeOptions(subtypes);
    }
  }, [propertyType]);

  const renderPropertyRenderedAmentities = useCallback(() => {
    console.log("Rerendering");
    return (
      <>
        {props.mappedPropertyFeatures &&
          Object.keys(props.mappedPropertyFeatures).length > 0 &&
          Object.keys(props.mappedPropertyFeatures).map(feature => {
            const selectOptions = props.mappedPropertyFeatures[feature].map(
              (featureItem: { id: string; name: string }) => ({
                id: featureItem.id,
                label: featureItem.name,
              }),
            );

            const inputName = feature.toLowerCase().split(" ").join("_");

            return (
              <Box key={feature} sx={{ pt: 1, pb: 1 }}>
                <AutocompleteElement
                  matchId
                  multiple
                  label={feature}
                  name={`amenities.${inputName}`}
                  options={selectOptions}
                  textFieldProps={{}}
                />
              </Box>
            );
          })}
      </>
    );
  }, [props.mappedPropertyFeatures]);

  return (
    <XLFormFragment>
      <Box sx={{ pt: 1, pb: 1 }}>
        <Grid container spacing={1} sx={{ mb: "20px" }}>
          <Grid item md={6} data-testid="property_type">
            <RadioButtonGroup
              label="Property Type"
              name="property_type"
              required
              options={[
                {
                  label: "House",
                  id: "1",
                },
                {
                  label: "Flat",
                  id: "2",
                },
              ]}
              row
            />
          </Grid>
          <Grid item xs={6}>
            <RadioButtonGroup
              label="New Build"
              name="new_build"
              options={[
                {
                  label: "Yes",
                  id: "true",
                },
                {
                  label: "No",
                  id: "false",
                },
              ]}
              row
            />
          </Grid>
          <Grid item md={12} data-testid="property_sub_type">
            {propertySubTypeOptions.length ? (
              <RadioButtonGroup
                label="Property Sub Type"
                name="property_sub_type"
                required
                options={propertySubTypeOptions as { label: string; id: string }[]}
                row
              />
            ) : null}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <SelectElement
              fullWidth
              required
              label="Bedrooms"
              data-testid="bedrooms-count-select"
              name="bedroom_count"
              options={[
                {
                  label: "Studio",
                  id: 0,
                },
                ...Array(9)
                  .fill("")
                  .map((_, index) => ({ label: index + 1, id: index + 1 })),
              ]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdOutlineBedroomParent />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectElement
              fullWidth
              required
              label="Bathrooms"
              data-testid="bathroom-count-select"
              name="bathroom_count"
              options={[
                ...Array(9)
                  .fill("")
                  .map((_, index) => ({ label: index + 1, id: index + 1 })),
              ]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdOutlineBathroom />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectElement
              fullWidth
              label="Reception Areas"
              required
              data-testid="reception-room-count-select"
              name="reception_rooms_count"
              options={[
                ...Array(9)
                  .fill("")
                  .map((_, index) => ({ label: index + 1, id: index + 1 })),
              ]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FaCouch />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        {renderPropertyRenderedAmentities()}
        <Box sx={{ pt: 1, pb: 1 }}>
          <SelectElement
            fullWidth
            label="Council Tax band"
            data-testid="council-tax-band-select"
            // data-testId="council-tax-band-choices"
            options={Object.keys(CouncilTaxBand).map(label => {
              return {
                label,
                id: CouncilTaxBand[label as keyof typeof CouncilTaxBand],
              };
            })}
            name=" council_tax_band"
          />
        </Box>
      </Box>
    </XLFormFragment>
  );
};
