import { SelectChangeEvent, SxProps } from "@mui/material";
import { FormControl } from "../FormControl";
import { InputLabel } from "../InputLabel";
import { Select } from "../Select";

type Props = {
  value: string;
  data: { label: string; value: string }[];
  onChange: (newOwnerId: string) => void;
  styles?: SxProps;
  className?: string;
  id?: string;
  label?: string;
};

const defaultStyles = { width: "100%" };

export const Dropdown: React.FC<Props> = ({
  data,
  value,
  onChange,
  styles = defaultStyles,
  className,
  id = "select-id",
  label,
}) => {
  const handleChange = (e: SelectChangeEvent) => {
    onChange(e.target.value);
  };
  return (
    <FormControl id={id} styles={Object.assign({}, defaultStyles, styles)} className={className}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select id={id} onChange={handleChange} label={label} value={value} options={data} />
    </FormControl>
  );
};
