import { Box, Tab, Tabs } from "@mui/material";
import { XLFormModes } from "containers/XLForm";
import { HTTPMethods } from "hooks/useMakeRequest";
import { AppConstant } from "xlconstants";
import {
  BaseListingLikeSummaryAndDescriptionFormPage,
  PropertyLikeDetailFormPage,
  PropertyLikeDocumentsFormPage,
  PropertyLikePhotosFloorPlansVideosFormPage,
  SubListingVariationDisplayAddressFormPage,
} from "../FormPages";

import { useGetSubListingDetails } from "../queries";
import { TabPanel } from "./TabPanel";

import { FaSpinner } from "react-icons/fa";
import { PropertyFormTypes } from "types/property";
import { SyntheticEvent, useState } from "react";

export type ISupportedListingVariationPlatforms = "zoopla" | "rightmove";

export const SubListingEditTabGroup = (props: {
  /** The ID for the sublisting */
  sublistingId: string;
  /** The variation type for the sublisting to update */
  sublistingType: ISupportedListingVariationPlatforms;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccesful?: (data: any) => void;
}) => {
  // Constructs the base url for the current sublisting based on the sublisting variation
  const sublistingBaseURL =
    props.sublistingType === "rightmove" ? "/rightmove-listing/" : "/zoopla-listing/";

  // Fetches the sublisting variation using the constructed base url and the sublisting id from the props
  const {
    data: fetchedSubListingResponse,
    refetch: refetchSublistingData,
    isLoading: isLoadingSubListing,
  } = useGetSubListingDetails(props.sublistingId, sublistingBaseURL);

  // Constructs something like http://localhost:8000/api/rightmove-listing/7fbf0621-60ca-41ab-86d1-d85f41d07077/
  const editURL = `${AppConstant.BASE_API_URL}${sublistingBaseURL}${props.sublistingId}/`;

  const handleSubmitSuccessful = (data: any) => {
    props.onSubmitSuccesful && props.onSubmitSuccesful(data);
    refetchSublistingData();
  };
  const [currentTabIndexValue, setCurrentTabIndexValue] = useState(0);

  // Updates the current tab when the tab label is clicked
  const handleCurrentTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTabIndexValue(newValue);
  };

  const tabs = [
    `${props.sublistingType} Listing Details`,
    "Photos/Videos",
    "Documents",
    `${props.sublistingType} Description/Summary`,
    `${props.sublistingType} Name`,
  ];

  return (
    <Box bgcolor="white">
      {isLoadingSubListing && (
        <div>
          <FaSpinner /> Loading {props.sublistingType} Sub Listing ...
        </div>
      )}
      <Tabs
        value={currentTabIndexValue}
        onChange={handleCurrentTabChange}
        aria-label={tabs.join("-")}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map(title => (
          <Tab label={title} key={title} />
        ))}
      </Tabs>

      <TabPanel value={currentTabIndexValue} index={0}>
        <PropertyLikeDetailFormPage
          title={""}
          property={fetchedSubListingResponse?.data}
          editButtonText={`Update ${props.sublistingType} Listing`}
          formMode={XLFormModes.EDIT}
          editURL={editURL}
          editMethod={HTTPMethods.PATCH}
          successEditMessage={`${props.sublistingType} Listing Details Updated Successfully`}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={1}>
        <PropertyLikePhotosFloorPlansVideosFormPage
          property={fetchedSubListingResponse?.data}
          submitButtonText={"Update Sub Listing"}
          propertyType={PropertyFormTypes.SUBLISTING}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={2}>
        <PropertyLikeDocumentsFormPage
          property={fetchedSubListingResponse?.data}
          submitButtonText={"Update Sub Listing"}
          propertyType={PropertyFormTypes.SUBLISTING}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={3}>
        <BaseListingLikeSummaryAndDescriptionFormPage
          baseListingLike={fetchedSubListingResponse?.data}
          editURL={editURL}
          submitButtonText={`Update ${props.sublistingType} Listing Variation`}
          successMessage={`Updated ${props.sublistingType} Listing Variation Successfully`}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={4}>
        <SubListingVariationDisplayAddressFormPage
          subListing={fetchedSubListingResponse?.data}
          sublistingType={props.sublistingType}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
    </Box>
  );
};
