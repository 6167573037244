import styled, { css } from "styled-components/macro";

export const capitalize = css`
  text-transform: capitalize;
`;
// Flexbox Utils
export const flexRow = css`
  display: flex;
  flex-direction: row;
`;
export const flexCol = css`
  display: flex;
  flex-direction: column;
`;
export const flexItemsCenter = css`
  justify-content: center;
  align-items: center;
`;
export const flexItemsCenterTop = css`
  align-items: center;
`;
export const alignItemsCenter = css`
  align-items: center;
`;
export const justifySpaceBetween = css`
  justify-content: space-between;
`;
// Font Size
export const font10 = css`
  font-size: 10px;
`;
