import { IUser } from "xl-types";
import { AppConstant } from "../../xlconstants";
import { axios } from "../../libconfigs";

export const createUser: (data: IUser) => Promise<{ status: number; data: IUser }> = async (
  data: IUser,
) => {
  const response = await fetch(`${AppConstant.BASE_API_URL}/users/new/`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    body: JSON.stringify(data),
  });
  return { status: response.status, data: await response.json() };
};

export const getUser = async () => {
  const { data, status } = await axios.get<IUser>("user/user/me/");
  return { status, data };
};

export const getInternalUsers = async () => {
  const { data, status } = await axios.get<IUser[]>(`/user/staff`);
  return { data, status };
};
