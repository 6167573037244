import { Grid, InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";
import React from "react";
import { TextFieldElement } from "react-hook-form-mui";
import { BiPound } from "react-icons/bi";
import DateFnsProvider from "./DateFnsProvider";
import { StyledDatepicker } from "./styles";

export const LSRCommercialFragment = (props: IXLFormFragmentProps) => {
  const { XLFormFragment } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  return (
    <XLFormFragment {...props}>
      <DateFnsProvider>
        <Box sx={{ pt: 1, pb: 1 }} data-group-name="Tenancy">
          <Typography sx={{ mb: 2 }} variant="h5">
            Tenancy
          </Typography>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <TextFieldElement
                fullWidth
                helperText="Required if Availability Date is set"
                label="Monthly Rent"
                required
                name="long_term_monthly_rent"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BiPound />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* The <StyledDatepicker would not accept other unrecognized props, so we rely on a parent that allows other props and we use cypress.get().contains to find the element that we want*/}
            <Grid item md={4} xs={12} data-testid="available_from_date">
              <StyledDatepicker
                helperText="Required if Monthly Rent is set"
                label="Availability Date"
                required
                inputProps={{
                  name: "available_from_date",
                }}
                disablePast
                name="available_from_date"
                // @ts-ignore TODO: fix types
                parseDate={date => {
                  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                }}
              />
            </Grid>
            <Grid item md={4} xs={12} data-testid="vacant_from_date">
              <StyledDatepicker
                label="Vacant From"
                name="vacant_from_date"
                inputProps={{
                  name: "vacant_from_date",
                }}
                disablePast
                // @ts-ignore TODO: fix types
                parseDate={date => {
                  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DateFnsProvider>
    </XLFormFragment>
  );
};
