import styled from "styled-components/macro";
import { Dropdown } from "react-bootstrap";

export const DropdownIconToggle = styled(Dropdown.Toggle)`
  background-color: transparent !important;
  border: unset !important;
  outline: none;
  box-shadow: none !important;
  color: var(--main);

  :hover,
  :active,
  :focus {
    color: var(--accent) !important;
  }
`;

export const InvalidWhatsAppNumberLabel = styled.small`
  background-color: var(--gray);
`;
