import { XLFormModes } from "containers/XLForm";
import { useXLFormPage } from "containers/XLFormPage";
import { HTTPMethods } from "hooks/useMakeRequest";

import { useEffect } from "react";

import { SubListingDisplayAddressFragmentGroup } from "components/PropertyCreation/FragmentGroups";
import {
  ISubListingVariationDisplayAddressFormPageInputs,
  ISubListingVariationDisplayAddressFormPageProps,
} from "./types";
import { AppConstant } from "xlconstants";

export const SubListingVariationDisplayAddressFormPage = (
  props: ISubListingVariationDisplayAddressFormPageProps,
) => {
  const sublistingBaseURL =
    props.subListing?.platform === "Rightmove" ? "/rightmove-listing/" : "/zoopla-listing/";

  const { useFormReturn, XLFormPage } =
    useXLFormPage<ISubListingVariationDisplayAddressFormPageInputs>({
      formGroupRegistrations: [
        {
          editURL: `${AppConstant.BASE_API_URL}${sublistingBaseURL}${props.subListing?.id}/`,
          editMethod: HTTPMethods.PATCH,
          order: 1,
          dataPrefix: "subListing",
          name: "Property",
          skipCreate: true,
          successEditMessage: "Update Sub Listing Successfully",
          afterSubmit(data) {
            if (typeof props.onSubmitSuccessful === "function") {
              props.onSubmitSuccessful(data);
            }
          },
        },
      ],
    });

  useEffect(() => {
    if (props.subListing?.id) {
      useFormReturn.reset({
        subListing: {
          display_address: props.subListing?.display_address,
        },
      });
    }
  }, [props.subListing, useFormReturn]);

  return (
    <XLFormPage
      title={""}
      mode={XLFormModes.EDIT}
      createButtonText={""}
      editButtonText={"Update Sub Listing"}
    >
      <SubListingDisplayAddressFragmentGroup namePrefix="subListing" />
    </XLFormPage>
  );
};
