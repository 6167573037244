import { XLFormModes } from "containers/XLForm";
import { useXLFormPage } from "containers/XLFormPage";
import { useEffect } from "react";

import {
  IPropertyLikePhotosFloorPlansVideosFormInputs,
  IPropertyLikePhotosFloorPlansVideosFormPageProps,
} from "./types";

import { PhotosFloorPlansVideosFragmentGroup } from "components/PropertyCreation/FragmentGroups";
import { IXenialetsAPIAttachment } from "types/attachments";
import { AppConstant } from "xlconstants";
import { uploadAllFilesToAPI } from "../uploadFilesUtils";
import { PropertyFormTypes } from "types/property";

export const getUploadedAttachmentPreviewURL = (path: string) => {
  const developmentURL = `${AppConstant.BASE_URL}${path}`;
  return process.env.NODE_ENV === "development" ? developmentURL : (path as string);
};

export const PropertyLikePhotosFloorPlansVideosFormPage = (
  props: IPropertyLikePhotosFloorPlansVideosFormPageProps,
) => {
  // also accept the landlord_group

  const { useFormReturn, XLFormPage } =
    useXLFormPage<IPropertyLikePhotosFloorPlansVideosFormInputs>({
      formGroupRegistrations: [
        {
          order: 1,
          dataPrefix: "property",
          name: "Property",
          customNetworkRequest: async (data: {
            floor_plans: File[];
            attachment_urls: IXenialetsAPIAttachment[];
            attachment_images: File[];
          }) => {
            const filesToUpload: any[] = [];
            data.floor_plans && filesToUpload.push(...data.floor_plans);
            data.attachment_urls && filesToUpload.push(...data.attachment_urls);
            data.attachment_images && filesToUpload.push(...data.attachment_images);

            const responses = await uploadAllFilesToAPI(filesToUpload, {
              objectId: props.property?.id as string,
              formType: props.propertyType as PropertyFormTypes,
            });

            return { data: responses };
          },
          successCreateMessage: props.successUploadMessage || "Uploaded Successfully",
          afterSubmit(data) {
            if (typeof props.onSubmitSuccessful === "function") {
              props.onSubmitSuccessful(data);
            }
          },
        },
      ],
    });

  useEffect(() => {
    if (props.property?.id) {
      // Find the Floor Plans documents
      // Find the Videos Documents
      // Find the Photos Documents
      const attachmentUrls = props.property.attachment_urls;
      useFormReturn.reset({
        property: {
          attachment_urls: attachmentUrls?.length
            ? // if there is an attachment url already uploaded, just add an empty field for fast update
              [...attachmentUrls, { url: "", caption: "" }]
            : // If there is no attachment url, add an empty set for fast update
              ([{ url: "", caption: "" }] as IXenialetsAPIAttachment[]),
        },
      });
    }
  }, [props.property, useFormReturn]);

  return (
    <XLFormPage
      title={props.title as string}
      testid={"upload-photos"}
      mode={XLFormModes.CREATE}
      createButtonText={props.submitButtonText}
    >
      <PhotosFloorPlansVideosFragmentGroup
        namePrefix="property"
        uploadedImages={
          props.property?.attachment_images?.map(image => {
            return {
              ...image,
              url: getUploadedAttachmentPreviewURL(image.image_200 || `/media/${image.file}`),
            };
          }) || []
        }
        uploadedAttachments={
          props.property?.attachment_documents?.filter(
            attachment => attachment.category === "Floor Plan",
          ) || []
        }
      />
    </XLFormPage>
  );
};
