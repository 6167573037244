import { DevTool } from "@hookform/devtools";
import React, { FormHTMLAttributes, ReactElement, ReactNode, useEffect, useState } from "react";
import { SubmitHandler, SubmitErrorHandler, UseFormReturn } from "react-hook-form";
import flattenObject from "utils/flattenObject";
import * as Styled from "./styles";

export enum FormStateTypes {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

export enum FormModes {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}
interface ExtendedProps {
  onFormSubmit: (data: any, event?: React.BaseSyntheticEvent) => any | Promise<any>;
  onInvalid?: SubmitErrorHandler<any>;
  hooksForm: UseFormReturn<any>;
  children: ReactNode | ReactNode[];
  state: {
    type: FormStateTypes;
    message: ReactNode;
  };

  /** This decides whether form is in create or edit or view mode */
  formMode?: FormModes;
  Title?: React.ReactElement;

  defaultValues?: Record<string, any>;
}

export declare type FieldValues = Record<string, any>;

type FormProps = FormHTMLAttributes<HTMLFormElement> & ExtendedProps;

export const GenericForm = (props: FormProps) => {
  const {
    onSubmit,
    onFormSubmit,
    onInvalid,
    hooksForm,
    children,
    Title,
    state,
    formMode,
    defaultValues,
    ...formProps
  } = props;

  const [formState, setFormState] = useState<{
    type: FormStateTypes;
    message: ReactNode;
  }>({ type: FormStateTypes.INFO, message: "" });

  const [injectFormState, setInjectFormState] = useState<boolean>(false);

  useEffect(() => {
    setFormState(state);
  }, [state]);

  useEffect(() => {
    hooksForm.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    const formChildren = props.children as any;
    formChildren?.map((child: ReactElement) => {
      if (!child) {
        return null;
      }
      const canInjectFormState = child.props["data-form-inject"] === "form-state";
      if (canInjectFormState) {
        setInjectFormState(true);
      }
      return child;
    });
  }, [props.children]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = hooksForm;

  const customHandleSubmit: SubmitHandler<any> = (data, event) => {
    onFormSubmit(data, event);
  };

  const customHandleErrorSubmit: SubmitErrorHandler<any> = (errors, event) => {
    // We might want to do something nice here, like add an animation to the form form to shake the form.
    // errors = flattenObject(errors);
    // const keys = Object.keys(errors);
    // setFormState({
    //   type: FormStateTypes.ERROR,
    //   message: errors[keys[0]].message,
    // });
  };

  const [isSubmittingOverlay, setIsSubmittingOverlay] = useState(<></>);

  useEffect(() => {
    if (isSubmitting) {
      setIsSubmittingOverlay(<Styled.SubmittingOverlayView />);
    } else {
      setIsSubmittingOverlay(<></>);
    }
  }, [isSubmitting]);

  const renderFormState = (index?: number) => {
    return (
      ((index && injectFormState) || (!injectFormState && !index)) &&
      formState.message &&
      formState.type && (
        <Styled.MessageSectionView
          type={formState.type}
          key={index}
          data-testid="form-state-message"
          id={`${formState.type}XLFormMessage`}
        >
          {formState.message}
        </Styled.MessageSectionView>
      )
    );
  };

  const renderChildrenWithExtendedProps = (children: any) => {
    return children?.map((child: ReactElement, index: number) => {
      if (!child) {
        return null;
      }
      const flattenedErrors = flattenObject(errors);
      let error = flattenedErrors[child?.props.name]?.message;
      // Get error from deeply nested field
      error = error || flattenObject(errors)[`${child.props.name}.message`];
      const extendedProps = {
        register,
        watch,
        key: index,
        errorText: error,
        isInvalid: !!error,
        errors: child.props.groupName ? errors : null,
        formMode: props.formMode,
        hooksForm,
      };
      const canInjectFormState = child.props["data-form-inject"] === "form-state";
      return canInjectFormState
        ? renderFormState(index)
        : React.cloneElement(
            child,
            child.props.name || child.props.groupName ? extendedProps : { key: index },
          );
    });
  };

  return (
    <Styled.FormView
      role="form"
      onSubmit={handleSubmit(customHandleSubmit, customHandleErrorSubmit)}
      {...formProps}
    >
      {isSubmittingOverlay}
      <Styled.FormTitleSize>{Title}</Styled.FormTitleSize>
      {renderChildrenWithExtendedProps(props.children)}
      {renderFormState()}
      {process.env.NODE_ENV === "development" && process.env.REACT_APP_TEST_MODE !== "true" ? (
        <DevTool control={hooksForm.control} />
      ) : null}
    </Styled.FormView>
  );
};
