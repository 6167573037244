import { FormControl as MUIFormControl, SxProps } from "@mui/material";
import styled from "styled-components";

export type Props = {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  label?: string;
  id?: string;
  styles?: SxProps;
};

const UnstyledFormControl: React.FC<Props> = ({ className, disabled, styles, children, id }) => {
  return (
    <MUIFormControl
      data-testid={`FormControl-Control-${id}`}
      className={className}
      disabled={disabled}
      sx={styles}
    >
      {children}
    </MUIFormControl>
  );
};

export const FormControl = styled(UnstyledFormControl)``;
