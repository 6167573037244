import { InputLabel as MUIInputLabel, SxProps } from "@mui/material";
import styled from "styled-components";

export type Props = {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  label?: string;
  id?: string;
  styles?: SxProps;
};

const UnstyledInputLabel: React.FC<Props> = ({ className, disabled, styles, id, children }) => {
  return (
    <MUIInputLabel
      className={className}
      disabled={disabled}
      id={id}
      sx={styles}
      data-testid={`InputLabel-label-${id}`}
    >
      {children}
    </MUIInputLabel>
  );
};

export const InputLabel = styled(UnstyledInputLabel)``;
