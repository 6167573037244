import * as Styled from "./styles";
import { useQuery } from "@tanstack/react-query";

import { ReactComponent as Logo } from "assets/images/logo-white.svg";
import { FiPhoneCall } from "@react-icons/all-files/fi/FiPhoneCall";
import { FiLogOut } from "@react-icons/all-files/fi/FiLogOut";
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { SoftPhoneAgentStatus, CallCentreContext } from "contexts/callCentreContext";
import { performLogout } from "api/api_base";
import * as Api from "api";
import { AuthContext } from "contexts/AuthContext";
import { SidebarMenuContext } from "contexts/SidebarMenuContext";
import { IconType } from "@react-icons/all-files";
import {
  useSeeNonCommercialBaseListingRelatedData,
  useSeeTestsSRs,
  useSetDeskPhoneAgentStatus,
} from "./queries";
import { FiSmartphone } from "@react-icons/all-files/fi/FiSmartphone";
import { isMobile } from "react-device-detect";

interface ISidebarMenuItem {
  heading: string;
  items: {
    label: string;
    path: string;
    icon?: IconType;
  }[];
}

interface ISidebarQuickAction {
  label: string;
  action?: () => void;
  testId?: string;
}

interface ISidebarMenu {
  menuItems: ISidebarMenuItem[];
  quickActions: ISidebarQuickAction[];
  isLoader?: boolean;
}
let timer: NodeJS.Timer;

const SidebarMenu = ({ menuItems, quickActions, isLoader }: ISidebarMenu) => {
  const callContext = useContext(CallCentreContext);
  const { shouldOpenTrelloCardInSeparateTab, toggleShouldOpenTrelloCardInSeparateTab } =
    useContext(SidebarMenuContext) || {};
  const { callCentreState, handleSetCallCentreState } = callContext || {};
  const { data: currentUser, refetch: refetchLoggedInUser } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const { data } = await Api.user.getUser();
      return data;
    },
  });
  const { setAuthenticatedUser } = useContext(AuthContext);
  const { mutate: putSeeTests } = useSeeTestsSRs();
  const {
    mutate: putSeeNonCommercialBaseListingRelatedData,
    isSuccess: seeNonCommercialBaseListingDataSuccess,
  } = useSeeNonCommercialBaseListingRelatedData();
  const { mutate: postSetDeskPhoneSoftPhoneAgentStatus } = useSetDeskPhoneAgentStatus();
  const [isSeeTestChecked, setIsSeeTestChecked] = useState(false);
  const [
    isAllowedToViewNonCommercialBaseListingRelatedData,
    setIsAllowedToViewNonCommercialBaseListingRelatedData,
  ] = useState<boolean | undefined>(undefined);

  const [isDeskPhoneSoftPhoneAgentStatusAvailable, setIsDeskPhoneSoftPhoneAgentStatusAvailable] =
    useState(false);
  const [lastDeskPhoneSoftPhoneAgentStatus, setLastDeskPhoneSoftPhoneAgentStatus] = useState("");
  const [isUserPollingActive, setIsUserPollingActive] = useState(false);

  const firstUpdate = useRef(true);
  const onAmazonConnectOpen = () => {
    if (!isLoader && handleSetCallCentreState && callCentreState) {
      handleSetCallCentreState({
        showAmazonConnectContainer: !callCentreState?.showAmazonConnectContainer,
      });
    }
  };
  useEffect(() => {
    timer = setInterval(() => {
      isUserPollingActive && refetchLoggedInUser();
    }, 4000);

    !isUserPollingActive && clearInterval(timer);
    return () => clearInterval(timer);
  }, [isUserPollingActive, lastDeskPhoneSoftPhoneAgentStatus]);

  const onSetAmazonConnectDeskPhoneSoftPhoneAgentStatus = () => {
    if (callCentreState && callCentreState?.agent?.status !== SoftPhoneAgentStatus.ROUTABLE) {
      postSetDeskPhoneSoftPhoneAgentStatus();
      setIsUserPollingActive(true);
    } else {
      !isDeskPhoneSoftPhoneAgentStatusAvailable &&
        alert(
          "Please log out of the Amazon Connect Soft Phone before trying to log in as a Desk Phone agent.",
        );
    }
  };

  useEffect(() => {
    setIsAllowedToViewNonCommercialBaseListingRelatedData(
      currentUser?.internaluseradditionalprofile
        ?.is_allowed_to_view_non_commercial_baselisting_related_data,
    );
  }, [currentUser]);

  useEffect(() => {
    if (seeNonCommercialBaseListingDataSuccess) {
      setIsAllowedToViewNonCommercialBaseListingRelatedData(
        !isAllowedToViewNonCommercialBaseListingRelatedData,
      );
    }
  }, [seeNonCommercialBaseListingDataSuccess]);

  const onSeeTests = useCallback(
    (shouldSeeTests: boolean) => {
      if (currentUser && currentUser.id) {
        putSeeTests({ id: currentUser.id, shouldSeeTests });
      }
    },
    [currentUser, putSeeTests],
  );

  useEffect(() => {
    const deskphoneStatus =
      currentUser?.internaluseradditionalprofile?.amazon_connect_deskphone_status || null;
    setLastDeskPhoneSoftPhoneAgentStatus(prevState => {
      if (deskphoneStatus && prevState !== deskphoneStatus) {
        setIsUserPollingActive(false);
        return deskphoneStatus;
      } else {
        return prevState;
      }
    });
    setIsDeskPhoneSoftPhoneAgentStatusAvailable(deskphoneStatus === "Available");

    if (currentUser) {
      setAuthenticatedUser(currentUser);
      setIsSeeTestChecked(currentUser.is_allowed_to_see_test_service_requests);
    }
  }, [currentUser]);

  useEffect(() => {
    if (firstUpdate.current) {
      return;
    }

    onSeeTests(isSeeTestChecked);
  }, [isSeeTestChecked, onSeeTests]);

  return (
    <Styled.SidebarAmazonWrapper>
      <Styled.SidebarContainer>
        <Styled.SidebarLogoContainer to="/home/lead-capture-home/">
          <Logo />
        </Styled.SidebarLogoContainer>
        <Styled.MenuGroup>
          <Styled.MenuGroupHeader>Quick Actions</Styled.MenuGroupHeader>
          {quickActions.length > 0 && (
            <ul>
              {quickActions.map(({ action, label, testId }) => (
                <li key={label}>
                  <Styled.QuickActionButton onClick={action} data-testid={testId}>
                    {label}
                  </Styled.QuickActionButton>
                </li>
              ))}
            </ul>
          )}
        </Styled.MenuGroup>
        {menuItems.length > 0 && (
          <Styled.MenuGroup>
            {menuItems.map(({ heading, items }) => (
              <Fragment key={heading}>
                <Styled.MenuGroupHeader>{heading}</Styled.MenuGroupHeader>
                {items.length > 0 && (
                  <ul>
                    {items.map(({ icon: Icon, label, path }) => (
                      <li key={path}>
                        <Styled.MenuLink
                          data-testid="sidebar-menu-item"
                          className={isActive => (isActive ? "active" : "")}
                          to={path}
                        >
                          {Icon && <Icon size={24} />} {label}
                        </Styled.MenuLink>
                      </li>
                    ))}
                  </ul>
                )}
              </Fragment>
            ))}
          </Styled.MenuGroup>
        )}
        <Styled.SidebarMenuFooter>
          <React.Fragment>
            <Styled.SidebarWideButton
              className={
                callCentreState && callCentreState?.showAmazonConnectContainer ? "active" : ""
              }
              onClick={onAmazonConnectOpen}
              data-testid={!isLoader ? "leads-home-icon" : ""}
            >
              <FiPhoneCall size={24} /> Amazon Connect
            </Styled.SidebarWideButton>
            <Styled.SidebarWideButton
              className={isDeskPhoneSoftPhoneAgentStatusAvailable ? "active" : ""}
              onClick={onSetAmazonConnectDeskPhoneSoftPhoneAgentStatus}
            >
              <FiSmartphone size={24} />
              {`Desk phone - ${isDeskPhoneSoftPhoneAgentStatusAvailable ? "Online" : "Offline"}`}
            </Styled.SidebarWideButton>
          </React.Fragment>
          <Styled.SidebarWideButton
            className={shouldOpenTrelloCardInSeparateTab ? "active" : ""}
            onClick={() => {
              toggleShouldOpenTrelloCardInSeparateTab && toggleShouldOpenTrelloCardInSeparateTab();
            }}
            data-testid={!isLoader ? "open-links-in-tabs-mode-button" : ""}
          >
            {shouldOpenTrelloCardInSeparateTab ? "Separate Tab" : "Same Tab"}
          </Styled.SidebarWideButton>
          <Styled.SeeTests>
            <label>
              <input
                type="checkbox"
                readOnly
                name="see-tests"
                checked={isSeeTestChecked}
                onClick={() => {
                  if (firstUpdate.current) firstUpdate.current = false;

                  setIsSeeTestChecked(prevState => !prevState);
                }}
              />
              <span>See test service requests</span>
            </label>
          </Styled.SeeTests>
          <Styled.SeeTests>
            <label>
              <input
                type="checkbox"
                readOnly
                name="is-allowed-to-view-non-commercial-baselisting-related-data"
                checked={isAllowedToViewNonCommercialBaseListingRelatedData}
                onClick={() => {
                  if (
                    currentUser?.id &&
                    isAllowedToViewNonCommercialBaseListingRelatedData !== undefined
                  ) {
                    putSeeNonCommercialBaseListingRelatedData({
                      id: currentUser.id,
                      shouldSeeNonCommercialBaseListingRelatedData:
                        !isAllowedToViewNonCommercialBaseListingRelatedData,
                    });
                  }
                }}
              />
              <span>See non commercial listing data</span>
            </label>
          </Styled.SeeTests>
          <Styled.LoggedInUserContainer>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>Logged in as</span>
              <Styled.LoggedInUser>{currentUser && currentUser.first_name}</Styled.LoggedInUser>
            </div>
            <Styled.LogoutButton onClick={performLogout}>
              <FiLogOut />
            </Styled.LogoutButton>
          </Styled.LoggedInUserContainer>
        </Styled.SidebarMenuFooter>
      </Styled.SidebarContainer>
    </Styled.SidebarAmazonWrapper>
  );
};

export { SidebarMenu };
