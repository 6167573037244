import { axios } from "../../libconfigs";
import { IGenericServiceRequestAPIData } from "xl-types";

export const getServiceRequest = async (type: string, id: string) => {
  const { data, status } = await axios.get<IGenericServiceRequestAPIData>(`/${type}/${id}/`);
  return { status, data };
};

export const updateServiceRequest = async (
  type: string,
  id: string,
  updateData: Partial<IGenericServiceRequestAPIData>,
) => {
  const { data, status } = await axios.patch<IGenericServiceRequestAPIData>(
    `/${type}/${id}/`,
    updateData,
  );
  return { status, data };
};
