import React from "react";
import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";

import { Box } from "@mui/system";
import { InputAdornment } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { BiLabel } from "react-icons/bi";

export const SublistingDisplayAddressFragment = (props: IXLFormFragmentProps) => {
  const { XLFormFragment } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  return (
    <XLFormFragment>
      <Box
        sx={{
          bgcolor: "white",
        }}
      >
        <TextFieldElement
          fullWidth
          required
          label="Display Address"
          name="display_address"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BiLabel />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </XLFormFragment>
  );
};
