import { XLFormModes } from "containers/XLForm";
import { HTTPMethods } from "hooks/useMakeRequest";
import { FieldValues } from "react-hook-form";
import { ILLSRAPIData } from "types/llsr";
import { ILSRAPIData, XeniaLetsTenancyStatus } from "types/lsr";
import { IXeniaLetsAPIBaseProperty } from "types/property";

export const propertySubTypes: Record<string, { label: string; value: string }[]> = {
  "1": [
    { label: "TERRACED", value: "1" },
    { label: "SEMI DETACHED", value: "3" },
    { label: "DETACHED", value: "4" },
    { label: "END OF TERRACE", value: "2" },
    { label: "TOWN HOUSE", value: "22" },
    { label: "COTTAGE", value: "23" },
    { label: "MEWS", value: "5" },
    { label: "VILLA", value: "27" },
    { label: "HMO", value: "512" },
  ],
  "2": [
    { label: "FLAT", value: "8" },
    { label: "GROUND FLAT", value: "7" },
    { label: "STUDIO", value: "9" },
    { label: "MAISONETTE", value: "11" },
    { label: "GROUND MAISONETTE", value: "10" },
    { label: "PENTHOUSE", value: "29" },
    { label: "DUPLEX", value: "56" },
    { label: "TRIPLEX", value: "59" },
  ],
};

interface IPropertyLikeDetailFormPageBaseProps {
  /** property like object (can be property, baselisting , sublisting) for edit mode */
  property?: IXeniaLetsAPIBaseProperty;
  lettingServiceRequest?: ILSRAPIData;
  shouldShowLSRCommercialFragments?: boolean;
  shouldShowAddressFragment?: boolean;

  title?: string;
  editButtonText?: string;
  createButtonText?: string;
  formMode?: XLFormModes;

  createURL?: string;
  editURL?: string;
  createMethod?: HTTPMethods;
  editMethod?: HTTPMethods;
  successCreateMessage?: string;
  successEditMessage?: string;
}

interface IPropertyLikeDetailFormPageForASuitableLandlordProps
  extends IPropertyLikeDetailFormPageBaseProps {
  /** this can only be present o fthe property is a actual Property (not baselisting or sublisting) the landlord Group ID to be used in create mode under whom the property is created */
  landlordLeadServiceRequest?: never;

  /** Controls if the suitable landlord select field is visible. Useful when creating a new LSR for an existing property */
  shouldAllowToCreateForSuitableLandlord?: boolean;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccessful?: (data: any) => void;
}

interface IPropertyLikeDetailFormPageForALandlordGroupProps
  extends IPropertyLikeDetailFormPageBaseProps {
  /** this can only be present o the property is a actual Property (not baselisting or sublisting) the landlord Group ID to be used in create mode under whom the property is created */
  landlordLeadServiceRequest?: ILLSRAPIData;

  /** Controls if the suitable landlord select field is visible. Useful when creating a new LSR for an existing property */
  shouldAllowToCreateForSuitableLandlord?: never;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccessful?: (data: any) => void;
}

interface IPropertyLikeDetailFormPageProps extends IPropertyLikeDetailFormPageBaseProps {
  landlordLeadServiceRequest?: ILLSRAPIData;

  shouldAllowToCreateForSuitableLandlord?: boolean;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccessful?: (data: any) => void;
}

export type PropertyLikeDetailFormPageProps =
  | IPropertyLikeDetailFormPageForALandlordGroupProps
  | IPropertyLikeDetailFormPageForASuitableLandlordProps
  | IPropertyLikeDetailFormPageProps;

export interface IPropertyLikeDetailFields extends IXeniaLetsAPIBaseProperty {
  amenities?: {
    outside_space: string[];
    parking: string[];
    accessibility: string[];
    building_amenities: string[];
    utilities: string[];
    heating: string[];
  };
}

export interface ILSRDetailsFields {
  status?: XeniaLetsTenancyStatus;
  date?: string;
  available_from_date?: string;
  is_available_from_date_estimated?: boolean;
  pets_extra_rent?: number;
  long_term_monthly_rent?: number;
  furnished_type?: string;
  bills?: string;
  extra_bills?: string[];
  rental_type?: string;
  is_vacant?: XeniaLetsTenancyStatus;
  llsr_attributed_to?: string;
  vacant_from_date?: string;
}

export interface IPropertyLikeDetailFormInputs extends FieldValues {
  property: IPropertyLikeDetailFields;
  tenancy?: ILSRDetailsFields;
}
