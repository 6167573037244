import styled from "styled-components/macro";

export const SCard = styled.div`
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.25);
  border: 1px solid rgb(230, 233, 236);
  box-shadow: rgb(13 38 59 / 5%) 0px 10px 60px;
`;

export const SCardHeader = styled.p`
  font-size: var(--lg);
  margin: 0;
`;

export const SFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SAvatar = styled.div`
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 9999px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const SDetail = styled.div`
  font-size: var(--md);
  margin: 0;
`;
