import { IActivitySMSUI, IActivitySMS } from "xl-types";
import { noteModel } from "./note-model";
import { userModel } from "./user-model";

export const smsModel = (activity: IActivitySMS): IActivitySMSUI => {
  const jsonData =
    typeof activity.json_data === "string" ? JSON.parse(activity.json_data) : activity.json_data;
  const user = userModel(activity.sender);
  const comments = activity.internal_comments.map(noteModel);

  return {
    anchorId: activity.anchor_id,
    attachments: activity.attachments,
    automationInfo: activity.automation_info,
    chaserMessageFriendlyName: activity.chaser_message_friendly_name,
    className: activity.class_name,
    comments,
    content: activity.text,
    deliveryMethod: activity.delivery_method,
    documentUrls: activity.document_urls,
    from: jsonData?.from ?? "Unknown",
    hasComment: !!comments.length,
    id: activity.id,
    imageUrls: activity.image_urls,
    isOutgoing: activity.is_outgoing,
    status: {
      name: activity.status,
      date: activity.status_timestamp,
    },
    statusUpdates: jsonData.sendgrid_data?.status_updates,
    statusTimestamp: activity.status_timestamp,
    to: jsonData?.to ?? "Unknown",
    time: activity.time_created,
    type: activity.type,
    user: user.fullName ?? user.email,
  };
};
