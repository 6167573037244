import Axios from "axios";
import qs from "qs";
import { AppConstant } from "xlconstants";
import { storage } from "utils/storage";

export const axios = Axios.create({
  baseURL: AppConstant.BASE_API_URL,
  headers: {
    Authorization: "JWT " + storage.getToken(),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { indices: false });
};

/* Version of Axios without authorization - for publicly accessible endpoints */
export const publicAxios = Axios.create({
  baseURL: AppConstant.BASE_API_URL,
  headers: {
    accept: "application/json",
  },
});
