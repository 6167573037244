import { Box, Button } from "@mui/material";
import { HiPlus } from "@react-icons/all-files/hi/HiPlus";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IXeniaLetsBaseAPIAddress } from "api/properties/types";
import {
  AddressAutocomplete,
  IAddressFullDetails,
} from "components/XLFormComponents/AddressAutoComplete";
import { AsyncAutocomplete } from "components/XLFormComponents/AsyncAutocomplete";
import { PhoneNumberField } from "components/XLFormComponents/PhoneNumberField";
import { FormModes } from "containers/GenericForm";
import { ServiceRequestSnackbar } from "containers/Snackbar";
import useXLForm, { XLFormModes } from "containers/XLForm";
import { CallCentreContext, IContactTraceRecord } from "contexts/callCentreContext";
import { useSnackbar } from "hooks/useSnackbar";
import { axios } from "libconfigs/axios";
import { useContext } from "react";
import { RadioButtonGroup, TextFieldElement } from "react-hook-form-mui";

export enum UsersFormTypes {
  LANDLORD = "landlord",
  TENANT = "tenant",
  UNKNOWN = "unknown",
}

export interface IUsersFormInputs {
  user_type?: string;
  id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  bedroom_count?: number;
  username?: string;
  address?: IXeniaLetsBaseAPIAddress;
  landlord?: string;
  enquired_property?: string | null;
  userprofile?: {
    id?: string;
    phone_number: string;
    address?: IXeniaLetsBaseAPIAddress | null;
    address_line_1: string;
    address_line_2: string;
    post_code: string;
    city: string;
    gender?: string;
    date_of_birth?: string;
    county: string;
  };
}
interface IUsersFormProps {
  defaultValues?: IUsersFormInputs;
  id?: string;
  title?: string;
  userType: UsersFormTypes;
  formMode?: FormModes;
  onSRLinkClicked?: () => void;
  shouldShowLLSRAddressInput?: boolean;
  onAfterSubmit?: (user: IContactTraceRecord) => void;
  onMutate?: any; // temporary, will update once I change all the code here
  hasShadow?: boolean;
  srId?: string; // temporary, will update once I change all the code here
}

const UsersForm = (props: IUsersFormProps) => {
  const { XLForm, useFormReturn } = useXLForm<IUsersFormInputs>({
    defaultValues: {
      email: props.defaultValues?.email || "",
      first_name: props.defaultValues?.first_name || "",
      last_name: props.defaultValues?.last_name || "",
      userprofile: {
        phone_number: props.defaultValues?.userprofile?.phone_number || "",
        address: null,
      },
      user_type: props.userType || UsersFormTypes.TENANT,
      enquired_property: null,
    },
  });

  const userType = useFormReturn.watch("user_type");

  const { addSnackbar } = useSnackbar();

  const queryClient = useQueryClient();
  const callCentreCtx = useContext(CallCentreContext);

  const { callCentreState, handleSetCallCentreState } = callCentreCtx || {};

  const { mutate: executePutCtr } = useMutation(
    (data: Record<string, any>) =>
      axios.put(`/amazonconnect/contacttracerecord/${callCentreState?.contactId}/`, data),
    {
      onSuccess(response) {
        if (handleSetCallCentreState && response.status === 200) {
          if (props.onAfterSubmit) props.onAfterSubmit(response.data);
          handleSetCallCentreState({
            contactTraceRecord: response.data,
            matchedCustomer: response.data.customer,
            showNewUserForm: false,
            showMatchedUser: false,
          });
        }
      },
    },
  );

  const { mutate: createSR } = useMutation(
    (data: Record<string, any>) =>
      axios.post(
        `/${
          props.userType === UsersFormTypes.LANDLORD ? "landlord-lead" : "tenant-enquiry"
        }-service-request/`,
        data,
      ),
    {
      onSuccess: async ({ data }) => {
        if (callCentreState && !!callCentreState.contactId) {
          executePutCtr({
            contact_id: callCentreState?.contactId,
            content_object_class_name_str: "BaseMessageThread",
            object_id: data.base_message_thread,
          });
        }
        queryClient.invalidateQueries([
          "trello-board-data",
          "data",
          props.userType === UsersFormTypes.LANDLORD
            ? "landlord-lead-service-request"
            : "tenant-enquiry-service-request",
        ]);
        addSnackbar(
          <ServiceRequestSnackbar
            link={`/home/${
              userType === UsersFormTypes.LANDLORD
                ? `landlord-lead-service-requests`
                : `tenant-enquiry-service-requests`
            }/${data.pkid}`}
            text={
              userType === UsersFormTypes.LANDLORD
                ? "New Landlord Lead Created"
                : "New Tenant Enquiry Created"
            }
            onClickLink={props.onSRLinkClicked}
          />,
        );
      },
    },
  );

  const userId = props.defaultValues?.id;
  const formMode = userId ? XLFormModes.EDIT : XLFormModes.CREATE;

  const urlToCall = `/user/${userType}/${userId ? `${userId}/` : ""}`;

  return (
    <XLForm
      title={`${props.defaultValues?.id ? "Edit" : "Create"} ${userType}`}
      mode={formMode}
      method={userId ? "patch" : "post"}
      successMessage={`${userId ? "Updated" : "Created New"} ${userType} successfully`}
      backendUrlToCallOnSubmit={urlToCall}
      beforeSubmit={data => {
        if (props.userType === UsersFormTypes.LANDLORD) {
          // Send the address as part of the base object for a landlord data
          data.address = data.userprofile.address;
        }
        return data;
      }}
      afterSubmit={(data, responseData) => {
        if (formMode === XLFormModes.CREATE) {
          let requestData: Record<string, any> = {
            landlord: responseData.id,
            address: data.address,
            bedroom_count: data.bedroom_count,
          };
          if (props.userType === UsersFormTypes.TENANT) {
            requestData = {
              tenant_group: responseData.tenant_group.id,
              enquired_sub_listings: [data.enquired_property.id],
            };
          }
          createSR(requestData);

          useFormReturn.reset();
          if (typeof props.onMutate === "function") {
            props?.onMutate(responseData);
          }
        }
      }}
    >
      <TextFieldElement
        fullWidth
        required
        label="First Name"
        name="first_name"
        InputProps={{
          inputProps: {
            "data-testid": "user-form-first-name",
          },
        }}
        validation={{
          pattern: {
            value: /^[A-Za-zÀ-ÖØ-öø-ÿ-]*$/,
            message: "Numbers and special characters are not valid",
          },
        }}
      />
      <TextFieldElement
        fullWidth
        required
        label="Last Name"
        name="last_name"
        InputProps={{
          inputProps: {
            "data-testid": "user-form-last-name",
          },
        }}
        validation={{
          pattern: {
            value: /^[A-Za-zÀ-ÖØ-öø-ÿ-]*$/,
            message: "Numbers and special characters are not valid",
          },
        }}
      />

      <TextFieldElement
        fullWidth
        required
        type="email"
        label="Email"
        name="email"
        InputProps={{
          inputProps: {
            "data-testid": "user-form-email",
          },
        }}
        validation={{
          pattern: {
            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            message: "Please enter a valid email address",
          },
        }}
      />
      <PhoneNumberField
        fullWidth
        required
        label="Phone Number"
        name="userprofile.phone_number"
        testid="user-form-phone-number"
      />
      {/* Don't show the address autocomplete when there is a user id, we don't support updating the users address */}

      {formMode === XLFormModes.CREATE && (
        <>
          <RadioButtonGroup
            row
            required
            options={[
              { label: "Landlord", id: UsersFormTypes.LANDLORD },
              { label: "Tenant", id: UsersFormTypes.TENANT },
            ]}
            name="user_type"
            label="Contact Type"
          />

          <AddressAutocomplete
            required
            label={`${userType === UsersFormTypes.TENANT ? "Current" : "Lead"} Address`}
            name="userprofile.address"
            onValueChanged={(address: IAddressFullDetails) => {
              useFormReturn.setValue(
                "userprofile.address",
                address as unknown as IXeniaLetsBaseAPIAddress,
              );
            }}
          />

          {userType === UsersFormTypes.LANDLORD && (
            <TextFieldElement
              fullWidth
              required
              label="Bedroom Count"
              name="bedroom_count"
              InputProps={{
                inputProps: {
                  "data-testid": "user-form-bedroom-count",
                },
              }}
            />
          )}
          {userType === UsersFormTypes.TENANT && (
            <AsyncAutocomplete
              required
              reactQueryKey="sublisting-options"
              label="Enquired Sublisting"
              name="enquired_property"
              urlToFetchOptionsOnLoad="/sub-listing/?autoselect_fields_only=True"
              autocompleteProps={{ getOptionLabel: option => option.name }}
            />
          )}
        </>
      )}

      <Box alignItems={"center"} display="flex" justifyContent="center">
        <Button type="submit" size="large" id="saveUserBtn" variant="contained" fullWidth>
          {XLFormModes.CREATE === formMode ? (
            <>
              <HiPlus /> Save
            </>
          ) : (
            <>Update</>
          )}
        </Button>
      </Box>
    </XLForm>
  );
};

export { UsersForm };
