import { ToastContainer } from "react-toastify";
import styled, { createGlobalStyle, DefaultTheme } from "styled-components/macro";

export const calcResize = (min: number, max: number) =>
  `calc(${min}px + (${max} - ${min}) * (100vw - 320px) / (2560 - 320))`;

export const SDefaultTheme = {
  fontFamily: "Basis Grotesque, sans-serif",
  shadow: "0px 3px 6px rgba(0, 0, 0, 0.15)",

  colors: {
    red: "var(--red)",
    blue: "var(--blue)",
    green: "var(--green)",
    white: "var(--white)",
    danger: "var(--danger)",
    error: "var(--red)",
    gray: "var(--gray)",
    darkgray: "var(--darkgray)",
    grey: "var(--grey)",
    darkergray: "var(--darkergray)",
    black: "var(--black)",
    main: "var(--main)",
    accent: "var(--accent)",
    inactive: "var(--inactive)",
    lightBlue: "var(--light-blue)",
  },
  breakpoints: {
    xs: "0",
    mobile: "425",
    laptop: "1024",
    desktop: "1440",
    hd: "2560",
  },
  fontSizes: {
    lead: `${calcResize(14, 18)}`,
    main: `${calcResize(14, 18)}`,
    title: "1rem",
    p: `${calcResize(14, 18)}`,
    span: `${calcResize(14, 18)}`,
    h1: `${calcResize(48, 70)}`,
    h2: `${calcResize(36, 60)}`,
    h3: `${calcResize(28, 46)}`,
    h4: `${calcResize(24, 32)}`,
    h5: `${calcResize(15, 17)}`,
    h6: `${calcResize(12, 15)}`,
    button: "15px",
  },
  sizes: {
    xxs: "3px",
    xs: "8px",
    sm: "12px",
    md: "16px",
    lg: "22px",
    xl: "30px",
    xxl: "45px",
  },
  inputs: {
    groupMarginBottom: "15px",
    color: "var(--grey)",
    error: "var(--red)",
    help: "var(--blue)",
    borderWidth: "1px",
    paddingX: "10px",
    paddingY: "10px",
    borderColor: "#0000001F",
    borderSize: "2px",
    borderError: "var(--danger)",
    borderRadius: "3px",
    placeholderColor: "var(--grey)",
  },
};

const clearfix = {
  marginLeft: 0,
  marginRight: 0,
  marginTop: 0,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  // marginBottom: '15px',
};

const headingClearfix = {
  lineHeight: 1.1,
  letterSpacing: -0.92,
  fontWeight: "bold",
};

export const GlobalStyle = createGlobalStyle<
  DefaultTheme & {
    theme: {
      colors: Record<string, any>;
      fontFamily: string;
      fontSizes: Record<string, any>;
    };
  }
>`
  html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: ${props => props.theme.colors.main};
  font-family: ${props => props.theme.fontFamily};
  font-size: ${props => props.theme.fontSizes.main};
  font-weight: normal;
  word-wrap: break-word;
  font-kerning: normal;
  -moz-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -ms-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt';
}
p {
  ${clearfix}
  color: ${props => props.theme.colors.p};
  font-size: ${props => props.theme.fontSizes.p};
  // letter-spacing: -0.4px;
}
span {
  ${clearfix}
  color: ${props => props.theme.colors.p};
  font-size: ${props => props.theme.fontSizes.span};
  // letter-spacing: -0.4px;
}
h1 {
  ${clearfix}
  ${headingClearfix}
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h1}
}
h2 {
  ${clearfix}
  ${headingClearfix}
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h2}
}
h3 {
  ${clearfix}
  ${headingClearfix}
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h3}
}
h4 {
  ${clearfix}
  ${headingClearfix}
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h4}
}
h5 {
  ${clearfix}
  ${headingClearfix}
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.h};
  font-size: ${props => props.theme.fontSizes.h5}
}
h6 {
  ${clearfix}
  ${headingClearfix}
  text-rendering: optimizeLegibility;
  color: ${props => props.theme.colors.accent};
  font-size: ${props => props.theme.fontSizes.h6};
  text-transform: uppercase;
}
a{
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  color: ${props => props.theme.colors.a};
  // letter-spacing: -0.4px;
}
a:active,
a:hover {
  outline-width: 0;
  text-decoration: underline;
  color: ${props => props.theme.colors.a};
}
.lead {
  color: ${props => props.theme.colors.lead};
  font-size: ${props => props.theme.fontSizes.lead};
  // letter-spacing: -0.4px;
}

input {
  // ::placeholder {
  //   color: ${props => props.theme.colors.main};
  //   opacity: 0.6;
  //   font-size: 16px;
  // }
}

  div[class*="col"] {
    position: relative !important;
  }



@media (min-width: 2560px){
.container {
    max-width: 1920px;
}}


`;

export const SToastContainer = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast--error {
    background-color: ${props => props.theme.colors.danger};
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
    background-color: ${props => props.theme.colors.green};
  }
  .Toastify__toast-body {
    padding: 15px;
    font-size: 16px;
  }
  .Toastify__progress-bar {
  }
`;

export * as CSSUtils from "./css-utils";
