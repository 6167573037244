import { ListItem as MUIListListem, SxProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  styles?: SxProps;
  disablePadding?: boolean;
};

const UnstyledListItem: React.FC<Props> = ({ children, styles, disablePadding = false }) => {
  return (
    <MUIListListem disablePadding={disablePadding} sx={styles}>
      {children}
    </MUIListListem>
  );
};

export const ListItem = styled(UnstyledListItem)``;
