import { Alert, AlertColor, Snackbar as MUISnackbar } from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  message: string;
  onClose?: () => void;
  autoHideDuration?: number;
  type?: ISnackbarType;
}

export type ISnackbarType = AlertColor;

export const Snackbar: React.FC<Props> = ({
  type = "success",
  autoHideDuration = 3000,
  open,
  onClose,
  message,
}) => {
  return (
    <MUISnackbar open={open} autoHideDuration={autoHideDuration} onClose={onClose}>
      <Alert onClose={onClose} severity={type} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </MUISnackbar>
  );
};
