import React from "react";
import { XLFormFragmentGroup, IXLFormFragmentProps } from "containers/XLFormPage";
import { FieldValues } from "react-hook-form";
import { SublistingDisplayAddressFragment } from "components/PropertyCreation/FormFragments";

export const SubListingDisplayAddressFragmentGroup = <T extends FieldValues = Record<string, any>>(
  props: IXLFormFragmentProps<T>,
) => {
  return (
    <XLFormFragmentGroup {...props}>
      <SublistingDisplayAddressFragment namePrefix="subListing" />
    </XLFormFragmentGroup>
  );
};
