import {
  IActivityWhatsappUI,
  IActivityWhatsapp,
  IActivityStatus,
  IActivityStatusUpdate,
} from "xl-types";
import { noteModel } from "./note-model";
import { userModel } from "./user-model";
import { sortStatusUpdates } from "./activity-transformer";

export const whatsappModel = (activity: IActivityWhatsapp): IActivityWhatsappUI => {
  const jsonData =
    typeof activity.json_data === "string" ? JSON.parse(activity.json_data) : activity.json_data;
  const user = userModel(activity.sender);
  const comments = activity.internal_comments.map(noteModel);

  const status_updates: {
    [x: string]: {
      _Timestamp: string;
      SmsStatus: [IActivityStatus];
    };
  } = jsonData?.status_updates || {};

  const statusUpdates: IActivityStatusUpdate[] = Object.keys(status_updates).map(statusKey => {
    const update = status_updates[statusKey];
    return {
      status: update.SmsStatus[0],
      timestamp: update._Timestamp,
    };
  });

  return {
    anchorId: activity.anchor_id,
    comments,
    content: activity.text,
    from: jsonData.direction === "outbound-api" ? jsonData?.from_ : jsonData?.From,
    id: activity.id,
    hasComment: !!comments.length,
    status: {
      name: activity.status || jsonData?.SmsStatus,
      date: activity.status_timestamp,
    },
    statusUpdates: sortStatusUpdates(statusUpdates),
    time: activity.time_created,
    to: jsonData.direction === "outbound-api" ? jsonData?.to : jsonData?.To,
    type: activity.type,
    user: user.fullName ?? user.email,
  };
};
