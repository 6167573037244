import { XLFormModes } from "containers/XLForm";
import { recursiveMap } from "containers/XLForm/recursiveMap";
import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";

interface IUseXLFormFragmentOptions {
  /** The different modes a fragment can exists, mostly available from the parent */
  formMode?: XLFormModes;

  /** A name to prefix all the fields for this fragment group */
  namePrefix?: string;

  /** A list of field names that should be disabled when the form is in edit mode */
  disableFieldsOnEdit?: string[];
}

export interface IXLFormFragmentProps<T extends FieldValues = Record<string, any>>
  extends IUseXLFormFragmentOptions {
  children?: React.ReactNode;

  /** An access to the useReactHookForm return value that initilizes the form component */
  useFormReturn?: UseFormReturn<T>;
}

export const useXLFormFragment = (useXLFragmentOptions: IUseXLFormFragmentOptions) => {
  /**
   * Determines if the fielld name can be disabled or not
   * @param fieldName The name of the field
   * @returns {boolean | undefined} a modified property field name
   */
  const canDisableField = (fieldName: string): boolean | undefined =>
    useXLFragmentOptions.disableFieldsOnEdit?.includes(fieldName) &&
    useXLFragmentOptions.formMode === XLFormModes.EDIT;

  /**
   * Returns a prefixed name matching the fragment's prefix props
   * @param {string} name The unprefixed field name of an input field
   * @returns {string} a prefixed name of the passed input field name.
   */
  const getPrefixedFieldName = (name: string): string =>
    `${useXLFragmentOptions.namePrefix}.${name}`;

  const XLFormFragment = (props: { children: React.ReactNode } & Record<string, any>) => {
    return (
      <div>
        {recursiveMap(props.children, child => {
          const name = (child as React.ReactElement)?.props?.name;
          return React.cloneElement(child as React.ReactElement, {
            name: name && getPrefixedFieldName(name),
            disabled: name && canDisableField(name),
          });
        })}
      </div>
    );
  };

  return { XLFormFragment, getPrefixedFieldName, canDisableField };
};
