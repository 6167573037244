import React from "react";
import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";
import { Box } from "@mui/system";
import { TextFieldElement } from "react-hook-form-mui";
import { Grid } from "@mui/material";

export const BaseListingLikeDescriptionAndSummaryFragment = (props: IXLFormFragmentProps) => {
  const { XLFormFragment } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  return (
    <XLFormFragment>
      <Box
        sx={{
          bgcolor: "white",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextFieldElement
              label="Description"
              name="description"
              multiline
              required
              fullWidth
              rows={4}
              validation={{ required: "Description is required" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldElement
              validation={{ required: "Summary is required" }}
              label="Summmary"
              required
              name="summary"
              multiline
              fullWidth
              rows={4}
            />
          </Grid>
        </Grid>
      </Box>
    </XLFormFragment>
  );
};
