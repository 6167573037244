import { useEffect } from "react";
import { useLocation, useRoutes } from "react-router-dom";
import ReactGA from "react-ga4";
import { useAuth } from "hooks/useAuth";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";
import { unauthenticatedRoutes } from "./unauthenticated";

const TRACKING_ID = "G-GW4DT419ZH";
ReactGA.initialize(TRACKING_ID, { testMode: process.env.NODE_ENV === "test" });

export const AppRoutes = () => {
  const location = useLocation();
  const isAuthenticated = useAuth();

  const routes = isAuthenticated ? protectedRoutes : unauthenticatedRoutes;

  const element = useRoutes([...routes, ...publicRoutes]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return <>{element}</>;
};
