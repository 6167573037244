import styled from "styled-components";
import { Button, ButtonProps } from "../Button";
import { CSSProperties } from "react";
import { SxProps } from "@mui/system";

export type ButtonEventFn = (label: string, e?: { target: EventTarget }, index?: number) => void;

type ButtonItemProps = Omit<ButtonProps, "onClick" | "onMouseOver"> & {
  onClick?: ButtonEventFn;
  onMouseOver?: ButtonEventFn;
  label: string;
};

export type Props = Omit<ButtonProps, "onClick" | "onMouseOver"> & {
  className?: string;
  data: string[];
  direction?: "horizontal" | "vertical";
  onClick?: ButtonEventFn;
  onMouseOver?: ButtonEventFn;
  styles?: CSSProperties;
  buttonStyles?: SxProps;
};

const UnStyledButtonGroup: React.FC<Props> = ({
  className,
  data,
  styles,
  buttonStyles,
  ...buttonProps
}) => {
  const renderButtonItem = ({ label, ...props }: ButtonItemProps, index: number) => {
    return (
      <Button
        {...props}
        onClick={e => props.onClick && props.onClick(label, e, index)}
        onMouseOver={e => props.onMouseOver && props.onMouseOver(label, e, index)}
        id={`${props.id}-${label?.split(" ").join("-").toLowerCase()}`}
      >
        {label}
      </Button>
    );
  };

  return (
    <div className={className} style={styles}>
      {data.map((label, index) => {
        return renderButtonItem({ label, styles: buttonStyles, ...buttonProps }, index);
      })}
    </div>
  );
};

export const ButtonGroup = styled(UnStyledButtonGroup)`
  display: flex;
  flex-direction: ${props => (props.direction === "horizontal" ? "row" : "column")};
  gap: 10px;
`;
