import { Link, NavLink } from "react-router-dom";
import styled from "styled-components/macro";

export const SidebarAmazonWrapper = styled.div`
  display: flex;
  min-height: 100%;
`;

export const SidebarContainer = styled.aside`
  background-color: var(--main);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const SidebarLogoContainer = styled(Link)`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  &:hover {
    text-decoration: none;
    color: white;
  }

  p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

export const SidebarLogo = styled.img`
  width: 42px;
`;

export const MenuGroup = styled.div`
  ul {
    padding-left: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const MenuGroupHeader = styled.div`
  color: white;
  margin-bottom: 0.5rem;
  font-weight: 600;
`;

export const QuickActionButton = styled.button`
  all: unset;
  color: white;
  font-size: 0.95rem;
  white-space: nowrap;
  padding-left: 0.5rem;
`;

export const MenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
  font-size: 0.95rem;
  white-space: nowrap;
  padding: 0.5rem;
  border-radius: var(--input-border-radius);

  &.active,
  &:hover {
    color: var(--main);
    background-color: #c1d0ea;
    text-decoration: none;
  }
`;

export const SidebarMenuFooter = styled.div`
  margin-top: auto;
  color: white;
`;

export const SidebarWideButton = styled.button`
  border: 1px solid white;
  border-radius: var(--input-border-radius);
  padding: 0.5rem 1rem;
  color: white;
  width: 100%;
  margin-bottom: 0.5rem;
  background-color: var(--main);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &.active {
    background-color: white;
    color: var(--main);
  }
`;

export const LoggedInUserContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: var(--input-border-radius);
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 0.9rem;
  }
`;

export const LoggedInUser = styled.span`
  font-weight: 600;
  font-size: 1rem;
`;

export const LogoutButton = styled.button`
  all: unset;
`;

export const SeeTests = styled.div`
  margin-bottom: 0.5rem;

  label {
    color: var(--gray);
    line-height: 1rem;
    display: flex;
    align-items: center;

    span {
      font-size: 0.9rem;
      margin-left: 0.25rem;
    }
  }
`;
