import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";
const CreateLandlordPage = lazy(() => import("pages/create-landlord"));
const XLDashboard = lazy(() => import("pages/xl-dashboard"));
const XLDashboardLeadCaptureHome = lazy(() => import("pages/xl-dashboard/lead-capture-home"));
const XLDashboardTrelloBoards = lazy(() => import("pages/xl-dashboard/trello-boards"));
const XLDashboardLettingServiceRequestDetail = lazy(
  () => import("pages/xl-dashboard/letting-service-request-detail"),
);
const XLDashboardLandlordLeadServiceRequestDetail = lazy(
  () => import("pages/xl-dashboard/landlord-lead-service-request-detail"),
);
const XLDashboardTenantEnquiryServiceRequestDetail = lazy(
  () => import("pages/xl-dashboard/tenant-enquiry-service-request-detail"),
);

const XLDashboardLettingServiceRequestsTrello = lazy(
  () => import("pages/xl-dashboard/trello-boards/letting-service-requests-trello"),
);
const XLDashboardTenantEnquiryServiceRequestsTrello = lazy(
  () => import("pages/xl-dashboard/trello-boards/tenant-enquiry-service-requests-trello"),
);
const XLDashboardLandlordLeadServiceRequestsTrello = lazy(
  () => import("pages/xl-dashboard/trello-boards/landlord-lead-service-requests-trello"),
);
// const XLDashboardPropertyCreation = lazy(() => import("pages/xl-dashboard/property/create"));
// const XLDashboardPropertyDetail = lazy(() => import("pages/xl-dashboard/property/detail"));
const CreateTenantPage = lazy(() => import("pages/createtenant"));
const Homepage = lazy(() => import("pages/homepage"));

const PropertyCreationPage = lazy(() => import("pages/test-page"));

export const protectedRoutes: RouteObject[] = [
  {
    path: "/",
    index: true,
    element: <Navigate to="/home" replace={true} />,
  },
  {
    path: "/oldhome",
    index: false,
    element: <Homepage />,
  },

  {
    path: `/home`, // can accept landlord_id as param
    index: false,
    element: <XLDashboard />,
    children: [
      {
        path: "test-page",
        element: <PropertyCreationPage />,
      },
      {
        path: `lead-capture-home`,
        element: <XLDashboardLeadCaptureHome />,
        children: [
          {
            path: "create-landlord",
            element: <CreateLandlordPage />,
          },
          {
            path: "create-tenant",
            element: <CreateTenantPage />,
          },
        ],
      },
      {
        path: `trello-boards`,
        element: <XLDashboardTrelloBoards />,
        children: [
          {
            path: `letting-service-requests`,
            element: <XLDashboardLettingServiceRequestsTrello />,
          },
          {
            path: `tenant-enquiry-service-requests`,
            element: <XLDashboardTenantEnquiryServiceRequestsTrello />,
          },
          {
            path: `landlord-lead-service-requests`,
            element: <XLDashboardLandlordLeadServiceRequestsTrello />,
          },
        ],
      },
      // {
      //   path: `property/:propertyId`,
      //   element: <XLDashboardPropertyDetail />,
      // },
      // {
      //   path: `property/`,
      //   element: <XLDashboardPropertyCreation />,
      // },
      {
        path: `letting-service-requests/:lsrId`,
        element: <XLDashboardLettingServiceRequestDetail />,
      },
      {
        path: `landlord-lead-service-requests/:llsrId`,
        element: <XLDashboardLandlordLeadServiceRequestDetail />,
      },
      {
        path: `tenant-enquiry-service-requests/:tesrId`,
        element: <XLDashboardTenantEnquiryServiceRequestDetail />,
      },
    ],
  },

  { path: "*", element: <Navigate to="/home" /> },
];
