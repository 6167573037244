import { Link } from "react-router-dom";
import * as Styled from "./styles";

const ServiceRequestSnackbar = ({
  text,
  link,
  onClickLink,
}: {
  text: string;
  link: string;
  onClickLink?: () => void;
}) => {
  return (
    <Styled.UserSnackbarMessage>
      <div>{text}</div>
      <Link onClick={onClickLink} to={link}>
        VIEW
      </Link>
    </Styled.UserSnackbarMessage>
  );
};

export { ServiceRequestSnackbar };
