export interface IReplyTemplate {
  id: number;
  name: string;
  attachment_file_keys: string[];
  category: string;
  category_by_linked_object: string;
  insertable: boolean;
  is_content_html: boolean;
}

export const enum OutgoingDeliveryMethod {
  PLATFORM = "platform",
  EMAIL = "email",
  SMS = "sms",
  WHATSAPP_TEXT = "whatsapp",
  PHONE = "phone",
  VIDEO = "video",
}

export interface IDeliveryMethodOption {
  delivery_method_name: OutgoingDeliveryMethod;
  recipient_list: IEmailRecipientList[] | ISmsRecipientList[];
}

export type IDeliveryMethodOptions = {
  [key in OutgoingDeliveryMethod]?: IDeliveryMethodOption;
};

export interface IBaseRecipientList {
  name: string;
  value: string;
}

export interface IEmailRecipientList extends IBaseRecipientList {
  email: string;
}

export interface ISmsRecipientList extends IBaseRecipientList {
  phone_number: string;
}
