import * as React from "react";
import { TableBody as MUITableBody } from "@mui/material";

type Props = {
  children: React.ReactNode;
  className?: string;
};

export const TableBody: React.FC<Props> = ({ children, className }) => {
  return <MUITableBody className={className}>{children}</MUITableBody>;
};
