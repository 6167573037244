import React, { InputHTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from "react";
import { IUser } from "xl-types";

import { FormModes } from "containers/GenericForm";
import {
  SSection,
  SHelpText,
  SPlaceholder,
  SIconWrapper,
  SCustomInputWrapper,
  SInputModeIconWrapper,
} from "./styles";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import { IBaseInputFieldProps } from "components/FormInputs/InputField";
import { Mention, MentionItem } from "react-mentions";
import * as Styled from "./styles";
import { defaultMentionStyle, defaultStyle } from "./mentions-default-style";

interface ITextareFieldProps {
  inputPlaceholder?: string;
  noMargin?: boolean;
  users?: IUser[];
  width?: string;
  rows?: number;
  cols?: number;
}

export type TextareaFieldProps = IBaseInputFieldProps &
  ITextareFieldProps &
  InputHTMLAttributes<HTMLTextAreaElement>;

const TextAreaField = (props: TextareaFieldProps) => {
  const {
    errorText,
    register,
    watch,
    isInvalid,
    iconPadding,
    Icon,
    CustomInput,
    formMode,
    inputGroupItem,
    inputPlaceholder,
    hooksForm,
    ...inputProps
  } = props;

  const customPlaceholder = props.required ? `${props.placeholder}*` : props.placeholder;

  let customInputFields = {
    ...inputProps,
  };

  if (register) {
    customInputFields = {
      ...customInputFields,
      ...register(props.name, props.registerOptions as never),
    };
    if (inputProps.users) {
      register(`${props.name}_user_mentions`, {});
    }
  }

  const [mentionsValue, setMentionsValue] = useState((props.defaultValue as string) || "");

  const handleCleanField = () => {
    const input: HTMLInputElement | null = document.querySelector(`textarea[name='${props.name}']`);
    // Clear the input fields
    if (input) {
      input.innerText = "";
    }
    if (mentionsValue) {
      setMentionsValue("");
    }
  };

  // @ts-ignore
  const value = watch(props.name, props.defaultValue || props.defaultChecked);
  if (!value) {
    handleCleanField();
  }
  const $helpText = `${value ? value.length : 0}/${props.maxLength} characters used`;
  const mentionsInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (mentionsInputRef.current && inputProps) {
      const _inputProps = { ...inputProps, registerOptions: null } as any;
      Object.keys(_inputProps).map((key: string) => {
        const value = _inputProps[key];
        mentionsInputRef.current?.setAttribute(key, value);
      });
    }
  }, [inputProps, mentionsInputRef.current]);

  const handleMentionsChange = (
    e: { target: { value: string } },
    newValue: string,
    newPlainTextValue: string,
    mentions: MentionItem[],
  ) => {
    setMentionsValue(e.target.value);
    hooksForm?.setValue(props.name, newValue, { shouldDirty: true, shouldTouch: true });
    if (mentions && mentions.length) {
      hooksForm?.setValue(`${props.name}_user_mentions`, mentions, {
        shouldDirty: true,
        shouldTouch: true,
      });
    }
  };

  return (
    <>
      <SSection
        $width={props.width}
        $iconPadding={iconPadding || !!Icon}
        $noMargin={props.noMargin}
        $inputGroupItem={inputGroupItem}
      >
        {Icon && <SIconWrapper>{Icon}</SIconWrapper>}
        {CustomInput ? (
          <SCustomInputWrapper
            $iconPadding={iconPadding && !Icon}
            $isInvalid={isInvalid}
            $formMode={formMode}
          >
            {CustomInput}
          </SCustomInputWrapper>
        ) : (
          <div style={{ position: "relative" }}>
            {/* Use textfield if you users are not passed */}
            {!props.users || props.users.length === 0 ? (
              <Styled.STextField
                $iconPadding={iconPadding && !Icon}
                {...customInputFields}
                $isInvalid={isInvalid}
                $inputGroupItem={inputGroupItem}
                $formMode={formMode}
                placeholder={inputPlaceholder}
                rows={props.rows}
                cols={props.cols}
              />
            ) : (
              <Styled.MentionsInputView
                $iconPadding={iconPadding && !Icon}
                onChange={handleMentionsChange}
                inputRef={mentionsInputRef}
                style={defaultStyle}
                value={mentionsValue}
                $isInvalid={isInvalid}
                $inputGroupItem={inputGroupItem}
                $formMode={formMode}
                placeholder={inputPlaceholder}
                rows={props.rows}
                cols={props.cols}
              >
                <Mention
                  style={defaultMentionStyle}
                  displayTransform={(id, display) => `@${display}`}
                  markup="<<__display__>>"
                  trigger="@"
                  data={
                    props.users?.map(u => ({
                      id: Number(u.pkid),
                      display: u.full_name as string,
                    })) || []
                  }
                />
              </Styled.MentionsInputView>
            )}
            {/* custom placeholder must come after the input field, the transition works when it is a direct sibling of the input field */}
            {customPlaceholder && (
              <SPlaceholder
                $iconPadding={iconPadding || !!Icon}
                $formMode={formMode}
                className="placeholder"
                // @ts-ignore
                $isActive={true}
                $placeTop={true}
                $isInvalid={isInvalid}
                // @ts-ignore
                onClick={() =>
                  // @ts-ignore
                  document.querySelector(`input[name=${props.name}`).focus()
                }
              >
                {customPlaceholder}
              </SPlaceholder>
            )}
          </div>
        )}
        {(errorText || $helpText) && (
          <SHelpText
            className="help-text"
            $iconPadding={iconPadding && !Icon}
            $isError={!!errorText}
          >
            {errorText || $helpText}
          </SHelpText>
        )}
        <SInputModeIconWrapper className="edit-icon-container">
          {formMode === FormModes.EDIT && <MdEdit className="edit" />}
          {formMode === FormModes.EDIT && (
            <MdCancel className="cancel" onClick={handleCleanField} />
          )}
        </SInputModeIconWrapper>
      </SSection>
    </>
  );
};

export default TextAreaField;
