import styled from "styled-components/macro";
import * as StyledInputField from "components/FormInputs/InputField/styles";
import { MentionsInput, Mention } from "react-mentions";

export const SSection = styled(StyledInputField.SectionView)``;

interface ISTextFieldProps extends StyledInputField.ICustomInputWrapperViewProps {
  rows?: number;
}

export const STextField = styled.textarea<ISTextFieldProps>`
  ${props => StyledInputField.baseInputStyle(props)}
  height: ${props => props.rows}px;
  min-height: ${props => props.rows}px;
  max-width: 300px;
  word-break: break-word;
`;

export const SHelpText = styled(StyledInputField.HelpTextView)``;

export const SPlaceholder = styled(StyledInputField.PlaceholderView)``;

export const SIconWrapper = styled(StyledInputField.IconWrapperView)``;

export const SCustomInputWrapper = styled(StyledInputField.CustomInputWrapperView)``;

export const SInputModeIconWrapper = styled(StyledInputField.InputModeIconWrapperView)``;

export const MentionsInputView = styled(MentionsInput)<ISTextFieldProps>`
  > div {
    min-height: ${props => props.rows}px;
  }

  textarea {
    ${props => StyledInputField.baseInputStyle(props)}
    height: ${props => props.rows}px;
    min-height: ${props => props.rows}px;
    word-break: break-word;
    word-wrap: break-word;
    width: 100%;
    resize: none;
  }
  span {
    font-size: unset;
  }
  [class$="suggestions"] {
    background-color: var(--white) !important;
    z-index: 1300 !important;
    border: 1px solid rgba(25, 47, 84, 0.4) !important;
  }
`;
export const MentionsView = styled(Mention)``;
