import moment from "moment";
import "moment-timezone";
import React, { useContext, useEffect, useRef, useState } from "react";
import Select from "react-select";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Slide, toast, ToastContainer } from "react-toastify";
import ss from "./styles.module.scss";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { FaCopy } from "react-icons/fa";
import useAxios from "api/api_base";
import { validatePhoneForE164 } from "containers/BaseMessageThreadDetail/utils/validators";
import { HTTPMethods } from "hooks/useMakeRequest";

import { OutgoingDeliveryMethod } from "types/baseMessageThread";
import { OutboundPhoneCallDropdown } from "../../../../components/OutboundPhoneCallDropdown";
import { useComposeModal } from "../../../ComposerModal";
import { InvalidWhatsAppNumberLabel } from "./styles";

moment.tz.setDefault("Europe/London");

const convert_plain_text_to_html_with_p_tags = plain_text => {
  return "<p>" + plain_text.replace(/(?:\r\n|\r|\n)/g, "<br>") + "</p>";
};

const urlParams = new URLSearchParams(window.location.search);
let initial_msg = urlParams.get("initial_msg");
const BMTReplyForm = ({
  baseMessageThread: bmt,
  bmtReplyFormRecipientList,
  bmtReplyFormSubject,
  deliveryMethods,
  handleRefreshThread,
  lastInboundMessage,
  onCompleted,
  replyTemplates,
}) => {
  const { register, handleSubmit, control, watch, errors, getValues, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      compose_message: initial_msg,
    },
  });

  const { getLatestWhatsAppMessageForNumber, activityObject } = useComposeModal();

  const [{}, postBmtReply] = useAxios(
    {
      url: `/base-message-thread/${bmt.id}/send-reply/`,
      method: "POST",
      headers: { "content-type": "multipart/form-data" },
    },
    { manual: true },
  );

  const onSubmit = async data => {
    console.log("Data before submitting = " + JSON.stringify(data));

    const {
      compose_message,
      recipient_list,
      compose_subject,
      mark_as_handled_while_sending,
      attachment_image,
      force_use_gmail,
    } = data;

    const recipientListFinal = recipient_list && recipient_list.filter(el => el !== false);

    // // Check message character count and alert if its greater than 160(1 message).
    // todo: add back if SMS character check is needed
    // if (msgCharCount > 160) {
    //   const message_count = Math.ceil(msgCharCount / 160);
    //   if (deliveryMethods.includes("sms") && message_count > 1) {
    //     let confirmMessageLength = `You should be very consise in sms.\nThis message counts as ${message_count} sms.Note that each sms is 160 character \nif you send long sms it will cost more.In worst case \nif you want to send the whole message as it is you can press OK else press CANCEL`;
    //     if (!window.confirm(confirmMessageLength)) return;
    //   }
    // }

    let confirmMessage = `Do you want to send this message below to ${JSON.stringify(
      recipientListFinal,
    )} via ${deliveryMethods} \n\nMessage \n\n${compose_message.slice(0, 10)} ...`;

    const formData = new FormData();
    deliveryMethods.forEach(dm => formData.append("delivery_method", dm));
    formData.append("message", compose_message);
    formData.append("subject", compose_subject); // used in backend only for delivery_method == 'email

    recipientListFinal && recipientListFinal.forEach(rec => formData.append("recipient_list", rec));
    attachmentUrls && attachmentUrls.forEach(rec => formData.append("attachment_urls", rec));

    // formData.append('is_html',  !!(fullTemplateContent && fullTemplateContent.is_content_html))
    formData.append("is_html", deliveryMethods.includes("email"));

    formData.append("mark_thread_handled", mark_as_handled_while_sending === "true");
    formData.append("force_use_gmail", force_use_gmail === "true");
    formData.append("in_reply_to", activityObject.messageId);

    // attachment_image: This is a FileList which is an Array like object, that has the length property, and indexed values,
    // but it lacks the Array methods, such as map.
    // With ES6 you can use spread or Array#from to convert it to a real array.
    attachment_image &&
      attachment_image.length > 0 &&
      Array.from(attachment_image).forEach(file => formData.append("attachment_files", file));

    const result = await postBmtReply({ data: formData });

    if (result.status === 201) {
      const { refresh, refresh_delay_in_seconds } = result.data;
      if (refresh) {
        handleRefreshThread(refresh_delay_in_seconds);
      }

      localStorage.removeItem(`draftSubject-${bmt.id}-${deliveryMethods.join("-")}`);
      localStorage.removeItem(`draftMessage-${bmt.id}-${deliveryMethods.join("-")}`);
      setValue("compose_subject", "", { shouldDirty: true });
      setValue("compose_message", "", { shouldDirty: true });
      setValue("attachment_image", "", { shouldDirty: true });
      setMsg("");
      onCompleted && onCompleted();
    }
  };

  // todo: add back to enable feature of marking BMT as handled
  // const [
  //   {
  //     data: markHandledData,
  //     loading: markHandledLoading,
  //     error: markHandledError,
  //   },
  //   executeMarkHandled,
  // ] = useAxios(
  //   {
  //     url: `/api/v2/core/mark_bmt_as_handled/${bmt.id}/`,
  //     method: "POST",
  //   },
  //   { manual: true },
  // );

  // const onClickMarkAsHandledButton = async () => {
  //   const result = await executeMarkHandled({});
  //   console.log("Got post data after marking handled = " + result.data);
  //   console.log("postResultData = " + markHandledData);
  //   if (result.status === 200) {
  //     handleRefreshThread();
  //   }
  // };

  // States
  const [insertableTemplateId, setInsertableTemplateId] = useState();
  const [insertableTemplateContent, set_insertable_template_content] = useState();

  const [fullTemplateId, setFullTemplateId] = useState();
  const [fullTemplateContent, setFullTemplateContent] = useState();

  const [attachmentUrls, setAttachmentUrls] = useState([]);

  const [msgCharCount, setMsgCharCount] = useState(0);
  const [msg, setMsg] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem(`draftMessage-${bmt.id}-${deliveryMethods.join("-")}`);
    return saved || "";
  });

  const saveDraft = (msg, key = "draftMessage") => {
    localStorage.setItem(`${key}-${bmt.id}-${deliveryMethods.join("-")}`, msg || "");
  };
  // references for adding text at cursort
  const composeMessageTextComponentRef = useRef(); // how to use this? https://react-hook-form.com/faqs#Howtosharerefusage
  let ckeditor_ref; // https://stackoverflow.com/questions/57265263/ckeditor5-append-content-at-curosr-position-of-editor-with-react

  const [
    { data: renderedReplyTemplateData, error: renderedReplyTemplateErrors },
    getRenderedReplyTemplate,
  ] = useAxios({ method: HTTPMethods.GET }, { manual: true });

  const getAndSetTemplateRenderedContent = async (templateId, setFunc) => {
    const res = await getRenderedReplyTemplate({
      url: `/reply-template/${templateId}/get-rendered-template/${bmt.id}/`,
    });
    res.status === 200 && setFunc(res.data);
  };

  // uwc-debug
  useEffect(() => {
    // insert template id changed, do side effects
    if (insertableTemplateId) {
      console.log(
        `Insert Template changed, Getting rendered template content for template=${insertableTemplateId}, name=${
          insertable_templates.filter(t => t.id === insertableTemplateId)[0].name
        }`,
      );
      getAndSetTemplateRenderedContent(insertableTemplateId, set_insertable_template_content);
    }
  }, [insertableTemplateId]);

  // uwc-debug
  useEffect(() => {
    // insert template id changed, do side effects to fetch from server
    if (fullTemplateId) {
      console.log(
        `Insert Template changed, Getting rendered template content for template=${fullTemplateId}, name=${
          full_templates.filter(t => t.id === fullTemplateId)[0].name
        }`,
      );
      getAndSetTemplateRenderedContent(fullTemplateId, setFullTemplateContent);
    }
  }, [fullTemplateId]);

  // uwc-debug
  useEffect(() => {
    // full template content changed, do side effect i.e. change Compose Textarea
    if (fullTemplateContent) {
      console.log("Full template content changed");
      setValue("compose_subject", fullTemplateContent.template_subject, {
        shouldDirty: true,
      });
      if (deliveryMethods.includes("email")) {
        const html_content_for_ckeditor = fullTemplateContent.is_content_html
          ? fullTemplateContent.template_content
          : convert_plain_text_to_html_with_p_tags(fullTemplateContent.template_content);
        setValue("compose_message", html_content_for_ckeditor, {
          shouldDirty: true,
        });
        // localStorage.setItem(`draftMessage-${bmt.id}-${deliveryMethods.join("-")}`,html_content_for_ckeditor);
        saveDraft(html_content_for_ckeditor, "draftMessage");
        setMsg(msg);
      } else {
        setValue("compose_message", fullTemplateContent.template_content, {
          shouldDirty: true,
        });
        setMsgCharCount(fullTemplateContent.template_content.length);
        // localStorage.setItem(`draftMessage-${bmt.id}-${deliveryMethods.join("-")}`,fullTemplateContent.template_content);
        saveDraft(fullTemplateContent.template_content);
        setMsg(msg);
      }

      setAttachmentUrls(prev_attachment_urls => [
        ...prev_attachment_urls,
        ...fullTemplateContent.attachment_urls,
      ]);
    }
  }, [fullTemplateContent]);

  useEffect(() => {
    // insertable template content changed, do side effect i.e. change Compose Textarea
    if (insertableTemplateContent && composeMessageTextComponentRef.current) {
      console.log("Insertable template content changed");
      const x = composeMessageTextComponentRef.current;
      const textToInsert = insertableTemplateContent.template_content;

      if (deliveryMethods.includes("email")) {
        // do nothing here, populates clip button
      } else {
        const startPos = x.selectionStart;
        const endPos = x.selectionEnd;
        const scrollTop = x.scrollTop;

        const new_compose_message_value =
          x.value.substring(0, startPos) + textToInsert + x.value.substring(endPos, x.value.length);

        saveDraft(new_compose_message_value);
        setMsg(msg);

        setValue("compose_message", new_compose_message_value, {
          shouldDirty: true,
        });
        setMsgCharCount(new_compose_message_value.length);

        x.focus();
        x.selectionStart = startPos;
        x.selectionEnd = startPos + textToInsert.length;
        x.scrollTop = scrollTop;
      }
    }
  }, [insertableTemplateContent]);

  const watchedComposeMsg = watch("compose_message");
  const watchSubjectLine = watch("compose_subject");

  useEffect(() => {
    if (deliveryMethods.includes("email")) {
      register("compose_message", { required: "Required" });
    }
  }, [register]);

  useEffect(() => {
    const savedSubject =
      localStorage.getItem(`draftSubject-${bmt.id}-${deliveryMethods.join("-")}`) || "";

    if (
      // Saved Subject Line was empty but there is a new value
      (!savedSubject && watchSubjectLine) ||
      // Saved Subject Line is not empty but there is a new value
      (savedSubject && watchSubjectLine) ||
      // Saved Subject Line has not up to 2 characters but there is a new value, clear it
      (savedSubject.length < 2 && !watchSubjectLine)
    ) {
      saveDraft(watchSubjectLine, "draftSubject");
    }
  }, [watchSubjectLine]);

  useEffect(() => {
    const savedSubject = localStorage.getItem(
      `draftSubject-${bmt.id}-${deliveryMethods.join("-")}`,
    );

    const populated_subject_from_last_email =
      deliveryMethods.includes(OutgoingDeliveryMethod.EMAIL) && lastInboundMessage
        ? `Re: ${lastInboundMessage.subject.replace("Re: ", "").replace("RE: ", "")}`
        : savedSubject;

    const emailSubject =
      deliveryMethods.includes(OutgoingDeliveryMethod.EMAIL) &&
      activityObject.messageId &&
      activityObject.subject
        ? `Re: ${activityObject.subject}`
        : populated_subject_from_last_email;

    setValue("compose_subject", emailSubject || "", { shouldDirty: true });

    const savedMessage = localStorage.getItem(
      `draftMessage-${bmt.id}-${deliveryMethods.join("-")}`,
    );
    const initialValue = savedMessage;
    setValue("compose_message", initialValue || "", { shouldDirty: true });
  }, [msg]);

  const insertable_templates = replyTemplates && replyTemplates.filter(t => t.insertable === true);
  const full_templates = replyTemplates && replyTemplates.filter(t => t.insertable === false);

  const insertableTemplateSelectOptions = insertable_templates
    ? insertable_templates.map(t => ({
        value: t.id,
        label: t.name,
      }))
    : [];

  const full_template_select_options = full_templates
    ? full_templates.map(t => ({
        value: t.id,
        label: `${t.name}${
          t.attachment_file_keys && t.attachment_file_keys.length > 0 ? "📎" : ""
        }${t.is_content_html ? "✍️" : ""}`,
      }))
    : [];

  const all_non_platform_dms = bmt && deliveryMethods.filter(dm => dm !== "platform");

  const recipient_list =
    all_non_platform_dms &&
    all_non_platform_dms
      .map(dm => {
        const dm_dict = bmt.delivery_method_options[dm];
        return dm_dict && "recipient_list" in dm_dict ? dm_dict.recipient_list : [];
      })
      .flat();

  const form_entered_recipient_list = watch("recipient_list", []);

  useEffect(() => {
    bmtReplyFormRecipientList &&
      bmtReplyFormRecipientList.map(recipient => {
        let index = recipient_list.findIndex(r => r.email == recipient);
        if (index > -1) {
          setValue(`recipient_list[${index}]`, true, { shouldDirty: true });
        }
      });
  }, [bmtReplyFormRecipientList]);

  useEffect(() => {
    bmtReplyFormSubject && setValue("compose_subject", bmtReplyFormSubject, { shouldDirty: true });
  }, [bmtReplyFormSubject]);

  // const  = dm_obj && dm_obj.recipient_list

  // getvalues(nest) returns an obj like
  //  compose_message: "a"
  //  compose_subject: ""
  //  recipient_list: Array(1) ==> if this has anything other than 'false' then some recipient is selected, then dont disable
  //      0: false
  //      length: 1

  const submit_button_should_be_disabled =
    recipient_list &&
    recipient_list.length > 0 &&
    form_entered_recipient_list &&
    form_entered_recipient_list.filter(el => el !== false).length === 0;

  const smsWhatsAppDeliveryMethods = [
    OutgoingDeliveryMethod.SMS,
    OutgoingDeliveryMethod.WHATSAPP_TEXT,
  ].some(dm => {
    return deliveryMethods.includes(dm);
  });

  const canPreSelectSingleRecipient = ["email", "sms", "whatsapp"].some(deliveryMethod =>
    deliveryMethods.includes(deliveryMethod),
  );

  const canHideRecipientCheckbox = deliveryMethods.includes("phone");

  const InvalidWhatsAppNumberLabelRenderer = ({ latestMessage }) => {
    return (
      <InvalidWhatsAppNumberLabel>
        Whatsapp identified as invalid
        {!!latestMessage.timestamp ? (
          <> on {moment.tz(latestMessage.timestamp, "UTC").local().format("DD MMM yyyy")}</>
        ) : (
          "."
        )}
      </InvalidWhatsAppNumberLabel>
    );
  };

  return (
    <div className={ss.container}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div>Send via {deliveryMethods.join("+")} to</div>

        <div className={ss.recipient_container}>
          {recipient_list
            ? recipient_list.map(({ name, value }, recipientIndex) => {
                const latestMessage = getLatestWhatsAppMessageForNumber(value);

                // Only default check the first if delivery method is preselectable or // Only default check the first if delivery method is preselectable.
                const defaultChecked = activityObject.messageId
                  ? value === activityObject.from
                  : (canPreSelectSingleRecipient && recipientIndex === 0) ||
                    !canPreSelectSingleRecipient;

                return (
                  <div key={recipientIndex}>
                    <input
                      type="checkbox"
                      hidden={canHideRecipientCheckbox}
                      defaultChecked={defaultChecked}
                      value={value}
                      {...register(`recipient_list[${recipientIndex}]`)}
                    />
                    &nbsp;{`${value} - ${name}`}
                    &nbsp;
                    {deliveryMethods.includes("sms") && !validatePhoneForE164(value) ? (
                      <div className="text-danger">
                        Phone number needs formatting, edit it in client info edit forms before
                        sending
                      </div>
                    ) : null}
                    {deliveryMethods.includes("phone") && (
                      <OutboundPhoneCallDropdown
                        onConfirmed={() => onCompleted && onCompleted()}
                        phoneNumber={value}
                        bmtId={bmt.id}
                      />
                    )}
                    <br />
                    {deliveryMethods.includes("whatsapp") &&
                    latestMessage &&
                    latestMessage.status === "Invalid" ? (
                      <InvalidWhatsAppNumberLabelRenderer latestMessage={latestMessage} />
                    ) : null}
                    <br />
                  </div>
                );
              })
            : null}
        </div>
        {/* todo: add back if there are multiple ways of email sending in backend and we need to force Gmail way */}
        {deliveryMethods.includes("email") && (
          <div style={{ display: "none" }}>
            <input type="checkbox" {...register("force_use_gmail")} value={true} />
            &nbsp; Force sending with Gmail (only use if normal email send fails, dont need to tick
            this normally)
          </div>
        )}
        <br />
        {!deliveryMethods.includes("phone") && (
          <div>
            <Row>
              <Col xs={12} sm={6}>
                {/* <label>Copy Template for Pasting</label> */}
                <Select
                  options={insertableTemplateSelectOptions}
                  {...register("insertable_templates")}
                  placeholder="Template to Paste"
                  value={
                    insertableTemplateSelectOptions.length > 0
                      ? insertableTemplateSelectOptions.find(t => t.value === insertableTemplateId)
                      : null
                  }
                  onChange={e => setInsertableTemplateId(e.value)}
                />
                {insertableTemplateContent ? (
                  <Button className={ss.copy_button}>
                    <CopyToClipboard
                      text={insertableTemplateContent.template_content}
                      onCopy={() => toast.info("Template copied")}
                      className={ss.copyLink}
                    >
                      <FaCopy />
                    </CopyToClipboard>
                  </Button>
                ) : null}

                <br />
              </Col>
              <Col xs={12} sm={6}>
                {/* <label>Insert Template Full</label> */}
                <Select
                  options={full_template_select_options}
                  {...register("full_templates")}
                  placeholder="Full Template"
                  value={
                    full_template_select_options.length > 0
                      ? full_template_select_options.find(t => t.value === fullTemplateId)
                      : null
                  }
                  onChange={e => setFullTemplateId(e.value)}
                />
                <br />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {!smsWhatsAppDeliveryMethods && (
                  <>
                    <input
                      className="form-control"
                      {...register(
                        "compose_subject",
                        deliveryMethods.includes("email") ? { required: "Required" } : {},
                      )}
                      placeholder="Subject"
                      disabled={!deliveryMethods.includes("email")}
                      style={{ marginBottom: "1rem" }}
                    />
                  </>
                )}

                {/* <ErrorMessage
              errors={errors}
              name="compose_subject"
              className={ss.formError}
              as="div"
            /> */}
                <Form.Label>Your Message</Form.Label>
                {deliveryMethods.includes("email") ? (
                  <CKEditor
                    editor={ClassicEditor}
                    data={watchedComposeMsg}
                    config={{
                      height: "300px",
                      allowedContent: true,
                    }}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                      // console.log('Editor is ready to use!', editor);
                      ckeditor_ref = editor;
                    }}
                    onChange={(event, editor) => {
                      const editorData = editor.getData();
                      setMsg(editorData);
                      saveDraft(editorData);
                    }}
                  />
                ) : (
                  <textarea
                    className="form-control"
                    {...register("compose_message")}
                    value={msg}
                    onChange={e => {
                      setMsgCharCount(e.target.value.length);
                      saveDraft(e.target.value);
                      setMsg(e.target.value);
                    }}
                    rows="10"
                  />
                )}
                {!deliveryMethods.includes(OutgoingDeliveryMethod.SMS) && (
                  <input
                    className="my-3"
                    type="file"
                    multiple={deliveryMethods.includes("email")}
                    {...register("attachment_image")}
                  />
                )}

                {/* todo: add back input and text if we need to mark a message thread as handled without replying */}
                {/* <input
              type="checkbox"
              {...register("mark_as_handled_while_sending")}
              defaultChecked={true}
              value={true}
            /> */}
                {/* &nbsp;Mark as Handled while Sending */}
                {/* <br /> */}
                {/* <ErrorMessage
              errors={errors}
              name="compose_message"
              className={ss.formError}
              as="div"
            /> */}
              </Col>
            </Row>

            {deliveryMethods.includes("whatsapp") && (
              <div>
                <strong>
                  <i>
                    To send a file through Whatsapp, only the following formats are currently
                    supported :<div>Images: JPG, JPEG, PNG</div>
                    <div>Audio: MP3, AMR</div>
                    <div>Documents: PDF</div>
                    <div>Video: MP4</div>
                  </i>
                </strong>
              </div>
            )}
            {deliveryMethods.includes("sms") || (deliveryMethods.includes("whatsapp") && <br />)}
            <button
              type="submit"
              className={`btn btn-sm ${
                submit_button_should_be_disabled ? "btn-secondary" : "btn-primary"
              }`}
              disabled={submit_button_should_be_disabled}
            >
              Send (via {deliveryMethods.join("+")})
            </button>
            {process.env.NODE_ENV === "development" ? <DevTool control={control} /> : null}
          </div>
        )}
      </Form>
      <br />
      {/* todo: add back for generic error displays */}
      {/* {bmtReplyError && <GenericAxiosError axiosError={bmtReplyError} />} */}
      {/* {bmtReplyData && <GenericAxiosSuccess axiosSucessData={bmtReplyData} />} */}
      {/* todo: addback to enable mark as handled feature */}
      {/* <button
        id="mark_handled_button"
        className={`btn  ${bmt.is_handled ? "btn-secondary" : "btn-primary"}`}
        disabled={bmt.is_handled}
        onClick={onClickMarkAsHandledButton}
      >
        {!bmt.is_handled
          ? "Mark as handled without reply"
          : `Already marked handled by ${bmt.handling_user}`}
      </button> */}
      <br />
      {/* Todo: add back accordion to show attachments in */}
      {/* <Accordion activeKey={attachmentUrls.length > 0 ? "0" : null}>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              Attachments&nbsp;&nbsp;
              {attachmentUrls.length > 0 ? (
                <span className="badge badge-primary">
                  {attachmentUrls.length}
                </span>
              ) : null}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {attachmentUrls.length > 0 ? (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={e => setAttachmentUrls([])}
                  className={ss.attachment_delete_button}
                >
                  Delete All
                </Button>
              ) : null}
              <br />
              <br />
              {attachmentUrls.map((img_url, i) => (
                <img
                  key={i}
                  src={img_url}
                  alt=""
                  className={ss.attachment_image}
                />
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion> */}
      <ToastContainer transition={Slide} hideProgressBar={true} />
    </div>
  );
};

export default BMTReplyForm;
