import { KeyboardArrowRight as MUIKeyboardArrowRight } from "@mui/icons-material";
import { Size } from "../types";
import styled from "styled-components";
import { SxProps } from "@mui/system";

type Props = {
  size?: Size;
  className?: string;
  sx?: SxProps;
};

const UnstyledKeyboardArrowRight: React.FC<Props> = ({ className, size = "medium", sx }) => {
  return <MUIKeyboardArrowRight className={className} fontSize={size} sx={sx} />;
};

export const KeyboardArrowRight = styled(UnstyledKeyboardArrowRight)``;
