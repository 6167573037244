import { lazy } from "react";
import { Navigate } from "react-router-dom";

const TESRViewing = lazy(() => import("../pages/tesr-viewing"));

export const publicRoutes = [
  {
    path: `/viewing-requests/:tesrId`,
    element: <TESRViewing />,
  },
  { path: "*", element: <Navigate to="/home" /> },
];
