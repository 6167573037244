import styled from "styled-components";
import { SVGRenderer } from "./svg-renderer";
import { IconProps } from "./types";

const UnstyledLetsAgreedMarker: React.FC<IconProps> = ({
  className,
  color = "rgb(223, 22, 96)",
  size,
}) => {
  return (
    <div className={className}>
      <SVGRenderer size={size}>
        <g
          transform={"translate(0.000000,98.000000) scale(0.100000,-0.100000)"}
          fill={color}
          stroke={"none"}
        >
          <path
            d={`M305 941 c-179 -44 -295 -206 -283 -397 8 -122 39 -171 228 -359
l161 -160 154 156 c130 131 160 167 187 225 30 64 33 77 33 169 0 87 -3 107
-26 156 -49 104 -127 170 -238 203 -56 16 -162 20 -216 7z m-115 -341 l0 -150
85 0 c78 0 85 -2 85 -20 0 -19 -7 -20 -105 -20 l-105 0 0 170 c0 163 1 170 20
170 19 0 20 -7 20 -150z m440 -1 c79 -199 77 -191 50 -187 -16 2 -28 16 -40
48 l-18 45 -74 3 -74 3 -18 -51 c-15 -41 -22 -50 -42 -50 -23 0 -24 1 -11 33
8 17 38 94 67 170 50 128 55 138 78 135 22 -3 32 -21 82 -149z`}
          />
          <path
            d={`M519 622 l-26 -67 49 -3 c28 -2 52 -1 54 1 5 4 -35 117 -45 129 -4 4
-18 -23 -32 -60z`}
          />
        </g>
      </SVGRenderer>
    </div>
  );
};

export const LetsAgreedMarker = styled(UnstyledLetsAgreedMarker)``;
