import { IActivityEmailUI, IActivityEmail, IActivityStatusUpdate } from "xl-types";
import { noteModel } from "./note-model";
import { userModel } from "./user-model";
import { sortStatusUpdates } from "./activity-transformer";

export const emailModel = (activity: IActivityEmail): IActivityEmailUI => {
  const jsonData =
    typeof activity.json_data === "string" ? JSON.parse(activity.json_data) : activity.json_data;
  const user = userModel(activity.sender);
  const comments = activity.internal_comments.map(noteModel);

  const statusUpdates: IActivityStatusUpdate[] = jsonData.sendgrid_data?.status_updates || [];

  return {
    anchorId: activity.anchor_id,
    attachments: activity.attachments,
    cc: activity.cc_emails,
    chaserMessageFriendlyName: activity.chaser_message_friendly_name,
    comments,
    content: {
      html: activity.html,
      text: activity.text,
    },
    from: activity.from_email,
    hasComment: !!comments.length,
    id: activity.id,
    isOutgoing: activity.is_outgoing,
    messageId: activity.message_id,
    status: {
      date: `${activity?.latest_sendgrid_email_status?.timestamp}Z`,
      name: activity?.latest_sendgrid_email_status?.status ?? "",
    },
    statusUpdates: sortStatusUpdates(statusUpdates),
    subject: activity.subject,
    time: activity.time_created,
    to: activity.to_emails,
    type: activity.type,
    user: user.fullName ?? user.email,
  };
};
