import styled from "styled-components";
import { Size } from "../types";
import { IconProps } from "./types";

const muiIconSizeMap: { [k in Size]: string } = {
  large: "2.1875rem",
  small: "1.25rem",
  medium: "1.5rem",
};

type Props = Pick<IconProps, "size"> & {
  children: React.ReactNode;
  className?: string;
};

const UnstyledSVGRenderer: React.FC<Props> = ({ className, children }) => {
  return (
    <svg
      className={className}
      viewBox={"0 0 82.000000 98.000000"}
      preserveAspectRatio={"xMidYMid meet"}
    >
      {children}
    </svg>
  );
};

export const SVGRenderer = styled(UnstyledSVGRenderer)`
  width: 1em;
  height: 1em;

  font-size: ${({ size = "medium" }) => muiIconSizeMap[size]};
`;
