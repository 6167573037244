import { Button as MUIButton, SxProps } from "@mui/material";
import styled from "styled-components";
import { FillColor } from "../types";
import React from "react";

export type Props = {
  children?: string | React.ReactNode;
  id?: string;
  className?: string;
  color?: Exclude<FillColor, "default">;
  disabled?: boolean;
  disableBottomShadow?: boolean;
  label?: string;
  ref?: React.Ref<HTMLButtonElement>;
  onClick?: (p: { target: EventTarget }) => void;
  styles?: SxProps;
  title?: string;
  variant?: "text" | "outlined" | "contained";
  onMouseOver?: (p: { target: EventTarget }) => void;
};

const UnStyledButton: React.FC<Props> = React.forwardRef((props, ref) => {
  const {
    children,
    className,
    id,
    color,
    label,
    disabled,
    disableBottomShadow = false,
    onClick,
    styles,
    title,
    onMouseOver,
    variant,
  } = props;
  if (!title && !children) {
    throw "Button is missing a [title] or [children] props";
  }
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    onClick && onClick({ target: e.target });
  };
  return (
    <MUIButton
      aria-label={label}
      className={className}
      id={id}
      ref={ref}
      disabled={disabled}
      disableElevation={disableBottomShadow}
      onClick={handleClick}
      onMouseOver={onMouseOver}
      sx={styles}
      color={color}
      variant={variant}
      data-testid={`Button-${id}`}
    >
      {title ?? children}
    </MUIButton>
  );
});

export const Button = styled(UnStyledButton)``;
