import { List as MUIList, SxProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  dense?: boolean;
  className?: string;
  disablePadding?: boolean;
  subheader?: React.ReactNode;
  styles?: SxProps;
  id?: string;
};

const UnstyledList: React.FC<Props> = ({
  children,
  dense,
  disablePadding,
  styles,
  className,
  id,
}) => {
  return (
    <MUIList
      id={id}
      data-testid={`List-${id}`}
      dense={dense}
      disablePadding={disablePadding}
      sx={styles}
      className={className}
    >
      {children}
    </MUIList>
  );
};

export const List = styled(UnstyledList)``;
