import { useSnackbar } from "hooks/useSnackbar";
import { ReactNode, useEffect } from "react";
import * as Styled from "./styles";
import { TSnackbarPosition } from "../Snackbar";

export interface ISnackbarItem {
  id: string | number;
  content: ReactNode;
  position?: TSnackbarPosition;
  duration?: number;
}

const SnackbarItem = ({ id, content, duration = 5000 }: ISnackbarItem) => {
  const { removeSnackbar } = useSnackbar();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeSnackbar(id);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, id, removeSnackbar]);

  return <Styled.SnackbarItemWrapper>{content}</Styled.SnackbarItemWrapper>;
};

export { SnackbarItem };
