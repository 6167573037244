import { SxProps } from "@mui/system";
import { Launch as MUILaunch } from "@mui/icons-material";
import { Size } from "../types";
import styled from "styled-components";

type Props = {
  size?: Size;
  className?: string;
  styles?: SxProps;
};

const UnstyledLaunch: React.FC<Props> = ({ className, size = "medium", styles }) => {
  return <MUILaunch className={className} fontSize={size} sx={styles} />;
};

export const Launch = styled(UnstyledLaunch)``;
