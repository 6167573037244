//@ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import Layout from "../../containers/Layout";
import XLDashboardRightContainer from "../../components/XLDashboardRightContainer";
import {
  CloseMUIDrawerButtonView,
  SContainer,
  CloseIconView,
  MobileNavItems,
  DesktopMenuNavItems,
} from "./styles";
import AppBar from "@mui/material/AppBar";
import styled from "styled-components/macro";
import { CallCentreContext } from "../../contexts/callCentreContext";
import * as Api from "api";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { SidebarMenu } from "containers/SidebarMenu";
import { menuItems } from "./menuItems";
import Dialog from "@mui/material/Dialog";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { UsersForm, UsersFormTypes } from "components/UsersForm";
import { FormModes } from "containers/GenericForm";
import { Box, Typography } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { PropertyCreateEditTabGroup } from "components/PropertyCreation";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuIcon from "@mui/icons-material/Menu";
import { ReactComponent as Logo } from "assets/images/logo-white.svg";
import { isMobile } from "react-device-detect";
import { CgClose } from "react-icons/cg";

export const SImage = styled.img`
  z-index: 100;
  width: 30px;
  height: 30px;
`;

interface IXLDashboard {
  isLoader?: boolean;
}

const XLDashboard = ({ isLoader = false }: IXLDashboard) => {
  const nav = useNavigate();
  const location = useLocation();
  const { data: currentUser } = useQuery({
    queryKey: ["currentUser"],
    queryFn: async () => {
      const { data } = await Api.user.getUser();
      return data;
    },
  });
  const { setAuthenticatedUser } = useContext(AuthContext);
  const [openNavDrawer, setOpenNavDrawer] = useState(false);
  const callContext = useContext(CallCentreContext);
  const { callCentreState, handleSetCallCentreState } = callContext || {};
  const [isPropertyCreationOpen, setIsPropertyCreationOpen] = useState(false);

  const onQuickNewUser = (userType: UsersFormTypes) => {
    if (handleSetCallCentreState) {
      handleSetCallCentreState({
        showNewUserForm: true,
        userType,
        phoneNumber: callCentreState?.contactAttributes?.customer_phone_number.value || "", // pick the phone number from the contact call information as reported by amazon connect which we use to prefill the form to help in data gathering then user creation
      });
    }
  };

  const onQuickCreateProperty = () => {
    setIsPropertyCreationOpen(true);
  };

  const quickActions = [
    {
      label: "New Tenant Enquiry >",
      action: () => {
        onQuickNewUser(UsersFormTypes.TENANT);
      },
      testId: !isLoader ? "create-new-tenant-button" : "",
    },
    {
      label: "New Landlord Lead >",
      action: () => {
        onQuickNewUser(UsersFormTypes.LANDLORD);
      },
      testId: !isLoader ? "create-new-landlord-button" : "",
    },
    {
      label: "Create New Property Listing >",
      action: onQuickCreateProperty,
      testId: "create-new-property-listing",
    },
  ];

  useEffect(() => {
    setAuthenticatedUser(currentUser);
  }, [currentUser]);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpenNavDrawer(!openNavDrawer);
    setMobileOpen(!mobileOpen);
  };

  const sideBarMenu = (
    <SidebarMenu menuItems={menuItems} quickActions={quickActions} isLoader={isLoader} />
  );

  return (
    <Layout>
      <SContainer fluid>
        {isMobile ? (
          <MobileNavItems data-testid="mobile-nav">
            <AppBar position="fixed" open={openNavDrawer}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  data-testid="mobile-sidebar-menu-trigger"
                  onClick={handleDrawerToggle}
                  sx={{ ...(openNavDrawer && { display: "none" }) }}
                >
                  <MenuIcon />
                </IconButton>

                <Box style={{ flexGrow: 1, display: "flex", justifyContent: "right" }}>
                  <Logo />
                </Box>
              </Toolbar>
            </AppBar>
            <SwipeableDrawer
              anchor={"left"}
              data-testid="swipeable-mobile-drawer"
              open={openNavDrawer}
              onClose={handleDrawerToggle}
              onOpen={handleDrawerToggle}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                data-testid="mobile-sidebar-menu-close"
                onClick={handleDrawerToggle}
                sx={{
                  position: "fixed",
                  right: "10px",
                  top: "10px",
                  color: "var(--main)",
                  backgroundColor: "rgb(193, 208, 234)",
                }}
              >
                <CgClose />
              </IconButton>
              {sideBarMenu}
            </SwipeableDrawer>
          </MobileNavItems>
        ) : (
          <DesktopMenuNavItems data-testid="desktop-nav">{sideBarMenu}</DesktopMenuNavItems>
        )}
        {!isLoader && <XLDashboardRightContainer />}
      </SContainer>
      <Dialog open={isPropertyCreationOpen} fullWidth maxWidth="md">
        <Box sx={{ p: 2, position: "relative" }}>
          <CloseIconView onClick={() => setIsPropertyCreationOpen(false)}>
            <FaTimes className="close-create-property-modal" />
          </CloseIconView>
          <Typography variant="h5">NEW Property For A Suitable Landlord</Typography>
          <PropertyCreateEditTabGroup shouldAllowToCreateForSuitableLandlord />
        </Box>
      </Dialog>
      <Dialog
        open={callCentreState.showNewUserForm}
        onClose={() => {
          /**Do nothing when the triggers for close are triggered byt the dialog component, we only want to close when the user intentionally closes the modal using the cancel icon below */
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        maxWidth="md"
      >
        <Box sx={{ position: "relative" }}>
          <CloseMUIDrawerButtonView
            onClick={() => {
              handleSetCallCentreState({
                showNewUserForm: false,
              });
            }}
          >
            <FaTimes />
          </CloseMUIDrawerButtonView>
          {callCentreState.showNewUserForm && (
            <div style={{ width: 550, maxWidth: "100%" }}>
              <UsersForm
                data-testid="user-create-form"
                key={callCentreState.userType}
                shouldShowLLSRAddressInput={true}
                formMode={FormModes.CREATE}
                defaultValues={{
                  userprofile: {
                    phone_number: callCentreState.phoneNumber,
                  },
                }}
                onSRLinkClicked={() => {
                  handleSetCallCentreState({
                    showNewUserForm: false,
                  });
                }}
                title={
                  callCentreState.userType === UsersFormTypes.LANDLORD
                    ? "Create Landlord"
                    : "Create Tenant"
                }
                userType={callCentreState.userType}
                onAfterSubmit={user => {
                  handleSetCallCentreState({
                    showNewUserForm: false,
                  });
                }}
              />
            </div>
          )}
        </Box>
      </Dialog>
    </Layout>
  );
};

export default XLDashboard;
