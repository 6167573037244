import React from "react";
import {
  AddressAutocomplete,
  IAddressFullDetails,
} from "components/XLFormComponents/AddressAutoComplete";
import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";

import { Box } from "@mui/system";
import { XLFormModes } from "containers/XLForm";
import { Grid, InputAdornment } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { BiLabel } from "react-icons/bi";

export const PropertyAddressFragment = (props: IXLFormFragmentProps) => {
  const { XLFormFragment, getPrefixedFieldName } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  return (
    <XLFormFragment>
      <Box
        sx={{
          bgcolor: "white",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <AddressAutocomplete
              label="Address"
              name="address"
              required
              defaultInputValue={
                props.useFormReturn?.getValues(getPrefixedFieldName("address"))?.full_address
              }
              onValueChanged={(address: IAddressFullDetails) => {
                if (props.formMode !== XLFormModes.EDIT) {
                  props?.useFormReturn?.setValue(getPrefixedFieldName("address"), address);
                  props.useFormReturn?.setValue(getPrefixedFieldName("name"), address.postcode);
                }
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldElement fullWidth required label="Property Unique Name" name="name" />
          </Grid>
        </Grid>
      </Box>
    </XLFormFragment>
  );
};
