import { ListItemText as MUIListListemText, SxProps } from "@mui/material";
import React from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  styles?: SxProps;
  disableTypography?: boolean;
  primary?: React.ReactNode;
  secondary?: React.ReactNode;
};

const UnstyledListItemText: React.FC<Props> = ({
  children,
  styles,
  disableTypography,
  secondary,
  primary,
}) => {
  return (
    <MUIListListemText
      sx={styles}
      disableTypography={disableTypography}
      primary={primary}
      secondary={secondary}
    >
      {children}
    </MUIListListemText>
  );
};

export const ListItemText = styled(UnstyledListItemText)``;
