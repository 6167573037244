import React, { createContext } from "react";

export interface ISidebarMenuContext {
  shouldOpenTrelloCardInSeparateTab: boolean;
  toggleShouldOpenTrelloCardInSeparateTab: () => void;
}

const SidebarMenuContext = createContext<ISidebarMenuContext | null>(null);
SidebarMenuContext.displayName = "SidebarMenuContext";
export { SidebarMenuContext };
