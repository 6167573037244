import { FormModes } from "containers/GenericForm";
import { HTTPMethods } from "hooks/useMakeRequest";
import { ReactNode } from "react";

import { LinkedObjectContentType } from "components/Notes";
import { ILSRAPIData } from "types/lsr";

export interface IBMTTaskFormComponentInputs extends ILSRAPIData {
  text: string;
}

export enum BMTTaskPurpose {
  PropertyViewed,
  LettingAgreementSigned,
}

export interface BMTTaskFormInputs {
  id: string;
  text: string;
  text_user_mentions: { id: string; display: string }[];
  trigger_time?: string;
  object_id?: string;
  content_object_class_name_str?: string;
}

export interface IBMTTaskFormComponentProps {
  defaultValues?: BMTTaskFormInputs;
  title: ReactNode;
  fieldName?: string;
  propertyId?: string;
  httpMethod?: HTTPMethods;
  fieldlaceholder?: string;
  butnText?: string;
  onSubmitted?: () => void;
  contentType: LinkedObjectContentType;
  objectId: string;
  formMode?: FormModes;
  type?: BMTTaskFormTypes;
}

export enum BMTTaskFormTypes {
  COMMENT = "COMMENT",
  TASK = "TASK",
}

export interface IInternalCommentApi {
  object_id: string;
  content_object_class_name_str: string | LinkedObjectContentType;
  text: string;
}
