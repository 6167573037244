import React, { InputHTMLAttributes, useState } from "react";
import {
  RegisterOptions,
  UseFormRegisterReturn,
  UseFormReturn,
  UseFormWatch,
} from "react-hook-form";
import { FormModes } from "containers/GenericForm";
import * as Styled from "./styles";
import { MdEdit } from "@react-icons/all-files/md/MdEdit";
import { MdCancel } from "@react-icons/all-files/md/MdCancel";
import * as chrono from "chrono-node";

export interface IBaseInputFieldProps {
  isInvalid?: boolean;
  name: string;
  registerOptions?: RegisterOptions;
  register?: (name: string, options: RegisterOptions) => UseFormRegisterReturn;
  watch?: UseFormWatch<any>;
  formMode?: FormModes;
  inputGroupItem?: boolean;
  hooksForm?: UseFormReturn<any>;
  helpText?: string;
  errorText?: string;
  errors?: Record<string, any>;
  required?: boolean;
  Icon?: React.ReactNode;
  placeTop?: boolean;
  iconPadding?: boolean;
  CustomInput?: React.ReactElement;
  testId?: string;
}

interface BaseInputFieldProps {
  placeholder?: string;
  width?: string;
  Icon?: React.ReactNode;
  iconPadding?: boolean;
  CustomInput?: React.ReactElement;
  formMode?: FormModes;
  inputPlaceholder?: string;
  noMargin?: boolean;
  showTimeParser?: boolean;
}

export type InputFieldProps = IBaseInputFieldProps &
  BaseInputFieldProps &
  InputHTMLAttributes<HTMLInputElement>;

const InputField = (props: InputFieldProps) => {
  const {
    helpText,
    errorText,
    register,
    watch,
    placeholder,
    required,
    isInvalid,
    iconPadding,
    Icon,
    CustomInput,
    formMode,
    inputGroupItem,
    inputPlaceholder,
    hooksForm,
    placeTop,
    ...inputProps
  } = props;

  const customPlaceholder = props.required ? `${props.placeholder}*` : props.placeholder;
  const $helpText = helpText || (props.required ? "*Required" : "");

  const [parsedTime, setParsedTime] = useState("");

  let customInputFields = {
    ...inputProps,
  };
  if (register) {
    const options = props.registerOptions || {};
    customInputFields = {
      ...inputProps,
      ...register(props.name, options),
    };
  }

  const handleCleanField = () => {
    const input: HTMLInputElement | null = document.querySelector(`input[name="${props.name}"]`);
    // Clear the input fields
    if (input) {
      input.value = "";
    }
    if (parsedTime) {
      setParsedTime("");
    }
  };

  const handleElementFocus = () => {
    const input: HTMLInputElement | null = document.querySelector(`input[name='${props.name}']`);
    if (input && props.type !== "text") {
      // @ts-ignore
      input.type = props.type;
    }
  };

  const handleElementBlur = () => {
    const input: HTMLInputElement | null = document.querySelector(`input[name='${props.name}']`);

    if (input?.type !== "password") {
      // @ts-ignore
      input.type = "text";
    }
  };

  // @ts-ignore
  const value = watch(props.name, props.defaultValue || props.defaultChecked);

  if (!value) {
    handleCleanField();
  }

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    const fieldValue = e.currentTarget.value;
    if (chrono.parseDate(fieldValue)) {
      setParsedTime(chrono.parseDate(fieldValue).toLocaleString());
    } else {
      setParsedTime("");
    }
    customInputFields.onChange && customInputFields?.onChange(e);
  };

  return (
    <Styled.SectionView
      $width={props.width}
      $iconPadding={iconPadding || !!Icon}
      $noMargin={props.noMargin}
      $inputGroupItem={inputGroupItem}
      data-testid={`${props.testId}-container`}
    >
      {Icon && <Styled.IconWrapperView>{Icon}</Styled.IconWrapperView>}
      {CustomInput ? (
        <Styled.CustomInputWrapperView
          $iconPadding={iconPadding && !Icon}
          $isInvalid={isInvalid}
          $formMode={formMode}
        >
          {CustomInput}
        </Styled.CustomInputWrapperView>
      ) : (
        <label style={{ display: "block", position: "relative" }}>
          <Styled.InputFieldView
            data-testid={props.testId}
            $iconPadding={iconPadding && !Icon}
            {...customInputFields}
            type={props.type || "text"}
            $isInvalid={isInvalid}
            $inputGroupItem={inputGroupItem}
            $formMode={formMode}
            placeholder={inputPlaceholder}
            onFocus={handleElementFocus}
            onBlur={handleElementBlur}
            onChange={handleInputChange}
          />
          {/* custom placeholder must come after the input field, the transition works when it is a direct sibling of the input field */}
          {customPlaceholder && (
            <Styled.PlaceholderView
              $iconPadding={iconPadding || !!Icon}
              $formMode={formMode}
              className="input-placeholder"
              // @ts-ignore
              $isActive={!!value || ["date"].includes(inputProps.type)}
              $placeTop={inputProps.type === "date" || placeTop}
              $isInvalid={isInvalid}
              // @ts-ignore
              onClick={() =>
                // @ts-ignore
                document.querySelector(`input[name=${props.name}`).focus()
              }
            >
              {customPlaceholder}
            </Styled.PlaceholderView>
          )}
        </label>
      )}
      {props.showTimeParser && (
        <Styled.ParsedTime>
          Would be saved as <span>{parsedTime}</span>
        </Styled.ParsedTime>
      )}
      {(errorText || $helpText) && (
        <Styled.HelpTextView
          className="help-text"
          $iconPadding={iconPadding && !Icon}
          $isError={!!errorText}
        >
          {errorText || $helpText}
        </Styled.HelpTextView>
      )}

      <Styled.InputModeIconWrapperView className="edit-icon-container">
        {formMode === FormModes.EDIT && <MdEdit className="edit" />}
        {formMode === FormModes.EDIT && <MdCancel className="cancel" onClick={handleCleanField} />}
      </Styled.InputModeIconWrapperView>
    </Styled.SectionView>
  );
};

export default InputField;
