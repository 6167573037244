import styled from "styled-components";
import React from "react";

type Props = {
  className?: string;
  value?: string;
};

const UnStyledMonthsHeaderRenderer: React.FC<Props> = ({ className, value }) => {
  return <span className={className}>{value}</span>;
};

export const MonthsHeaderRenderer = styled(UnStyledMonthsHeaderRenderer)`
  display: inline-block;
  padding: 0;

  font-size: 12px;
  writing-mode: vertical-rl;

  rotate: 180deg;
`;
