import { KeyboardArrowLeft as MUIKeyboardArrowLeft } from "@mui/icons-material";
import { Size } from "../types";
import styled from "styled-components";
import { SxProps } from "@mui/system";

type Props = {
  size?: Size;
  className?: string;
  sx?: SxProps;
};

const UnstyledKeyboardArrowLeft: React.FC<Props> = ({ className, size = "medium", sx }) => {
  return <MUIKeyboardArrowLeft className={className} fontSize={size} sx={sx} />;
};

export const KeyboardArrowLeft = styled(UnstyledKeyboardArrowLeft)``;
