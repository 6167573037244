import { IActivityCallUI, IActivityCall } from "xl-types";
import { noteModel } from "./note-model";
import { userModel } from "./user-model";

export const callModel = (activity: IActivityCall): IActivityCallUI => {
  const user = userModel(activity.sender);
  const comments = activity.internal_comments.map(noteModel);

  return {
    anchorId: activity.anchor_id,
    comments,
    content: activity.audio_url_link,
    hasComment: !!comments.length,
    id: activity.id,
    isOutgoing: activity.is_outgoing,
    time: activity.time_created,
    type: activity.type,
    user: user.fullName ?? user.email,
  };
};
