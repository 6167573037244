import { Box, Tab, Tabs } from "@mui/material";
import { XLFormModes } from "containers/XLForm";
import { HTTPMethods } from "hooks/useMakeRequest";
import { AppConstant } from "xlconstants";
import {
  PropertyLikeDetailFormPage,
  PropertyLikeDocumentsFormPage,
  PropertyLikePhotosFloorPlansVideosFormPage,
  BaseListingLikeSummaryAndDescriptionFormPage,
} from "../FormPages";
import { useGetBaseListingDetails } from "../queries";
import { TabPanel } from "./TabPanel";

import { FaSpinner } from "react-icons/fa";
import { PropertyFormTypes } from "types/property";
import { useState, SyntheticEvent } from "react";

export const BaseListingEditTabGroup = (props: {
  baseListingId: string;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccesful?: (data: any) => void;
}) => {
  // Fetches the baselisting using the baselisting id from the props
  const {
    data: fetchedBaseListingResponse,
    refetch: refetchBaseListing,
    isLoading: isLoadingBaseListing,
  } = useGetBaseListingDetails(props.baseListingId);

  // constructs something like: `http://localhost:8000/api/base-listing/7fbf0621-60ca-41ab-86d1-d85f41d07077/`
  const editURL = `${AppConstant.BASE_API_URL}/base-listing/${props.baseListingId}/`;

  const handleSubmitSuccessful = (data: any) => {
    refetchBaseListing();
    props.onSubmitSuccesful && props.onSubmitSuccesful(data);
  };

  const [currentTabIndexValue, setCurrentTabIndexValue] = useState(0);

  // Updates the current tab when the tab label is clicked
  const handleCurrentTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTabIndexValue(newValue);
  };

  const tabs = ["Base Listing Details", "Photos/Videos", "Documents", "Summary And Description"];

  return (
    <Box bgcolor="white">
      {isLoadingBaseListing && (
        <div>
          <FaSpinner /> Loading Base Listing ...
        </div>
      )}
      <Tabs
        value={currentTabIndexValue}
        onChange={handleCurrentTabChange}
        aria-label={tabs.join("-")}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map(title => (
          <Tab label={title} key={title} data-testid={title} />
        ))}
      </Tabs>
      <TabPanel value={currentTabIndexValue} index={0}>
        <PropertyLikeDetailFormPage
          title={""}
          property={fetchedBaseListingResponse?.data}
          editButtonText={"Update Base Listing"}
          formMode={XLFormModes.EDIT}
          editURL={editURL}
          editMethod={HTTPMethods.PATCH}
          onSubmitSuccessful={handleSubmitSuccessful}
          successEditMessage={"Base Listing Details Updated Successfully"}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={1}>
        <PropertyLikePhotosFloorPlansVideosFormPage
          property={fetchedBaseListingResponse?.data}
          submitButtonText={"Update Base Listing"}
          propertyType={PropertyFormTypes.BASELISTING}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={2}>
        <PropertyLikeDocumentsFormPage
          property={fetchedBaseListingResponse?.data}
          submitButtonText={"Update Base Listing"}
          propertyType={PropertyFormTypes.BASELISTING}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={3}>
        <BaseListingLikeSummaryAndDescriptionFormPage
          baseListingLike={fetchedBaseListingResponse?.data}
          editURL={editURL}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
    </Box>
  );
};
