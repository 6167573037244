import React, { useContext } from "react";
import Card from "../Card";
import moment from "moment";
import "moment-timezone";
import { CallCentreContext } from "../../contexts/callCentreContext";
import { BasicInfoCardChildTemplate } from "components/BasicInfoCard";
import { SCard, SFlex } from "components/BasicInfoCard/style";

moment.tz.setDefault("Europe/London");

const MatchedCustomerCard = () => {
  // @ts-ignore
  const { callCentreState } = useContext(CallCentreContext);
  const matchedCustomer = callCentreState.matchedCustomer;

  return (
    <div style={{ background: "white" }}>
      <Card>
        <SCard data-testid="srBasicInfoCard">
          <SFlex>
            <BasicInfoCardChildTemplate
              header={`${matchedCustomer?.first_name} ${matchedCustomer?.last_name}`}
              details={[matchedCustomer?.email, matchedCustomer?.userprofile.phone_number]}
            />
          </SFlex>
        </SCard>
      </Card>
    </div>
  );
};

export default MatchedCustomerCard;
