import styled from "styled-components/macro";
import { XLFormStateTypes } from "./index";
import { FormContainer } from "react-hook-form-mui";

export const StyledFormContainer = styled(FormContainer)`
  position: relative;
  background: var(--white) 0% 0% no-repeat padding-box;

  margin: var(--md);
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

type MessageSectionProps = {
  $type: XLFormStateTypes;
};

export const MessageSectionView = styled.section<MessageSectionProps>`
  color: ${props =>
    props.$type === XLFormStateTypes.ERROR
      ? "var(--colors-danger)"
      : props.$type === XLFormStateTypes.INFO
      ? "var(--colors-accent)"
      : "var(--colors-success)"};
`;

export const FormTitleSize = styled.h2``;

export const SubmittingOverlayView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
`;
