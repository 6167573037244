import { SFooter } from "components/PropertyCard/style";
import React, { useContext, useEffect, useState } from "react";
import Card from "../Card";
import { ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

import { Link } from "react-router-dom";
import { AiOutlineFieldTime } from "@react-icons/all-files/ai/AiOutlineFieldTime";
import moment from "moment";
import "moment-timezone";
import useAxios from "../../api/api_base";
import { CallCentreContext, ICustomerRequestItem } from "../../contexts/callCentreContext";
import { Button } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useLinkContactWithBaseMessageThread } from "../DialPad/queries";

moment.tz.setDefault("Europe/London");

const CustomerRequestList = () => {
  const [showAddContactButton, setShowAddContactButton] = useState(false);
  const { mutate } = useLinkContactWithBaseMessageThread();

  // @ts-ignore
  const { callCentreState, handleSetCallCentreState } = useContext(CallCentreContext);
  const { customerRequestItems } = callCentreState;

  useEffect(() => {
    if (customerRequestItems && customerRequestItems?.length > 1) {
      setShowAddContactButton(true);
    }
  }, [customerRequestItems]);

  return (
    <div style={{ background: "white" }}>
      <Card>
        <SFooter>
          <div>
            <strong>Enquiries:</strong>
            <ListGroup>
              {customerRequestItems?.map((item: ICustomerRequestItem) => {
                const url = `/home/${item.type_string}s/${item.pkid}`;
                return (
                  <ListGroup.Item key={item.id}>
                    <div className="d-flex justify-content-around">
                      <div>{item.friendly_name}</div>
                      <div>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Last modified</Tooltip>}
                        >
                          <div>
                            <AiOutlineFieldTime />
                            <span className="ms-1">
                              {moment(item.time_modified).format("DD MMM YYYY, h:mm:ss a")}
                            </span>
                          </div>
                        </OverlayTrigger>
                      </div>
                      <div>
                        <Link to={url}>
                          <Button
                            size="small"
                            className="p-1"
                            variant="contained"
                            onClick={() =>
                              handleSetCallCentreState({
                                showAmazonConnectContainer: true,
                              })
                            }
                          >
                            View
                          </Button>
                        </Link>
                      </div>
                      {showAddContactButton && (
                        <div>
                          <Button
                            size="small"
                            className="p-1"
                            variant="contained"
                            onClick={() => {
                              if (callCentreState?.contactId) {
                                mutate({
                                  contactId: callCentreState.contactId,
                                  bmtId: item.bmt_id,
                                });
                              }
                            }}
                          >
                            Add contact
                          </Button>
                        </div>
                      )}
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>
        </SFooter>
      </Card>
    </div>
  );
};

export default CustomerRequestList;
