// Temporarily adding this component here
import { axios } from "libconfigs/axios";
import { useCallback, useState } from "react";
import { FieldValues, Path, PathValue, UseFormSetValue } from "react-hook-form";
import { AutocompleteElement, AutocompleteElementProps } from "react-hook-form-mui";
import { AppConstant } from "xlconstants";
import { debounce } from "lodash";

interface IAddressSuggestion {
  address: string;
  url: string;
  id: string;
}

interface IAddressSearchResults {
  suggestions: IAddressSuggestion[];
}

export interface IAddressFullDetails {
  postcode: string;
  latitude: number;
  longitude: number;
  formatted_address: string[];
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
  residential: boolean;
}

interface IAddressAutocomplete<
  F extends FieldValues,
  T,
  M extends boolean | undefined,
  D extends boolean | undefined,
> extends Omit<AutocompleteElementProps<F, T, M, D>, "options"> {
  delay?: number;

  /** a function that the parent component can use to subscribe for update when address value has changed */
  onValueChanged?: (address: IAddressFullDetails) => void;

  /** Can be used to set the default value of the address input field. useful; in the edit state */
  defaultInputValue?: string;

  disabled?: boolean;
}

const AddressAutocomplete = <TFieldValues extends FieldValues>({
  name,
  onValueChanged,
  label,
  defaultInputValue,
  disabled,
  ...rest
}: IAddressAutocomplete<
  TFieldValues,
  IAddressSuggestion | string | any,
  boolean | undefined,
  boolean | undefined
>) => {
  const [options, setOptions] = useState<IAddressSuggestion[]>([]);
  const [inputValue, setInputValue] = useState(defaultInputValue || "");

  const getAddressSuggestions = (keyword: string) =>
    axios
      .get<IAddressSearchResults>(
        `https://api.getAddress.io/autocomplete/${keyword || ""}?api-key=${
          AppConstant.GET_ADDRESS_API_KEY
        }`,
      )
      .then(({ data }) => data);

  const getSelectedAddressDetails = (id: string) =>
    axios
      .get<IAddressFullDetails>(
        `https://api.getAddress.io/get/${id}?api-key=${AppConstant.GET_ADDRESS_API_KEY}`,
      )
      .then(({ data }) => data);

  const debouncedOnInputChange = useCallback(
    debounce(async (inputValue: string) => {
      if (inputValue.length) {
        const suggestions = await getAddressSuggestions(inputValue);
        setOptions(suggestions.suggestions);
      }
    }, 800),
    [],
  );

  return (
    <>
      <AutocompleteElement
        {...rest}
        label={label}
        name={name}
        options={options}
        autocompleteProps={{
          ...rest.autocompleteProps,
          disabled: disabled,
          filterOptions: option => option,
          isOptionEqualToValue: (option, value) => option.code === value,
          getOptionLabel: option =>
            option.address ||
            option?.formatted_address?.filter((e: string) => e !== "").join(", ") ||
            option.full_address,
          inputValue: inputValue,
          onInputChange: (e, newInputValue, reason) => {
            if (reason !== "reset") {
              setInputValue(newInputValue);
              debouncedOnInputChange(newInputValue);
            }
          },
          onChange: (event, value: IAddressSuggestion) => {
            if (value) {
              getSelectedAddressDetails(value.id).then(address => {
                const remappedPropertyAddress = {
                  ...address,
                  address_line_1: address.line_1,
                  address_line_2: address.line_2,
                  address_line_3: address.line_3,
                  address_line_4: address.line_4,
                  post_code: address.postcode,
                  city: address.town_or_city,
                  building_number: address.building_number || address.sub_building_number,
                  building_name: address.building_name || address.building_name,
                };
                setInputValue(`${value.address} - ${address.postcode}`);
                // when the address autocomplete is updated, we want the parent component to be aware if they suscribe
                if (typeof onValueChanged === "function") {
                  onValueChanged(remappedPropertyAddress);
                }
              });
            }
          },
        }}
      />
    </>
  );
};

export { AddressAutocomplete };
