import React, { useContext, useEffect, useState } from "react";
import { AppConstant } from "../../xlconstants";
import { IUser } from "xl-types";

import {
  CallCentreContext,
  IContactTraceRecord,
  SoftPhoneAgentStatus,
} from "../../contexts/callCentreContext";
import useAxios from "../../api/api_base";
import { ListGroup } from "react-bootstrap";
import { HTTPMethods } from "../../hooks/useMakeRequest";
import { sendToastNotification } from "utils/sendToastNotification";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { v4 as uuidv4 } from "uuid";
import MatchedCustomerCard from "components/MatchedCustomerCard";

import CustomerRequestList from "../CustomerRequestList";
import { axios } from "../../libconfigs";
import { useLinkContactWithBaseMessageThread } from "./queries";

const { AWS_CONNECT_BASE_INSTANCE_URL: awsConnectUrl } = AppConstant;

const DialPad = () => {
  const containerDivRef = React.useRef<HTMLDivElement>(null);
  const callCentreCTX = useContext(CallCentreContext);
  const { contactId } = callCentreCTX.callCentreState;
  const defaultOutboundQueueArn =
    "arn:aws:connect:eu-west-2:322292962697:instance/b73c7b4c-4cc9-4777-b38e-b507b8c6f065/queue/36910360-e547-4c49-a94c-3c1d49ba54b8";
  const bmtToLink = callCentreCTX?.callCentreState.callTriggeredFromBMT?.bmtIdToLink;
  const isOutbound = callCentreCTX?.callCentreState.callTriggeredFromBMT !== null;
  const phoneNumber =
    callCentreCTX?.callCentreState.contactAttributes?.customer_phone_number?.value;
  const propertyReferenceId =
    callCentreCTX?.callCentreState.contactAttributes?.property_reference_number?.value;
  const isXenia = propertyReferenceId ? propertyReferenceId.startsWith("1") : false;

  const [viewportHeight, setViewportHeight] = useState(515);
  const [currentTabId, _] = useState<string>(uuidv4());
  const [matchedCustomerIds, setMatchedCustomerIds] = useState<string>("");
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const { mutate } = useLinkContactWithBaseMessageThread();

  const { data: userRequestsResponse } = useQuery(
    ["user-service-requests", matchedCustomerIds, refreshKey],
    () =>
      axios.get(`/user-service-requests-lookup/?id=${matchedCustomerIds}`).then(res => res.data),
    { enabled: !!matchedCustomerIds },
  );

  const [
    { data: contactTraceRecordData, error: ContactTraceRecordErrors },
    postContactTraceRecord,
  ] = useAxios({ url: `/amazonconnect/contacttracerecord/`, method: "POST" }, { manual: true });

  const [{ data: user, error: userErrors }, getUser] = useAxios(
    { method: HTTPMethods.GET },
    { manual: true },
  );
  const queryClient = useQueryClient();

  /** Creates an outbound contact to the given endpoint.
   *  You can optionally provide a queueARN to associate the contact with a queue.
   *  Optional success and failure callbacks can be provided to determine if the operation was successful.
   */
  const createNewEndpoint = (
    phoneNumber: string,
    queueArn?: string,
    onSuccess?: connect.SuccessFailCallback<[]>,
    onFailure?: connect.SuccessFailCallback<[]>,
  ) => {
    const endpoint = connect.Endpoint.byPhoneNumber(phoneNumber);
    const agent = new connect.Agent();

    agent.connect(endpoint, {
      queueARN: queueArn,
      success: onSuccess,
      failure: onFailure,
    });
  };

  const getFocusedTabId = () => {
    return localStorage.getItem("_focusedTabId");
  };

  const getCallingTabId = () => {
    return localStorage.getItem("_callingTabId");
  };

  const setFocusedTabId = () => {
    console.log("Setting focusedTab to ", currentTabId);
    localStorage.setItem("_focusedTabId", currentTabId);
  };

  /**   Subscribe to contact events. */
  const subscribeToContactEvents = (contact: any) => {
    console.log("Subscribing to events for contact");
    // contact.onConnected(handleContactConnected);
    // contact.onEnded(handleContactEnded);
    // contact.onMissed(handleContactMissed);
    contact.onConnecting(handleContactConnecting);
  };

  /** Subscribe to agent events. */
  const subscribeToAgentEvents = (agent: any) => {
    console.log(`Subscribing to events for agent ${agent.getName()}`);

    agent.onRoutable(handleAgentRoutable);
    agent.onOffline(handleAgentOffline);
    agent.onNotRoutable(handleAgentNotRoutable);
    const iframe = containerDivRef.current?.querySelector<HTMLIFrameElement>(
      `iframe[src='${awsConnectUrl}']`,
    );
    iframe && (iframe.src = awsConnectUrl);
  };

  /** Handlers */

  /** Contact connecting handler */
  const handleContactConnecting = (contact: connect.Contact) => {
    const initialConnection = contact.getInitialConnection();
    const initiationMethod = initialConnection.getType();

    const initiationMap = {
      [connect.ConnectionType.INBOUND]: connect.ConnectionType.INBOUND,
      [connect.ConnectionType.OUTBOUND]: connect.ConnectionType.OUTBOUND,
      [connect.ConnectionType.AGENT]: connect.ConnectionType.AGENT,
      [connect.ConnectionType.MONITORING]: connect.ConnectionType.MONITORING,
    };

    callCentreCTX?.handleSetCallCentreState({
      contactAttributes: contact.getAttributes(),
      contactId: contact.contactId,
      matchedCustomer: null,
      initiationMethod: initiationMap[initiationMethod]?.toUpperCase(),
    });
  };

  const handleAgentRoutable = (agent: any) => {
    callCentreCTX?.handleSetCallCentreState({
      agent: {
        status: SoftPhoneAgentStatus.ROUTABLE,
      },
    });
  };
  const handleAgentOffline = (agent: any) => {
    callCentreCTX?.handleSetCallCentreState({
      agent: {
        status: SoftPhoneAgentStatus.OFFLINE,
      },
    });
  };

  const handleAgentNotRoutable = (agent: any) => {
    callCentreCTX?.handleSetCallCentreState({
      agent: {
        status: SoftPhoneAgentStatus.NOT_ROUTABLE,
      },
    });
  };
  /** Gets user instance by the phoneNumber */
  const getUserByPhoneNumber = async (phoneNumber: string) => {
    const phone = phoneNumber.replace("+", "%2B");
    return getUser({
      url: `/user/user/?phone_number=${phone}`,
    });
  };

  /**
   * Determine if the contact is outbound and in the calling tab.
   * @returns {boolean} - true if the contact is outbound and in the calling tab, false otherwise.
   */
  const isOutboundAndCallingTab = (): boolean => {
    return isOutbound && currentTabId === getCallingTabId();
  };

  /**
   * Determine if the contact is inbound and in the focused tab.
   * @returns {boolean} - true if the contact is inbound and in the focused tab, false otherwise.
   */
  const isInboundAndFocusedTab = (): boolean => {
    return !isOutbound && currentTabId === getFocusedTabId();
  };

  /**
   * Handle the response for outbound calls in the calling tab.
   * Links the contact with the BMT, updates the call center state, and invalidates the relevant query.
   */
  const handleOutboundAndCallingTab = async () => {
    if (contactId && bmtToLink) {
      mutate({ contactId, bmtId: bmtToLink });
    }
  };

  /**
   * Handle the response for inbound calls in the focused tab.
   * Sets the call center state based on the user response and user type.
   * @param {string} phoneNumber - The phone number of the user.
   */
  const handleInboundAndFocusedTab = async (phoneNumber: string, ctrData: IContactTraceRecord) => {
    if (!phoneNumber) return;

    const userResponse = await getUserByPhoneNumber(phoneNumber);
    if (userResponse.status !== 200) return;

    const userType =
      ctrData.contact_attributes_json.customer_type.value === "landlord"
        ? "user_landlord"
        : "user_tenant";

    const state = {
      showAmazonConnectContainer: true,
      showNewUserForm: false,
      contactTraceRecord: ctrData,
    };

    if (userResponse.data.length > 0) {
      callCentreCTX?.handleSetCallCentreState({
        matchedCustomer: userResponse.data[0],
        showMatchedUser: true,
        ...state,
      });
      // Create a comma-separated string of user IDs
      const userIds = userResponse.data.map((user: IUser) => user.id).join(",");

      if (matchedCustomerIds === userIds) {
        setRefreshKey(refreshKey + 1);
      } else {
        setMatchedCustomerIds(userIds);
      }
    } else {
      callCentreCTX?.handleSetCallCentreState({
        showMatchedUser: false,
        userType: userType,
        ...state,
      });
    }
  };

  /**
   * Main function to handle contact ID change.
   * Based on the type of call (outbound or inbound) and the tab state, this function
   * handles the contact trace record and updates the call center state accordingly.
   * @param {Record<string, any>} contactData - The contact data to be processed.
   */
  const handleContactIdChange = async (contactData: Record<string, any>) => {
    if (!isOutboundAndCallingTab() && !isInboundAndFocusedTab()) return;

    const ctrResponse = await postContactTraceRecord({ data: contactData });
    if (ctrResponse.status !== 201 && ctrResponse.status !== 200) return;

    if (isOutboundAndCallingTab()) {
      await handleOutboundAndCallingTab();
    } else if (isInboundAndFocusedTab() && phoneNumber) {
      await handleInboundAndFocusedTab(phoneNumber, ctrResponse.data);
    }
  };

  useEffect(() => {
    if (userRequestsResponse) {
      callCentreCTX?.handleSetCallCentreState({ customerRequestItems: userRequestsResponse });
      if (userRequestsResponse.length === 1) {
        contactId && mutate({ contactId: contactId, bmtId: userRequestsResponse[0].bmt_id });
      }
    }
  }, [userRequestsResponse]);

  /** Executes each time a new connection endpoint is triggered from the Base Message Thread */
  useEffect(() => {
    if (callCentreCTX?.callCentreState.callTriggeredFromBMT) {
      const { contactPhoneNumber } = callCentreCTX?.callCentreState.callTriggeredFromBMT || {};

      if (contactPhoneNumber) {
        sendToastNotification(`Connecting to ${contactPhoneNumber}`, "info");
        setTimeout(() => {
          createNewEndpoint(contactPhoneNumber, defaultOutboundQueueArn);
          localStorage.setItem("_callingTabId", currentTabId);
        }, 2500);
      }
    }
  }, [callCentreCTX?.callCentreState.callTriggeredFromBMT]);

  /**
   * Posts a new Contact Trace Record each time the contactId changes.
   * Displays a toast notification for incoming calls regarding Xenia.
   */
  useEffect(() => {
    const { contactId, contactAttributes, initiationMethod } = callCentreCTX?.callCentreState || {};

    if (contactId && !isXenia) {
      handleContactIdChange({
        contact_id: contactId,
        contact_attributes_json: contactAttributes,
        customer_phone_number: phoneNumber,
        initiation_method: initiationMethod,
      });
    }

    if (isXenia) {
      sendToastNotification("New incoming call regarding Xenia!", "info");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callCentreCTX?.callCentreState.contactId]);

  useEffect(() => {
    localStorage.setItem("_focusedTabId", currentTabId);

    const handleVisibilityChange = () => {
      if (!document.hidden && currentTabId !== getFocusedTabId()) {
        setFocusedTabId();
      }
    };

    const handleBeforeUnload = () => {
      localStorage.removeItem("_focusedTabId");
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("focus", setFocusedTabId);

    // @ts-ignore
    window.connect.getLog().setEchoLevel(window.connect.LogLevel.CRITICAL);

    if (containerDivRef?.current) {
      window.connect.core.initCCP(containerDivRef.current, {
        ccpUrl: awsConnectUrl,
        region: AppConstant.AWS_CONNECT_REGION,
        loginPopup: false,
        softphone: {
          allowFramedSoftphone: true,
        },
        // @ts-ignore
        ccpSynTimeout: 60000,
        ccpLoadTimeout: 60000,
      });
    }

    window.connect.agent(subscribeToAgentEvents);
    window.connect.contact(subscribeToContactEvents);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("focus", setFocusedTabId);
    };
  }, []);

  useEffect(() => {
    const getWindowHeight = () => {
      const { innerHeight } = window;
      setViewportHeight(innerHeight);
    };

    window.addEventListener("resize", getWindowHeight);

    getWindowHeight();

    return () => {
      window.removeEventListener("resize", getWindowHeight);
    };
  }, []);

  return (
    <div
      className="ccp"
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        background: "#f2f2f2",
      }}
    >
      {/** CCP */}

      <div
        style={{ height: viewportHeight, minHeight: "400px" }}
        className="containerDivClass"
        ref={containerDivRef}
      />
      {callCentreCTX?.callCentreState.contactAttributes &&
        !callCentreCTX?.callCentreState.showMatchedUser && (
          <div style={{ backgroundColor: "#fff", fontWeight: 600, textAlign: "center" }}>
            Create User using Sidebar Quick Actions
          </div>
        )}

      {/* Matched User Card */}
      {callCentreCTX?.callCentreState?.showMatchedUser &&
        callCentreCTX?.callCentreState?.matchedCustomer && (
          <div>
            <MatchedCustomerCard />
          </div>
        )}
      {callCentreCTX?.callCentreState?.customerRequestItems &&
        callCentreCTX?.callCentreState?.customerRequestItems.length > 0 && <CustomerRequestList />}
      {/** Current Contact Info  */}
      {callCentreCTX?.callCentreState.contactAttributes && (
        <ListGroup>
          <ListGroup.Item>
            <b>Queue name:</b> {callCentreCTX?.callCentreState.contactAttributes.queue?.value}
          </ListGroup.Item>

          <ListGroup.Item>
            <b>Phone number:</b>
            {callCentreCTX?.callCentreState.contactAttributes.customer_phone_number?.value}
          </ListGroup.Item>
          <ListGroup.Item>
            <b>Listing reference:</b>
            {callCentreCTX?.callCentreState.contactAttributes.property_reference_number?.value}
          </ListGroup.Item>
        </ListGroup>
      )}
    </div>
  );
};
export default DialPad;
