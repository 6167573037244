import { Tabs } from "react-bootstrap";
import styled from "styled-components";
import { CgClose } from "react-icons/cg";
import { TbArrowBackUp } from "react-icons/tb";

export const ButtonGroupSection = styled.section`
  padding: 30px 5px 5px;
  overflow: auto;
`;

export const ComposerModalSection = styled.section`
  position: fixed;
  bottom: 20px;
  right: 3%;
  padding: 3px 3px 5px 3px;
  height: auto;
  background-color: var(--main);
  z-index: 300;
  border: 2px solid var(--main);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 600px;

  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  display: flex;

  @media (max-width: 768px) {
    min-width: auto;
    width: calc(100vw - 20px);
  }
`;

export const TabsView = styled(Tabs)`
  .nav-item {
    cursor: not-allowed;

    .nav-link {
      height: 100%;
      color: var(--main);
      border-radius: 0;

      &.active {
        color: var(--main);
        background-color: transparent;
        border-bottom: 3px solid var(--main);
        font-weight: bold;
      }

      &.disabled {
        background-color: var(--gray);
        color: #fff;
      }

      &:hover {
        background-color: #cbd5e2;
        border-bottom: 3px solid var(--main);
        color: white;
      }
    }
  }
`;

export const ComposerTabContentSection = styled.section`
  background-color: #fff;
  max-height: 70vh;
  padding-left: 5px;
  padding-right: 5px;
  overflow: hidden scroll;
  width: 100%;
`;

export const ComposerTabContentComponent = styled.div<{ $activeTabContent: boolean }>`
  padding-top: 28px;
  display: ${props => (props.$activeTabContent ? "block" : "none")};
`;

export const ComposerTabContentActionIcons = styled.div<{ $show: boolean }>`
  display: ${props => (props.$show ? "flex" : "none")};
  position: sticky;
  padding: 0;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  background-color: rgb(193, 208, 234);
  z-index: 400;
  height: 20px;
  min-width: 20px;
  line-height: 0.6;
`;

export const CloseIcon = styled(CgClose)`
  cursor: pointer;
  height: 12px;
  width: 12px;
`;

export const BackIcon = styled(TbArrowBackUp)`
  cursor: pointer;
  height: 20px;
  width: 20px;
`;

export const AbsoluteDiv = styled.div<{ $position: "left" | "right" }>`
  position: absolute;
  left: ${props => (props.$position === "left" ? "15px" : "unset")};
  right: ${props => (props.$position === "right" ? "20px" : "unset")};
  top: 10px;
`;
