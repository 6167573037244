import styled from "styled-components";
import { SVGRenderer } from "./svg-renderer";
import { IconProps } from "./types";

const UnstyledXeniaLetsMarker: React.FC<IconProps> = ({
  className,
  color = "rgb(25, 47, 84)",
  size,
}) => {
  return (
    <div className={className}>
      <SVGRenderer size={size}>
        <g
          transform={"translate(0.000000,98.000000) scale(0.100000,-0.100000)"}
          fill={color}
          stroke={"none"}
        >
          <path
            d={`M289 937 c-107 -30 -200 -114 -241 -216 -17 -44 -22 -77 -23 -151 0
-145 22 -183 225 -385 l161 -160 158 160 c200 203 216 232 216 385 0 99 -2
110 -33 172 -40 82 -111 148 -190 179 -70 27 -206 35 -273 16z m214 -57 c83
-26 155 -88 194 -168 24 -49 28 -69 28 -142 0 -125 -17 -156 -183 -322 l-136
-138 -123 122 c-68 68 -123 128 -123 135 0 8 12 13 28 13 21 0 48 -21 122 -95
l95 -95 108 107 c130 128 157 176 157 273 0 239 -281 357 -449 189 -61 -61
-89 -146 -76 -233 l7 -46 184 0 184 0 0 78 0 77 -56 33 -56 32 -54 -30 -53
-31 -3 -57 c-3 -54 -4 -57 -30 -60 l-28 -3 0 75 0 75 78 47 c42 26 82 47 88
47 6 0 48 -21 93 -47 l81 -48 0 -124 0 -124 -234 0 -234 0 -17 65 c-21 78 -16
144 18 218 44 96 115 156 216 183 66 18 100 17 174 -6z`}
          />
        </g>
      </SVGRenderer>
    </div>
  );
};

export const XeniaLetsMarker = styled(UnstyledXeniaLetsMarker)``;
