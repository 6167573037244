import { ReactNode } from "react";
import { SCardContainer } from "./style";

interface ICard {
  children: ReactNode;
  width?: string | number;
}

const Card = ({ children, width = "100%" }: ICard) => {
  return <SCardContainer $width={width}>{children}</SCardContainer>;
};

export default Card;
