import React from "react";
import styled from "styled-components";
import { MapMarker } from "../Iconography";
import { Button } from "../Button";
import { Coords } from "./types";

const markerSize = "46px";
const defaultFillColour = "rgb(238, 75, 43)";

export type Props = Coords & {
  children?: React.ReactNode;
  className?: string;
  Icon?: React.ReactNode;
  infoWindow?: React.ReactElement;
  onClick?: () => void;
  showInfoWindow?: boolean;
};

const UnStyledMarker: React.FC<Props> = ({
  children,
  className,
  Icon = <MapMarker styles={{ fill: defaultFillColour }} />,
  infoWindow,
  lat,
  lng,
  onClick,
  showInfoWindow = false,
}) => {
  const component = children ?? Icon;
  return (
    <div className={className}>
      {showInfoWindow &&
        infoWindow &&
        React.cloneElement(infoWindow, {
          lat,
          lng,
          markerSize,
        })}
      <Button styles={{ padding: 0, minWidth: 0 }} onClick={onClick}>
        {component}
      </Button>
    </div>
  );
};

export const Marker = styled(UnStyledMarker)`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
