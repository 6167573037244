const BASE_URL = process.env.REACT_APP_BASE_URL; // Default to this

export const AppConstant = {
  BASE_API_URL: `${BASE_URL}/api`,
  BASE_URL: BASE_URL,
  AWS_CONNECT_BASE_INSTANCE_URL: "https://xenia-london.awsapps.com/connect/ccp-v2/",
  AWS_CONNECT_REGION: "eu-west-2",
  GET_ADDRESS_API_KEY: process.env.REACT_APP_GET_ADDRESS_API_KEY,
  GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
};
