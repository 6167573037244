import * as Styled from "./style";
import { ComposerType, IComposerTabs } from "./types";
import { ComposeTabKeys, TabMap } from "./tabs";
import { ButtonGroup } from "xl-components";
import { ButtonEventFn } from "components/ButtonGroup/buton-group";

export const ComposeTabButtonGroup = ({
  onButtonClicked,
  className,
}: {
  composerTabs: TabMap;
  className?: string;
  onButtonClicked: (method: IComposerTabs) => void;
}) => {
  const handleButtonClicked: ButtonEventFn = (label, e, index) => {
    const composerTab = ComposeTabKeys[Number(index)];
    onButtonClicked(composerTab);
  };
  return (
    <Styled.ButtonGroupSection className={className}>
      <ButtonGroup
        data={[
          ComposerType.Call,
          ComposerType.Email,
          ComposerType.WhatsApp,
          ComposerType.SMS,
          ComposerType.Task,
          ComposerType.Note,
        ]}
        variant="outlined"
        id="composer-nonmodal"
        buttonStyles={{
          borderColor: "#fff",
          color: "#fff",
          ":hover": {
            borderColor: "#fff",
            color: "#fff",
          },
        }}
        onClick={handleButtonClicked}
      />
    </Styled.ButtonGroupSection>
  );
};
