import { FormControlLabel as MUIFormControlLabel, SxProps } from "@mui/material";
import styled from "styled-components";

export type Props = {
  anchorLabel?: "bottom" | "end" | "start" | "top";
  className?: string;
  component: React.ReactElement;
  disabled?: boolean;
  label?: string;
  styles?: SxProps;
};

const UnstyledFormControlLabel: React.FC<Props> = ({
  anchorLabel = "end",
  className,
  component,
  disabled,
  label,
  styles,
}) => {
  return (
    <MUIFormControlLabel
      className={className}
      control={component}
      disabled={disabled}
      label={label}
      labelPlacement={anchorLabel}
      sx={styles}
    />
  );
};

export const FormControlLabel = styled(UnstyledFormControlLabel)``;
