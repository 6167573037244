import React, { useEffect } from "react";
import { IXLFormFragmentProps, useXLFormFragment } from "containers/XLFormPage";
import { useFieldArray } from "react-hook-form";
import { Box, Button, Grid, InputAdornment } from "@mui/material";
import { TextFieldElement } from "react-hook-form-mui";
import { BiLabel } from "react-icons/bi";

import { CustomUploadInput } from "components/XLFormComponents/CustomUploadInput";
import { TiTimes } from "react-icons/ti";
import { AiOutlineLink, AiOutlinePlus } from "react-icons/ai";
import { IXenialetsAPIAttachment } from "types/attachments";

export const PhotosFloorPlansVideosFragment = (
  props: IXLFormFragmentProps & {
    uploadedImages: IXenialetsAPIAttachment[];
    uploadedAttachments: IXenialetsAPIAttachment[];
  },
) => {
  const { XLFormFragment, getPrefixedFieldName } = useXLFormFragment({
    namePrefix: props.namePrefix,
    formMode: props.formMode,
    disableFieldsOnEdit: props.disableFieldsOnEdit,
  });

  const {
    fields: videoArrayFields,
    append: appendVideosArrayFieldItem,
    remove: removeVideosArrayFieldItem,
  } = useFieldArray({
    control: props.useFormReturn?.control, // control props comes from useForm (optional: if you are using FormContext)
    name: getPrefixedFieldName("attachment_urls"), // unique name for your Field Array
  });

  return (
    <XLFormFragment>
      <Box sx={{ my: "10px" }}>
        <Grid container>
          <Grid md={1} item xs={6}>
            Images
          </Grid>
          <Grid md={11} xs={6} item data-testid={"attach_images"}>
            <CustomUploadInput
              uploadedFiles={props.uploadedImages}
              control={props.useFormReturn?.control}
              name="attachment_images"
              accept={{ "image/jpeg": [], "image/png": [] }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ my: "10px" }}>
        <Grid container>
          <Grid md={1} item xs={6}>
            Floor Plan
          </Grid>
          <Grid md={11} item xs={6}>
            <CustomUploadInput
              data-testid={"attach_images"}
              control={props.useFormReturn?.control}
              name="floor_plans"
              uploadedFiles={props.uploadedAttachments}
              accept={{ "application/pdf": [] }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Grid container>
          <Grid item md={1} xs={12}>
            Videos
          </Grid>
          <Grid item md={11} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                {videoArrayFields.map((field, index) => (
                  <Box key={field.id} sx={{ my: "10px" }}>
                    <Grid container spacing={1}>
                      <Grid item xs={10} md={11}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={7}>
                            <TextFieldElement
                              fullWidth
                              label="URL"
                              name={`attachment_urls.${index}.url`}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <AiOutlineLink />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <TextFieldElement
                              fullWidth
                              type="url"
                              label="Caption"
                              name={`attachment_urls.${index}.caption`}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <BiLabel />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={2} md={1}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width: "100%",
                          }}
                        >
                          <TiTimes
                            style={{ cursor: "pointer" }}
                            onClick={() => removeVideosArrayFieldItem(index)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        appendVideosArrayFieldItem({
                          url: "",
                          caption: "",
                          id: "",
                        })
                      }
                    >
                      <AiOutlinePlus fontSize={18} />
                      Add URL
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </XLFormFragment>
  );
};
