import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}
/** This is needed because unfortunately with normal Material UI TabPanel implementation ,
 * each tab panel is unmounted as focus shifts to another tabpanel by clicking on the tab
 *
 * https://stackoverflow.com/a/61097886/3403972
 */
const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
};

export { TabPanel };
