import React from "react";

import { XLFormFragmentGroup } from "containers/XLFormPage";
import { FieldValues } from "react-hook-form";
import { PhotosFloorPlansVideosFragment } from "components/PropertyCreation/FormFragments";
import { IPhotosFloorPlansVideosFragmentGroupProps } from "./types";

export const PhotosFloorPlansVideosFragmentGroup = <T extends FieldValues = Record<string, any>>(
  props: IPhotosFloorPlansVideosFragmentGroupProps<T>,
) => {
  const { uploadedAttachments, uploadedImages, ...fragmentGroupProps } = props;
  return (
    <XLFormFragmentGroup {...fragmentGroupProps}>
      <PhotosFloorPlansVideosFragment
        uploadedAttachments={uploadedAttachments}
        uploadedImages={uploadedImages}
      />
    </XLFormFragmentGroup>
  );
};
