const storagePrefix = "";

const storage = {
  getToken: () => {
    const token = window.localStorage.getItem(`${storagePrefix}access_token`);

    if (token) return token;

    return null;
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}access_token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}access_token`);
  },
};

export { storage };
