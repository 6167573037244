import { XLFormModes } from "containers/XLForm";
import { useXLFormPage } from "containers/XLFormPage";
import { HTTPMethods } from "hooks/useMakeRequest";

import { useEffect } from "react";

import { AppConstant } from "xlconstants";
import { BaseListingSummaryAndDescriptionFragmentGroup } from "components/PropertyCreation/FragmentGroups/BaseListingSummaryAndDescriptionFragmentGroup";
import {
  IBaseListingLikeSummaryAndDescriptionFormPageInputs,
  IBaseListingLikeSummaryAndDescriptionFormPageProps,
} from "./types";

export const BaseListingLikeSummaryAndDescriptionFormPage = (
  props: IBaseListingLikeSummaryAndDescriptionFormPageProps,
) => {
  const { useFormReturn, XLFormPage } =
    useXLFormPage<IBaseListingLikeSummaryAndDescriptionFormPageInputs>({
      formGroupRegistrations: [
        {
          editURL: props.editURL,
          editMethod: HTTPMethods.PATCH,
          order: 1,
          dataPrefix: "baseListingLike",
          name: "BaseListingLike",
          skipCreate: true,
          successEditMessage: props?.successMessage || "Update Base Listing Successfully",
          afterSubmit(data) {
            if (typeof props.onSubmitSuccessful === "function") {
              props.onSubmitSuccessful(data);
            }
          },
        },
      ],
    });

  useEffect(() => {
    if (props.baseListingLike?.id) {
      useFormReturn.reset({
        baseListingLike: {
          description: props.baseListingLike?.description,
          summary: props.baseListingLike?.summary,
        },
      });
    }
  }, [props.baseListingLike, useFormReturn]);

  return (
    <XLFormPage
      title={""}
      testid={"update-baselisting"}
      mode={XLFormModes.EDIT}
      createButtonText={""}
      editButtonText={props?.submitButtonText || "Update Base Listing"}
    >
      <BaseListingSummaryAndDescriptionFragmentGroup namePrefix="baseListingLike" />
    </XLFormPage>
  );
};
