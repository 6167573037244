import { IActivity, IActivityStatusUpdate } from "xl-types";
import { callModel } from "./call-model";
import { emailModel } from "./email-model";
import { isEmailGuard } from "./is-email-guard";
import { isMilestoneGuard } from "./is-milestone-guard";
import { isNoteGuard } from "./is-note-guard";
import { isSMSGuard } from "./is-sms-guard";
import { isTaskGuard } from "./is-task-guard";
import { isWhatsappGuard } from "./is-whatsapp-guard";
import { milestoneModel } from "./milestone-model";
import { noteModel } from "./note-model";
import { smsModel } from "./sms-model";
import { taskModel } from "./task-model";
import { whatsappModel } from "./whatsapp-model";

export const activityTransformer = (activity: IActivity) => {
  if (isNoteGuard(activity)) {
    return noteModel(activity);
  }
  if (isEmailGuard(activity)) {
    return emailModel(activity);
  }
  if (isTaskGuard(activity)) {
    return taskModel(activity);
  }
  if (isSMSGuard(activity)) {
    return smsModel(activity);
  }
  if (isWhatsappGuard(activity)) {
    return whatsappModel(activity);
  }
  if (isMilestoneGuard(activity)) {
    return milestoneModel(activity);
  }

  return callModel(activity);
};

export const sortStatusUpdates = (statusUpdates: IActivityStatusUpdate[]) => {
  return statusUpdates.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
  );
};
