import { SxProps } from "@mui/material";
import { Table } from "./table";
import { TableBody } from "./table-body";
import { TableHead } from "./table-head";
import { TableRow } from "./table-row";
import { RenderColumns } from "./renderer/render-column";
import { RenderGroupedColumns } from "./renderer/render-grouped-columns";
import { RenderRow } from "./renderer/render-row";
import { isColumnGuard } from "./utils/is-column-guard";
import { getColumnGroupingConfig } from "./utils/get-column-grouping-config";
import * as Styles from "./styles";
import * as TableTypes from "./types";
import { Size } from "../types";

export type Props<T> = {
  columns: TableTypes.Column<T>[] | TableTypes.Column<T>[][];
  displayTableHead?: boolean;
  gridLines?: boolean;
  id: string;
  label?: string;
  minWidth?: number;
  rows: T[];
  size?: Exclude<Size, "large">;
  styles?: SxProps;
};

export const BasicTable = <T extends TableTypes.Row>({
  columns = [],
  displayTableHead = true,
  gridLines = false,
  id,
  label,
  minWidth = 0,
  rows = [],
  size = "medium",
  styles,
}: Props<T>) => {
  const sxStyling = Object.assign(gridLines ? Styles.gridLines : {}, styles);
  if (isColumnGuard(columns)) {
    return (
      <Table id={id} label={label} minWidth={minWidth} size={size} styles={sxStyling}>
        {displayTableHead && (
          <TableHead>
            <TableRow>{columns.map(RenderColumns)}</TableRow>
          </TableHead>
        )}
        <TableBody>{rows.map(RenderRow(columns))}</TableBody>
      </Table>
    );
  }

  const columnConfig = getColumnGroupingConfig(columns);
  return (
    <Table id={id} label={label} minWidth={minWidth} size={size} styles={sxStyling}>
      {displayTableHead && <TableHead>{columns.flatMap(RenderGroupedColumns)}</TableHead>}
      <TableBody>{rows.map(RenderRow(columnConfig))}</TableBody>
    </Table>
  );
};
