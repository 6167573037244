import { clsx } from "clsx";
import { IAgreedListings } from "xl-types";
import { getColumnConfig } from "./get-column-config";
import { TableProps } from "xl-components";

type IColumn = Pick<TableProps.BaseColumn, "children" | "name"> & { columnSpan?: number };

export const createGroupedColumns =
  (listings: IAgreedListings[], bedroomCount: number) => (title: IColumn) => {
    const columns = [[title]];
    const columnHeaders = [];

    for (const { price_data } of listings) {
      for (const { bedroom_count } of price_data) {
        columnHeaders.push({
          children: `${bedroom_count} Bed`,
          className: clsx({ "is-highlighted": bedroomCount === bedroom_count }),
          name: getColumnConfig(bedroom_count),
        });
      }
      break;
    }
    columns.push(columnHeaders);
    return columns;
  };
