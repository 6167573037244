import * as Api from "api";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ComposeTabButtonGroup } from "./button-group";

import * as Styled from "./style";
import { useComposeModal } from "./context";
import { FloatingOptions, FloatingOptionsTrigger } from "containers/TrelloBoard/Board/styles";
import { FaWrench } from "react-icons/fa";
import { IComposerTabs } from "./types";
import { ComposerTabs } from "./tabs";
import { ComposeTabContainer } from "./tab-container";
import { ComposeTabContentActions } from "./tab-actions";
import { isEmailGuard, emailModel } from "xl-utils";
import { IActivityEmail, IGenericServiceRequestAPIData } from "xl-types";
import { LinkedObjectContentType } from "components/Notes";

export interface IComposeModalProps {
  baseMessageThreadId: string;
  linkedObjectContentType: LinkedObjectContentType;
  serviceRequest: IGenericServiceRequestAPIData;
  subject?: string;
  recipientList?: string;
}

export const ComposerModal = (props: IComposeModalProps) => {
  const [showComposerModal, setShowComposerModal] = useState(false);
  const [showTabContent, setShowTabContent] = useState(false);
  const { activeComposeType, setActiveComposeType, setActivityObject } = useComposeModal();
  const queryClient = useQueryClient();

  const { data: replyTemplates } = useQuery({
    queryKey: ["get-reply-templates", props.baseMessageThreadId],
    queryFn: async () => {
      const { data } = await Api.activity.getReplyTemplates();
      return data;
    },
  });

  const { data: bmtData, refetch: _refetchBmtData } = useQuery({
    queryKey: ["base-message-thread", props.baseMessageThreadId],
    queryFn: async () => {
      const { data } = await Api.activity.geActivities(props.baseMessageThreadId);
      return data;
    },
  });

  const { data: activities } = useQuery({
    enabled: !!props.baseMessageThreadId,
    queryKey: ["get-activities", props.baseMessageThreadId],
    queryFn: async () => {
      const { data } = await Api.activity.geActivitiesV2(props.baseMessageThreadId);
      return data.activities;
    },
  });

  const filteredReplyTemplates = replyTemplates?.filter(
    (template: any) =>
      template.category_by_linked_object === bmtData?.primary_linked_object_type_string ||
      !template.category_by_linked_object,
  );

  const lastInboundMessage =
    activities &&
    (activities.find(activity => {
      return isEmailGuard(activity) && !activity.is_outgoing;
    }) as IActivityEmail);

  // When the context updates, show the composer type
  useEffect(() => {
    if (activeComposeType) {
      setShowComposerModal(true);
      setShowTabContent(true);
    }
  }, [activeComposeType]);

  const handleTabButtonClicked = (method: IComposerTabs) => {
    setActivityObject(undefined);
    setActiveComposeType(method);
    setShowTabContent(true);
  };
  const handleRefreshThread = () => {
    _refetchBmtData();
  };
  const closeComposeContent = () => {
    setActiveComposeType(undefined);
    setActivityObject(undefined);
    setShowComposerModal(false);
    setShowTabContent(false);
  };
  const componentProps = {
    // This is the props that each tab component would receive
    baseMessageThread: bmtData,
    serviceRequest: props.serviceRequest,
    linkedObjectContentType: props.linkedObjectContentType,
    lastInboundMessage: lastInboundMessage && emailModel(lastInboundMessage),
    handleRefreshThread: handleRefreshThread,
    bmtReplyFormSubject: props.subject as string,
    bmtReplyFormRecipientList: props.recipientList as string,
    replyTemplates: filteredReplyTemplates,
    onCompleted() {
      closeComposeContent();
      queryClient.invalidateQueries(["base-message-thread"]);
      queryClient.invalidateQueries(["get-activities"]);
      queryClient.invalidateQueries(["sr-internal-comments"]);
    },
  };
  return (
    <>
      {!showComposerModal && (
        <FloatingOptions data-testid="floating-actions-trigger">
          <FloatingOptionsTrigger
            onClick={() => {
              setShowComposerModal(prev => !prev);
            }}
          >
            <FaWrench />
          </FloatingOptionsTrigger>
        </FloatingOptions>
      )}
      {showComposerModal && (
        <Styled.ComposerModalSection>
          <ComposeTabContentActions
            onBackButtonClick={() => {
              setActiveComposeType(undefined);
              setActivityObject(undefined);
              if (showTabContent) {
                // only hide the tab content but show the buttons
                setShowTabContent(false);
              } else {
                // at this point, exit the composer modal completely
                setShowComposerModal(false);
              }
            }}
            onCloseComposeContent={closeComposeContent}
          />
          {showTabContent ? (
            activeComposeType && (
              <ComposeTabContainer
                currentComposerTab={ComposerTabs[activeComposeType.label]}
                componentProps={componentProps}
              />
            )
          ) : (
            <ComposeTabButtonGroup
              onButtonClicked={handleTabButtonClicked}
              composerTabs={ComposerTabs}
            />
          )}
        </Styled.ComposerModalSection>
      )}
    </>
  );
};
