import styled from "styled-components";

export const SnackbarContainer = styled.div`
  position: fixed;
  z-index: 1400;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &.snackbar-top-left {
    top: 0;
    left: 0;
  }

  &.snackbar-bottom-left {
    bottom: 0;
    left: 0;
  }

  &.snackbar-top-center {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.snackbar-bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &.snackbar-top-right {
    top: 0;
    right: 0;
  }

  &.snackbar-bottom-right {
    bottom: 0;
    right: 0;
  }
`;
