import { Container } from "react-bootstrap";
import styled from "styled-components/macro";

export const SApp = styled.div`
  padding: 50px;
  width: 40%;
`;

export const SContainer = styled(Container)`
  display: flex;
  background-color: var(--accent);
  height: 100vh;
  width: 100%;
  padding: 0;
  @media (max-width: 768px) {
    margin-top: 55px;
  }
`;

export const SLoggedInAs = styled.div`
  margin-top: auto;

  p {
    font-size: 0.85rem;
    margin: 0;
  }
`;

export const CloseMUIDrawerButtonView = styled.button`
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
`;

export const CloseIconView = styled.div`
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
`;

export const MobileNavItems = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const DesktopMenuNavItems = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;
