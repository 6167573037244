import * as React from "react";
import { SxProps, Table as MUITable, TableContainer as MUITableContainer } from "@mui/material";
import { Padding, Size } from "../types";

type Props = {
  children: React.ReactNode;
  className?: string;
  label?: string;
  id: string;
  minWidth?: number;
  padding?: Padding;
  ref?: React.RefObject<HTMLTableElement>;
  size?: Exclude<Size, "large">;
  stickyHeader?: boolean;
  styles?: SxProps;
};

export const Table: React.FC<Props> = ({
  children,
  className,
  id,
  label,
  minWidth = 250,
  padding = "normal",
  ref,
  size = "medium",
  stickyHeader = false,
  styles,
}) => {
  const identifier = `${id}-Table`;
  return (
    <MUITableContainer sx={styles}>
      <MUITable
        aria-label={label}
        className={className}
        data-testid={identifier}
        id={identifier}
        padding={padding}
        ref={ref}
        size={size}
        stickyHeader={stickyHeader}
        sx={{ minWidth }}
      >
        {children}
      </MUITable>
    </MUITableContainer>
  );
};
