import styled from "styled-components";

export const SnackbarItemWrapper = styled.div`
  background-color: rgb(193, 208, 234);
  border: 1px solid var(--gray);
  padding: 1rem 1.5rem;
  width: 450px;
  animation-name: slideUp;
  animation-duration: 0.1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  @keyframes slideUp {
    from {
      transform: translateY(110%);
    }

    to {
      transform: translateY(0%);
    }
  }
`;
