import React from "react";
import * as Styled from "./style";
import { IComposerTypeComponentProps, IComposerTabs } from "./types";

interface IComposeTabContentProps {
  componentProps: IComposerTypeComponentProps;
  currentComposerTab: IComposerTabs;
}

export const ComposeTabContainer = ({
  componentProps,
  currentComposerTab,
}: IComposeTabContentProps) => {
  return (
    <Styled.ComposerTabContentSection>
      <Styled.ComposerTabContentComponent $activeTabContent={true} key={currentComposerTab?.label}>
        {componentProps.baseMessageThread && currentComposerTab && (
          <currentComposerTab.Component {...componentProps} />
        )}
      </Styled.ComposerTabContentComponent>
    </Styled.ComposerTabContentSection>
  );
};
