import { DevTool } from "@hookform/devtools";
import React, { BaseSyntheticEvent, ReactNode, useState } from "react";
import { FieldValues, SubmitHandler, useForm, UseFormProps, UseFormReturn } from "react-hook-form";
import * as Styled from "./styles";
import Box, { BoxProps } from "@mui/material/Box";
// import { useMakeFormRequest } from "./queries";
import { axios } from "libconfigs/axios";
import { useMutation } from "@tanstack/react-query";
import { Typography } from "@mui/material";
import { FormContainer } from "react-hook-form-mui";
import flattenObject from "utils/flattenObject";

export enum XLFormStateTypes {
  ERROR = "error",
  SUCCESS = "success",
  INFO = "info",
}

export enum XLFormModes {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

/***
 * Form mode create - POST only, needs backend Url
 * form mode edit - PUT or PATCH, needs backend Url
 * form mode view - No method, no need for backend Url
 */

interface XLFormProps<T extends FieldValues = Record<any, any>> {
  onSubmit?: (e: BaseSyntheticEvent<T, any, any>) => void | Promise<void>;

  /** This is a message that is shown when the form is successfully submitted */
  successMessage?: string;

  /** This is BOTH the title that is shown at top of the form UI and also the success or error message refers to this. this is the object's type name like "User" or "Tenant Enquiry" etc*/
  title: string;

  mode: XLFormModes;

  /** Provides a standard white background, padding=2 margin=2 - but you can override it with another MUI sx to have a custom layout of the form */
  boxProps?: BoxProps;

  children?: ReactNode | ReactNode[];

  /** this is the final URL - the calling component should pass final URL */
  backendUrlToCallOnSubmit: string;

  /**specify which HTTP method - note that CREATE formMode needs to go with POST, PUT/PATCH needs to go with UPDATE , and VIEW mode goes "" but put "GET" for now **/
  method: "post" | "delete" | "patch" | "put";

  beforeSubmit?: ((data: T) => T) | ((data: T) => { isInvalid: boolean });
  afterSubmit: (data: T, submitResponse: T & Record<string, any>, completeData: T) => void;
}

/**
 *
 * @param useFormProps pass anything that you use pass to a normal useForm() if you are using RHF straight out of the book,
 *  anything that goes there will be accepted here - like defaultValues, etc
 *
 * @returns returns an instance of XLForm that in essence is the FormContainer and also all the methods of useForm() that
 * can be used to get getValues() or watch() etc
 */
const useXLForm = <T extends FieldValues>(useFormProps: UseFormProps<T>) => {
  const useFormReturn = useForm<T>(useFormProps);
  const [formState, setFormState] = useState<{ message: string; type: XLFormStateTypes }>();

  const XLForm = <T extends FieldValues = Record<any, any>>({
    beforeSubmit,
    afterSubmit,
    title,
    mode,
    children,
    method,
    backendUrlToCallOnSubmit = "",
    successMessage,
    boxProps = {
      sx: {
        bgcolor: "white",
      },
    },
  }: XLFormProps<T>) => {
    const { mutateAsync: makeRequestOnSubmit } = useMutation(({ data }: { data: T }) =>
      axios[method]<T>(backendUrlToCallOnSubmit, data),
    );

    const handleFormSubmit = async (data: any) => {
      const modifiedData = typeof beforeSubmit === "function" ? beforeSubmit(data) : data;
      if (modifiedData.isInvalid) return; // don't continue the submission process if the beforeSubmit returns a false data, which means that the custom validation fails or data transformation fails
      setFormState({ message: `Submitting ${title} data`, type: XLFormStateTypes.INFO });
      try {
        const response = await makeRequestOnSubmit({ data: modifiedData as T });
        afterSubmit(data, response.data, data);
        setFormState({
          message: successMessage || `Created ${title} data`,
          type: XLFormStateTypes.INFO,
        });
      } catch (error: any) {
        if (error.response.data) {
          const flattenedError = flattenObject(error.response.data);
          Object.keys(flattenedError).map(key => {
            useFormReturn.setError(key as any, { message: flattenedError[key] });
          });
        }
        setFormState({
          message: `Unable to ${mode} ${title}. Please try again`,
          type: XLFormStateTypes.ERROR,
        });
      }
    };
    return (
      <Box {...boxProps}>
        <FormContainer
          FormProps={{ "aria-label": "form" }}
          formContext={useFormReturn as UseFormReturn}
          handleSubmit={useFormReturn.handleSubmit(handleFormSubmit)}
        >
          <Box
            sx={{ background: "var(--white) 0% 0% no-repeat padding-box" }}
            margin="var(--md)"
            display="flex"
            flexDirection="column"
            gap={2}
          >
            {title && <Typography variant="subtitle1">{title}</Typography>}
            {children}
            {useFormReturn.formState.isSubmitting && <Styled.SubmittingOverlayView />}
            {process.env.NODE_ENV === "development" &&
            process.env.REACT_APP_TEST_MODE !== "true" ? (
              <DevTool control={useFormReturn.control} />
            ) : null}
            <Styled.MessageSectionView $type={formState?.type as XLFormStateTypes}>
              {formState?.message}
            </Styled.MessageSectionView>
            {process.env.NODE_ENV === "development" &&
            process.env.REACT_APP_TEST_MODE !== "true" ? (
              <div>
                {" "}
                Showing errors in development mode:
                <pre>{JSON.stringify(useFormReturn.formState.errors)}</pre>
              </div>
            ) : null}
          </Box>
        </FormContainer>
      </Box>
    );
  };
  return { XLForm, useFormReturn };
};

export default useXLForm;
