import React from "react";

import { XLFormFragmentGroup, IXLFormFragmentProps } from "containers/XLFormPage";
import { FieldValues } from "react-hook-form";
import {
  PropertyLikeDetailsFragment,
  PropertySuitableLandlordFragment,
  PropertyAddressFragment,
} from "components/PropertyCreation/FormFragments";

export const PropertyLikeDetailFragmentGroup = <T extends FieldValues = Record<string, any>>(
  props: IXLFormFragmentProps<T> & {
    mappedPropertyFeatures: Record<string, { id: string; name: string }[]>;
    showSuitableLandlordFragment?: boolean;
    shouldShowLSRFragment?: boolean;
    shouldShowAddressFragment?: boolean;
  },
) => {
  const { mappedPropertyFeatures, showSuitableLandlordFragment, ...fragmentGroupProps } = props;
  return (
    <XLFormFragmentGroup {...fragmentGroupProps}>
      {showSuitableLandlordFragment && <PropertySuitableLandlordFragment />}

      {props.shouldShowAddressFragment && (
        <PropertyAddressFragment disableFieldsOnEdit={["address", "name"]} />
      )}

      <PropertyLikeDetailsFragment mappedPropertyFeatures={mappedPropertyFeatures} />
    </XLFormFragmentGroup>
  );
};
