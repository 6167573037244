import styled from "styled-components";
import { clsx } from "clsx";
import React from "react";

type Props = {
  className?: string;
  value?: number | string;
  isHighlighted?: boolean;
  styles?: string[];
};

const UnStyledTableCellRender: React.FC<Props> = ({
  className,
  value,
  isHighlighted = false,
  styles = [],
}) => {
  return (
    <span className={className}>
      <span
        className={clsx("table-cell", ...styles, {
          highlight: isHighlighted,
        })}
      >
        {value}
      </span>
    </span>
  );
};

export const TableCellRender = styled(UnStyledTableCellRender)`
  .table-cell {
    display: inline-block;
    border-style: none;
    font-size: 12px;

    color: rgb(96, 106, 107);

    scale: 1.2;
    transform-origin: left;
    white-space: nowrap;

    &.dark-text {
      color: rgb(28, 28, 28);
    }

    &.price {
      width: 52px;
      text-align: left;
    }

    &.highlight {
      color: rgb(25, 47, 84);

      scale: 1.4;
    }

    &.agreed-lettings {
      color: rgb(0, 0, 0);
      font-family: "Courier New", Courier, monospace;
      font-size: 16px;

      scale: unset;
    }

    @media (max-width: 768px) {
      text-align: left;

      scale: 1.2;

      &.highlight :not(.agreed-lettings) {
        scale: 1.4;
      }
    }
  }
`;
