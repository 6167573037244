import { IActivityTaskUI, IActivityTask } from "xl-types";
import { getTaskStatus } from "./get-task-status";
import { userModel } from "./user-model";

export const taskModel = (activity: IActivityTask): IActivityTaskUI => {
  const user = userModel(activity.created_by);
  return {
    anchorId: activity.anchor_id,
    content: activity.text,
    hasComment: false,
    id: activity.id,
    objectId: activity.object_id,
    status: {
      name: getTaskStatus(activity),
    },
    time: activity.time_created,
    type: activity.type,
    user: user.fullName,
    triggerTime: activity.absolute_trigger.trigger_time,
    userMentions: activity.user_mentions.map(userModel),
  };
};
