import { ISnackbarItem, Snackbar, TSnackbarPosition } from "containers/Snackbar";
import { createContext, ReactNode, useCallback, useState } from "react";

interface ISnackbarContext {
  addSnackbar: (content: ISnackbarItem["content"], position?: TSnackbarPosition) => void;
  removeSnackbar: (id: ISnackbarItem["id"]) => void;
}

interface ISnackbarProvider {
  children: ReactNode;
}

const SnackbarContext = createContext<ISnackbarContext>({
  addSnackbar: () => {},
  removeSnackbar: () => {},
});

let snackbarId = 1;

const snackbarPositions = [
  "top-left",
  "top-center",
  "top-right",
  "bottom-left",
  "bottom-center",
  "bottom-right",
] as const;

const SnackbarProvider = ({ children }: ISnackbarProvider) => {
  const [snackbars, setSnackbars] = useState<ISnackbarItem[]>([]);

  const addSnackbar: ISnackbarContext["addSnackbar"] = useCallback(
    (content, position = "bottom-center") => {
      setSnackbars(prevSnackbars => [...prevSnackbars, { id: snackbarId++, content, position }]);
    },
    [],
  );

  const removeSnackbar: ISnackbarContext["removeSnackbar"] = useCallback(id => {
    setSnackbars(prevSnackbars => prevSnackbars.filter(item => item.id !== id));
  }, []);

  return (
    <SnackbarContext.Provider value={{ addSnackbar, removeSnackbar }}>
      {snackbarPositions.map(pos => (
        <Snackbar
          key={pos}
          position={pos}
          snackbars={snackbars.filter(item => item.position === pos)}
        />
      ))}
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider, SnackbarContext };
