import { XLFormModes } from "containers/XLForm";
import { useXLFormPage } from "containers/XLFormPage";

import { uploadAllFilesToAPI } from "../uploadFilesUtils";

import { DocumentUploadFragmentGroup } from "components/PropertyCreation/FragmentGroups/DocumentUploadFragmentGroup";
import {
  IPropertyLikeDocumentsFormInputs,
  IPropertyLikeDocumentsUploadFormPageProps,
} from "./types";
import { useEffect, useState } from "react";
import { PropertyFormTypes } from "types/property";

export const PropertyLikeDocumentsFormPage = (props: IPropertyLikeDocumentsUploadFormPageProps) => {
  // also accept the landlord_group

  const { XLFormPage } = useXLFormPage<IPropertyLikeDocumentsFormInputs>({
    formGroupRegistrations: [
      {
        order: 1,
        dataPrefix: "property",
        name: "Property",
        customNetworkRequest: async (data: { epcs: File[]; others: File[] }) => {
          const filesToUpload: any[] = [];
          data.epcs && filesToUpload.push(...data.epcs);
          data.others && filesToUpload.push(...data.others);

          const responses = await uploadAllFilesToAPI(filesToUpload, {
            objectId: props.property?.id as string,
            formType: props.propertyType as PropertyFormTypes,
          });

          return { data: responses };
        },
        successCreateMessage: props.successUploadMessage || "Update Property Successfully",
        afterSubmit(data) {
          if (typeof props.onSubmitSuccessful === "function") {
            props.onSubmitSuccessful(data);
          }
        },
      },
    ],
  });
  const [uploadedEpcs, setUploadedEpcs] = useState<any[]>([]);
  const [uploadedOthers, setUploadedOthers] = useState<any[]>([]);

  useEffect(() => {
    if (props.property) {
      setUploadedEpcs(
        props.property?.attachment_documents?.filter(attachment => attachment.category === "Epc") ||
          [],
      );

      setUploadedOthers(
        props.property?.attachment_documents?.filter(
          attachment => attachment.category === "Others",
        ) || [],
      );
    }
  }, [props.property]);

  return (
    <XLFormPage
      title={props.title as string}
      mode={XLFormModes.CREATE}
      createButtonText={props.submitButtonText}
    >
      <DocumentUploadFragmentGroup
        namePrefix="property"
        uploadedEpcs={uploadedEpcs}
        uploadedOthers={uploadedOthers}
      />
    </XLFormPage>
  );
};
