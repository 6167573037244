import styled from "styled-components/macro";

export const ButtonSectionView = styled.section`
  display: flex;
  align-items: flex-end;
  justify-content: right;
`;

export const TitleView = styled.div`
  color: var(--main);
  transition: all 0.2s ease-in-out;
  margin-bottom: var(--sm);
  margin-top: var(--sm);
`;
