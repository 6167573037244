import React, { createContext, useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { IUser } from "xl-types";

export interface IAuthContext {
  authenticatedUser: IUser | null;
  setAuthenticatedUser: (p: IUser | null) => void;
  userId: string | null;
  userType: string | null;
  isPermittedToSeeAdminElements: boolean;
}

const AuthContext = createContext<IAuthContext>({
  authenticatedUser: null,
  setAuthenticatedUser: () => {},
  userId: null,
  userType: null,
  isPermittedToSeeAdminElements: false,
});

type Props = {
  children: React.ReactNode;
};

const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [userType] = useState(() => {
    const userType = localStorage.getItem("user_type");
    return userType;
  });
  const [token] = useState(() => {
    const accessToken = localStorage.getItem("access_token");
    return accessToken;
  });
  const [authenticatedUser, setAuthenticatedUser] = useState<IUser | null>(null);
  const isPermittedToSeeAdminElements =
    authenticatedUser?.is_superuser ?? authenticatedUser?.is_staff ?? false;

  const { decodedToken } = useJwt<{ user_id: string }>(token ?? "");

  useEffect(() => {
    if (decodedToken) setUserId(decodedToken.user_id);
  }, [decodedToken]);

  const handleSetAuthUser = (user: IUser | null) => {
    setAuthenticatedUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        userId,
        userType,
        authenticatedUser,
        setAuthenticatedUser: handleSetAuthUser,
        isPermittedToSeeAdminElements,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContextProvider, AuthContext };
