import styled from "styled-components/macro";
import App from "./App";

export const AppView = styled(App)`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--main-color);
    font-family: var(--font-family);
    font-size: var(--fonts-main);
    font-weight: normal;
    word-wrap: break-word;
    font-kerning: normal;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "calt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
  }
  p {
    color: var(--colors-paragraph);
    font-size: var(--fonts-paragraph);
    /* letter-spacing: -0.4px; */
  }
  span {
    color: var(--colors-paragraph);
    font-size: var(--fonts-span);
    /* letter-spacing: -0.4px; */
  }
  h1 {
    color: var(--colors-header);
  }
  h2 {
    color: var(--colors-header);
  }
  h3 {
    color: var(--colors-header);
  }
  h4 {
    color: var(--colors-header);
  }
  h5 {
    color: var(--colors-header);
  }
  h6 {
    color: var(--colors-accent);
    text-transform: uppercase;
  }
  a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    text-decoration: none;
    color: var(--colors-link);
    /* letter-spacing: -0.4px; */
  }
  a:active,
  a:hover {
    outline-width: 0;
    text-decoration: underline;
    color: var(--colors-link);
  }
  .lead {
    color: var(--colors-lead);
    font-size: var(--fonts-lead);
    /* letter-spacing: -0.4px; */
  }

  input {
    ::placeholder {
      color: var(--colors-main);
      opacity: 0.6;
    }
  }
`;
