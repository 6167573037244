import { IActivityNoteUI, IActivityNote } from "xl-types";
import { userModel } from "./user-model";

export const noteModel = (activity: IActivityNote): IActivityNoteUI => {
  const user = userModel(activity.created_by);

  return {
    anchorId: activity.anchor_id,
    content: activity.text,
    hasComment: false,
    id: activity.id,
    time: activity.time_created,
    type: activity.type,
    user: user.fullName ?? "",
  };
};
