import { IActivityMilestoneUI, IActivityMilestone } from "xl-types";

export const milestoneModel = (activity: IActivityMilestone): IActivityMilestoneUI => {
  return {
    content: activity.description,
    hasComment: false,
    helpText: activity.help_text,
    summary: activity.summary,
    time: activity.time_created,
    type: activity.type,
  };
};
