import { clsx } from "clsx";
import { v4 as uuidv4 } from "uuid";
import { IAverageRentPrice, IRightMoveListingsUI } from "xl-types";
import { TableCellNumericRenderer, TableCellRender } from "xl-views";

type CreateRightMoveTableData = (
  bedroomCount: number,
) => (acc: IRightMoveListingsUI, val: IAverageRentPrice) => IRightMoveListingsUI;

export const createRightMoveTableData: CreateRightMoveTableData =
  queryBedroomCount =>
  (acc, { bedroom_count, max_price, min_price, property_count }) => {
    const isHighlighted = queryBedroomCount === bedroom_count;

    acc.rows.push({
      bedroomCount: <TableCellRender value={`${bedroom_count} Bed`} styles={["dark-text"]} />,
      className: clsx({ "is-highlighted": isHighlighted }),
      id: uuidv4(),
      propertyCount: <TableCellRender isHighlighted={isHighlighted} value={property_count} />,
      rent: (
        <TableCellNumericRenderer
          isHighlighted={isHighlighted}
          start={min_price}
          end={max_price}
          styles={["right-move"]}
        />
      ),
    });

    return acc;
  };
