import { IActivityTask } from "xl-types";

export const getTaskStatus = ({ completed_on, time_created }: IActivityTask) => {
  const timeCreated = new Date(time_created);
  const today = new Date();

  if (!completed_on && today > timeCreated) {
    return "overdue";
  }

  return completed_on ? "completed" : "future";
};
