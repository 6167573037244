import { ServiceRequestSnackbar } from "containers/Snackbar";
import { XLFormModes } from "containers/XLForm";
import { useXLFormPage } from "containers/XLFormPage";
import { HTTPMethods } from "hooks/useMakeRequest";
import { useSnackbar } from "hooks/useSnackbar";
import { useCallback, useEffect, useMemo } from "react";
import { IXeniaLetsAPIBaseProperty, IXeniaLetsPropertyFeature } from "types/property";

import { AppConstant } from "xlconstants";
import {
  propertySubTypes,
  IPropertyLikeDetailFormInputs,
  PropertyLikeDetailFormPageProps,
} from "./types";

import { PropertyLikeDetailFragmentGroup } from "components/PropertyCreation/FragmentGroups";
import { useGetPropertyFeatures } from "../queries";
import { LSRCommercialFragmentGroup } from "components/PropertyCreation/FragmentGroups";

/** Backend accepts one list of amenities - however in front end form we get the amenities selected by user under multiple groups
 * In this function, we are remapping the data from the form to the format that backend accepts
 * This function is the inverse of remapFeaturesDataFromBackendForForm
 */
const remapFeaturesDataFromFormForBackend = (data: Record<string, any>) => {
  let features: string[] = [];
  const amenities = data.amenities;
  const amenity_subgroups = [
    "parking",
    "accessibility",
    "outside_space",
    "building_amenities",
    "utilities",
    "heating",
  ];

  amenity_subgroups.forEach(subgroup => {
    if (Array.isArray(amenities?.[subgroup]) && amenities[subgroup].length > 0) {
      features = features.concat(amenities?.[subgroup]);
    }
  });
  return features;
};

/** Backend sends one list of amenities - however in front end form we need to pre-populate the amenities under multiple groups
 * In this function, we are remapping the data from the backend to the format the react hooks form accepts
 * This function is the inverse of remapFeaturesDataFromFormForBackend
 */

const remapFeaturesDataFromBackendForForm = (
  groupName: string,
  defaultValues?: IXeniaLetsAPIBaseProperty,
) => {
  const defaultFeatures: string[] = [];
  if (defaultValues?.features) {
    for (let i = 0; i < defaultValues.features.length; i++) {
      const feature = defaultValues.features[i] as IXeniaLetsPropertyFeature;
      if (feature.property_feature_group.name === groupName) {
        defaultFeatures.push(feature.id);
      }
    }
  }
  return defaultFeatures;
};

export const PropertyLikeDetailFormPage = (props: PropertyLikeDetailFormPageProps) => {
  const { addSnackbar } = useSnackbar();

  // also accept the landlord_group

  const { useFormReturn, XLFormPage } = useXLFormPage<IPropertyLikeDetailFormInputs>({
    formGroupRegistrations: [
      {
        createURL: props.createURL || "/properties/",
        createMethod: props.createMethod || HTTPMethods.POST,
        editURL: props.editURL || `/properties/${props.property?.id}/`,
        editMethod: props.editMethod || HTTPMethods.PUT,
        order: 1,
        dataPrefix: "property",
        name: "Property",
        successCreateMessage: props.successCreateMessage || "Created New Property",
        successEditMessage: props.successEditMessage || "Update Property Successfully",
        beforeSubmit: (data, responses) => {
          const features = remapFeaturesDataFromFormForBackend(data);
          // Get the landlord group from the form data if we are creating for an existing landlord or from the service request if we are creating for a new landlord
          const landlordGroupId = props.shouldAllowToCreateForSuitableLandlord
            ? data.landlord_group
            : props.landlordLeadServiceRequest?.landlord_group?.id;

          const llsrs_attributed_to = props.shouldAllowToCreateForSuitableLandlord
            ? [data?.llsr_attributed_to]
            : [props?.landlordLeadServiceRequest?.id];
          return {
            ...data,
            features,
            llsrs_attributed_to,
            landlord_group: landlordGroupId,
          };
        },
        afterSubmit: data => {
          // Because we are skipping the edit for LSR HTTP Request, it is ideal to invoke the props.onSubmitSuccessful at this point
          if (
            props.formMode === XLFormModes.EDIT &&
            typeof props.onSubmitSuccessful === "function"
          ) {
            props.onSubmitSuccessful(data);
          }
        },
      },
      {
        createURL: `${AppConstant.BASE_API_URL}/letting-service-request/`,
        createMethod: HTTPMethods.POST,
        editMethod: HTTPMethods.PUT,
        skipEdit: true,
        order: 2,
        dataPrefix: "tenancy",
        name: "LSR",
        successCreateMessage: "Created New LLSR",
        beforeSubmit: (tenancyData, formData, responses) => {
          console.log({ tenancyData });
          return {
            ...tenancyData,
            property: responses && responses["Property"].id,
            llsrs_attributed_to: props.shouldAllowToCreateForSuitableLandlord
              ? [formData?.property?.llsr_attributed_to]
              : [props?.landlordLeadServiceRequest?.id],
          };
        },
        afterSubmit: lsrResponse => {
          props.onSubmitSuccessful && props.onSubmitSuccessful(lsrResponse);
          addSnackbar(
            <ServiceRequestSnackbar
              link={`/home/letting-service-requests/${lsrResponse.id}`}
              text="New Property Listing Created"
            />,
          );
        },
      },
    ],
  });

  useEffect(() => {
    if (props.property?.id) {
      const rebuiltAmenitiesFromPreviousValues = {
        outside_space: remapFeaturesDataFromBackendForForm("Outside Space", props.property),
        parking: remapFeaturesDataFromBackendForForm("Parking", props.property),
        accessibility: remapFeaturesDataFromBackendForForm("Accessibility", props.property),
        building_amenities: remapFeaturesDataFromBackendForForm(
          "Building Amenities",
          props.property,
        ),
        utilities: remapFeaturesDataFromBackendForForm("Utilities", props.property),
        heating: remapFeaturesDataFromBackendForForm("Heating", props.property),
      };

      const propertyType = props.property?.property_type === "House" ? "1" : "2";

      const propertySubType = propertySubTypes[propertyType].find(
        ({ label }) => label.toLowerCase() === props.property?.property_sub_type?.toLowerCase(),
      ) || { label: "", value: "" };

      useFormReturn.reset({
        property: {
          address: props.property?.address,
          property_type: propertyType,
          property_sub_type: propertySubType.value,
          amenities: rebuiltAmenitiesFromPreviousValues,
          name: props.property?.name,
          bedroom_count: props.property?.bedroom_count || "",
          bathroom_count: props.property?.bathroom_count || "",
          reception_rooms_count: props.property?.reception_rooms_count || "",
          council_tax_band: props.property?.council_tax_band || undefined,
        },
        tenancy: {
          vacant_from_date: props.lettingServiceRequest?.vacant_from_date,
          available_from_date: props.lettingServiceRequest?.available_from_date,
          long_term_monthly_rent: props.lettingServiceRequest?.long_term_monthly_rent,
        },
      });
    }
  }, [props.lettingServiceRequest, props.property]);

  const {
    data: propertyFeaturesResponse,
    isFetched: isPropertyFeaturesResponseFetched,
    refetch,
  } = useGetPropertyFeatures();

  const mappedPropertyFeatures = useMemo(
    () =>
      isPropertyFeaturesResponseFetched &&
      propertyFeaturesResponse?.data?.reduce((accObj: any, currentObj: any) => {
        accObj[currentObj.property_feature_group.name] =
          accObj[currentObj.property_feature_group.name] || [];
        accObj[currentObj.property_feature_group.name].push(currentObj);
        return accObj;
      }, {}),
    [propertyFeaturesResponse?.data, isPropertyFeaturesResponseFetched],
  );

  const onBeforeAllSubmit = useCallback(data => {
    if (data.tenancy.available_from_date && !data.tenancy.long_term_monthly_rent) {
      return {
        isInvalid: true,
        message: "Tenancy Available from date is required if Monthly rent is entered",
      };
    } else if (data.tenancy.long_term_monthly_rent && !data.tenancy.available_from_date) {
      return {
        isInvalid: true,
        message: "Tenancy Monthly rent is required if Available from date is entered",
      };
    }
    return data;
  }, []);

  return (
    <XLFormPage
      testid={"property-details"}
      title={props.title as string}
      mode={props.formMode as XLFormModes}
      createButtonText={props.createButtonText}
      editButtonText={props.editButtonText}
      beforeAllSubmit={onBeforeAllSubmit}
    >
      <PropertyLikeDetailFragmentGroup
        namePrefix="property"
        mappedPropertyFeatures={mappedPropertyFeatures}
        showSuitableLandlordFragment={props.shouldAllowToCreateForSuitableLandlord}
        shouldShowAddressFragment={props.shouldShowAddressFragment}
      />

      {props.shouldShowLSRCommercialFragments && (
        <LSRCommercialFragmentGroup namePrefix="tenancy" />
      )}
    </XLFormPage>
  );
};
