import styled from "styled-components/macro";

export const SHeader = styled.div`
  font-size: var(--xl);
  margin: 0;
  padding: 0.8rem 1rem;
`;

export const SDivider = styled.hr`
  margin: 0;
  border: 0;
  height: 1px;
  background: var(--gray);
`;

export const SFooter = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin: 0;
  padding: 0.8rem 1rem;
`;

export const SFooterGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 5px;
`;

export const SEllipsis = styled.span`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: inherit;
  width: 100%;
  max-width: 600px;
`;
