import { useMutation, useQuery } from "@tanstack/react-query";
import { axios } from "libconfigs/axios";
import { ILSRAPIData } from "types/lsr";

import { IXeniaLetsAPIBaseProperty } from "types/property";
import { IXeniaLetsAPISubListing } from "types/subListing";

export const useGetPropertyFeatures = () =>
  useQuery(["fetch-property-features"], () => axios("/property-features/"), { enabled: true });

export const useCreateLSR = (url: string) => {
  return useMutation(
    ["create-lsr-using-property-id"],
    (data: {
      available_from_date: string;
      long_term_monthly_rent: string;
      property: string;
      llsrs_attributed_to: string[];
      vacant_from_date: string;
    }) => axios.post<ILSRAPIData>(url, data),
  );
};

export const useGetPropertyDetailData = (propertyId?: string, enabled = false) =>
  useQuery(
    ["property-detail", propertyId],
    () => axios.get<IXeniaLetsAPIBaseProperty>(`/properties/${propertyId}/`),
    {
      enabled,
    },
  );

/** Getting detail of BaseListing , aka "Listing" */
export const useGetBaseListingDetails = (baseListingId?: string, enabled = false) =>
  useQuery(
    ["baselisting-detail", baseListingId],
    () => axios.get<IXeniaLetsAPIBaseProperty>(`/base-listing/${baseListingId}/`),
    {
      enabled,
    },
  );

/** Getting detail of SubListing , aka "Listing Variations" */
export const useGetSubListingDetailData = (subListingId?: string, enabled = false) =>
  useQuery(
    ["sublisting-detail", subListingId],
    () => axios.get<IXeniaLetsAPISubListing>(`/sub-listing/${subListingId}/`),
    {
      enabled,
    },
  );

export const useGetLSRDetails = (lsrId?: string, enabled = false) =>
  useQuery(
    ["fetch-lsr-details-with-id", lsrId],
    () => axios.get<ILSRAPIData>(`/letting-service-request/${lsrId}/`),
    {
      enabled,
    },
  );
