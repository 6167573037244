import { InputLabel, Select, MenuItem, SelectChangeEvent, FormControl, Box } from "@mui/material";
import React, { FunctionComponent, ReactNode } from "react";
import { IGenericServiceRequestAPIData } from "types/serviceRequest";
import { SAvatar, SCard, SCardHeader, SDetail, SFlex } from "./style";

interface IBasicInfoCardChildTemplate {
  avatarImage: string;
  header: string;
  details: ReactNode[];
}

export const BasicInfoCardChildTemplate = ({
  avatarImage,
  header,
  details,
}: Partial<IBasicInfoCardChildTemplate>) => {
  return (
    <>
      {avatarImage && (
        <SAvatar>
          <img src={avatarImage} alt="placeholder" />
        </SAvatar>
      )}
      <div>
        <SCardHeader>{header}</SCardHeader>
        {details &&
          details.length > 0 &&
          details?.map((detail, idx) => <SDetail key={`sdetail-${idx}`}>{detail}</SDetail>)}
      </div>
    </>
  );
};

// This needs children that looks similar to BasicInfoCardChildTemplate, ideally use BasicInfoCardChildTemplate and pass props to fill up text
// or create a inner component similar to BasicInfoCardChildTemplate and pass as children
export interface IBasicInfoCard {
  componentToRender: FunctionComponent<{ srData: IGenericServiceRequestAPIData }>;
  srData: IGenericServiceRequestAPIData;
  children?: React.ReactNode;
}

const BasicInfoCard = ({ componentToRender, srData, children }: IBasicInfoCard) => {
  return (
    <SCard data-testid="srBasicInfoCard">
      <SFlex>{React.createElement(componentToRender, { srData: srData })}</SFlex>
      {children}
    </SCard>
  );
};

export default BasicInfoCard;
