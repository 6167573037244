import * as React from "react";
import { TableRow as MUITableRow } from "@mui/material";

type Props = {
  children: React.ReactNode;
  className?: string;
  hoverRow?: boolean;
  id?: string;
};

export const TableRow: React.FC<Props> = ({ children, className, hoverRow = false, id }) => {
  return (
    <MUITableRow id={id} hover={hoverRow} className={className}>
      {children}
    </MUITableRow>
  );
};
