import { Box, Tab, Tabs } from "@mui/material";
import { XLFormModes } from "containers/XLForm";

import {
  PropertyLikeDetailFormPage,
  PropertyLikePhotosFloorPlansVideosFormPage,
  PropertyLikeDocumentsFormPage,
} from "../FormPages";
import { useGetLSRDetails, useGetPropertyDetails } from "../queries";
import { TabPanel } from "./TabPanel";

import { ILLSRAPIData } from "types/llsr";
import { FaSpinner } from "react-icons/fa";
import { PropertyFormTypes } from "types/property";
import { useState, SyntheticEvent } from "react";

interface PropertyCreateModePropsWithLandlordIdProps {
  propertyId?: never;
  landlordGroupId: string;
  landlordLeadServiceRequest?: ILLSRAPIData;
  shouldAllowToCreateForSuitableLandlord?: never;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccesful?: (data: any) => void;
}

interface PropertyCreateModePropsWithSuitableLandlordAllowedProps {
  /** The ID for the property to enable edit mode for */
  propertyId?: never;

  landlordLeadServiceRequest?: never;

  /** This is required only if `shouldAllowToCreateForSuitableLandlord` is not passed. */
  landlordGroupId?: never;

  /** Use this to launch new LSR creation for an existing landlord. This is required only if `landlordGroupId` is not passed. In w*/
  shouldAllowToCreateForSuitableLandlord: boolean;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccesful?: (data: any) => void;
}

// interface PropertyEditModeProps {
//   propertyId: string;
//   landlordGroupId?: never;
//   shouldAllowToCreateForSuitableLandlord?: never;
//   landlordLeadServiceRequest?: never;

//   /** A callback that can be used to hook into the successful HTTP Request  */
//   onSubmitSuccesful?: (data: any) => void;
// }

interface PropertyCreateEditModeProps {
  propertyId: string;
  landlordGroupId?: string;
  landlordLeadServiceRequest?: ILLSRAPIData;

  shouldAllowToCreateForSuitableLandlord?: never;

  /** A callback that can be used to hook into the successful HTTP Request  */
  onSubmitSuccesful?: (data: any) => void;
}

type PropertyCreateEditTabGroupProps =
  | PropertyCreateModePropsWithLandlordIdProps
  | PropertyCreateModePropsWithSuitableLandlordAllowedProps
  // | PropertyEditModeProps
  | PropertyCreateEditModeProps;

/***
 * This is a tab group for creating/editing a property.
 */
const PropertyCreateEditTabGroup = (
  props: PropertyCreateEditTabGroupProps & { title?: string },
) => {
  // Fetches the property using the property id from the props
  const {
    data: fetchedPropertyResponse,
    isLoading: isLoadingProperty,
    refetch: refetchPropertyData,
  } = useGetPropertyDetails(props.propertyId);

  // if there is lsrId, fetch the LSR
  const { data: fetchedLSRResponse } = useGetLSRDetails(
    fetchedPropertyResponse?.data.active_letting_service_request,
  );

  const handleSubmitSuccessful = (data: any) => {
    props.onSubmitSuccesful && props.onSubmitSuccesful(data);
    if (fetchedPropertyResponse?.data?.id) {
      refetchPropertyData();
    }
  };

  const [currentTabIndexValue, setCurrentTabIndexValue] = useState(0);

  // Updates the current tab when the tab label is clicked
  const handleCurrentTabChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTabIndexValue(newValue);
  };

  const tabs = ["Property Details", "Photos/Videos", "Documents"];
  return (
    <Box bgcolor="white">
      {props.propertyId && isLoadingProperty && (
        <div>
          <FaSpinner /> Loading Property ...
        </div>
      )}
      <Tabs
        value={currentTabIndexValue}
        onChange={handleCurrentTabChange}
        aria-label={tabs.join("-")}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tabs.map(title => (
          <Tab label={title} key={title} data-testid={title} />
        ))}
      </Tabs>
      <TabPanel value={currentTabIndexValue} index={0}>
        <PropertyLikeDetailFormPage
          property={fetchedPropertyResponse?.data}
          lettingServiceRequest={fetchedLSRResponse?.data}
          landlordLeadServiceRequest={props?.landlordLeadServiceRequest}
          createButtonText={"Create Property And LSR"}
          editButtonText={"Update Property Details"}
          formMode={fetchedPropertyResponse?.data?.id ? XLFormModes.EDIT : XLFormModes.CREATE}
          shouldAllowToCreateForSuitableLandlord={props.shouldAllowToCreateForSuitableLandlord}
          onSubmitSuccessful={handleSubmitSuccessful}
          shouldShowLSRCommercialFragments={true}
          shouldShowAddressFragment={true}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={1}>
        <PropertyLikePhotosFloorPlansVideosFormPage
          property={fetchedPropertyResponse?.data}
          submitButtonText={"Update Property"}
          propertyType={PropertyFormTypes.PROPERTY}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
      <TabPanel value={currentTabIndexValue} index={2}>
        <PropertyLikeDocumentsFormPage
          property={fetchedPropertyResponse?.data}
          submitButtonText={"Update Property"}
          propertyType={PropertyFormTypes.PROPERTY}
          onSubmitSuccessful={handleSubmitSuccessful}
        />
      </TabPanel>
    </Box>
  );
};

export { PropertyCreateEditTabGroup };
