import { IXLFormFragmentProps } from "containers/XLFormPage/XLFormFragment";
import React, { JSXElementConstructor, ReactElement, ReactNode } from "react";
import { FieldValues } from "react-hook-form";

export interface IXLFormFragmentGroupProps<T extends FieldValues = Record<string, any>>
  extends IXLFormFragmentProps<T> {
  children?:
    | ReactElement<IXLFormFragmentProps<T>, string | JSXElementConstructor<IXLFormFragmentProps<T>>>
    | ReactElement<
        IXLFormFragmentProps<T>,
        string | JSXElementConstructor<IXLFormFragmentProps<T>>
      >[]
    | ReactNode;
}

export const XLFormFragmentGroup = <T extends FieldValues>(props: IXLFormFragmentGroupProps<T>) => {
  return (
    <div>
      {React.Children.map(props.children, child => {
        return child && React.cloneElement(child as ReactElement, { ...props, children: null });
      })}
    </div>
  );
};
