import * as ReactDOM from "react-dom";
import { ISnackbarItem, SnackbarItem } from "../SnackbarItem";
import * as Styled from "./styles";

export type TSnackbarPosition =
  | "top-left"
  | "top-center"
  | "top-right"
  | "bottom-left"
  | "bottom-center"
  | "bottom-right";

interface ISnackbar {
  snackbars: ISnackbarItem[];
  position: TSnackbarPosition;
}

const Snackbar = ({ snackbars = [], position }: ISnackbar) => {
  return snackbars.length > 0
    ? ReactDOM.createPortal(
        <Styled.SnackbarContainer className={`snackbar-${position}`}>
          {snackbars.map(({ id, content }) => (
            <SnackbarItem key={id} id={id} content={content} />
          ))}
        </Styled.SnackbarContainer>,
        document.getElementById("snackbar-container") as HTMLDivElement,
      )
    : null;
};

export { Snackbar };
