import { useMutation, useQueryClient } from "@tanstack/react-query";
import { axios } from "../../libconfigs";

/**
 * Send a PUT request to link a contact with a base message thread.
 *
 * @param {object} param - An object containing:
 * - `contactId` (string): The ID of the contact to be linked.
 * - `bmtId` (string|number): The ID of the base message thread to be linked.
 *
 * @returns A Promise that resolves with the response data from the server.
 *
 */
const linkContactWithBaseMessageThread = ({
  contactId,
  bmtId,
}: {
  contactId: string;
  bmtId: string | number;
}) => {
  return axios.put(`/amazonconnect/contacttracerecord/${contactId}/link-ctr-and-bmt/`, {
    bmt_id: bmtId,
  });
};

/**
 * A React hook that provides a mutation function for linking a contact with a base message thread.
 *
 * @returns An object containing:
 * - `mutate`: A function that initiates the linking process by calling linkContactWithBaseMessageThread.
 * - `isLoading`: A boolean indicating whether the linking process is currently in progress.
 * - `isError`: A boolean indicating whether an error occurred during the linking process.
 * - `error`: An error object containing details of the error, if any.
 * - `reset`: A function to reset the state of the mutation.
 *
 */
const useLinkContactWithBaseMessageThread = () => {
  const queryClient = useQueryClient();

  return useMutation(linkContactWithBaseMessageThread, {
    onSuccess: (data, { contactId, bmtId }) => {
      queryClient.invalidateQueries(["base-message-thread", bmtId]);
    },
  });
};

export { useLinkContactWithBaseMessageThread };
