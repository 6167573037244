import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#192f54",
    },
    secondary: {
      main: "#365a8d",
    },
  },
});
