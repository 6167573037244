import { useQuery } from "@tanstack/react-query";
import { axios } from "libconfigs/axios";
import { useState } from "react";
import { Path, UseFormSetValue } from "react-hook-form";
import {
  AutocompleteElement,
  AutocompleteElementProps,
  FieldValues,
  PathValue,
} from "react-hook-form-mui";

type AutoDefault = {
  label: string;
  id: string;
};

interface AsyncAutocompleteProps<F extends FieldValues>
  extends Omit<
    AutocompleteElementProps<
      F,
      AutoDefault | string | any,
      boolean | undefined,
      boolean | undefined
    >,
    "options"
  > {
  /** query key to store the options fetched in react query */
  reactQueryKey?: string;

  /**Url to call when the component loads to get options list */
  urlToFetchOptionsOnLoad: string;

  /** value should be "setValue" from useFormReturn */
  setFieldValue?: UseFormSetValue<F>;

  onValueChanged?: (data: any) => void;

  optionIdFieldName?: string;

  disabled?: boolean;
}

const AsyncAutocomplete = <TFieldValues extends FieldValues>({
  reactQueryKey,
  urlToFetchOptionsOnLoad,
  setFieldValue,
  onValueChanged,
  optionIdFieldName,
  disabled,
  ...rest
}: AsyncAutocompleteProps<TFieldValues>) => {
  const { data: options } = useQuery([reactQueryKey || urlToFetchOptionsOnLoad], () =>
    axios(urlToFetchOptionsOnLoad).then(({ data }) => data),
  );

  return (
    <AutocompleteElement
      {...rest}
      options={(options || []).map((option: any) => ({
        ...option,
        id: optionIdFieldName ? option[optionIdFieldName] : option.id,
      }))}
      autocompleteProps={{
        ...rest.autocompleteProps,
        disabled: disabled,
        onChange: (event, value: any) => {
          if (value) {
            if (typeof onValueChanged === "function") {
              onValueChanged(value);
            }
          }
        },
      }}
    />
  );
};

export { AsyncAutocomplete };
