import { IAverageRentPrice, IMarketIntelligence } from "xl-types";
import { axios } from "../../libconfigs";

export const getAveragePrices = async (postcode: string) => {
  const { data, status } = await axios.post<IAverageRentPrice[]>(
    "market-data/show-area-intelligence/",
    {
      postcode,
    },
  );
  return { status, data };
};

type Params = {
  bedroom_count: number;
  force_fetch_market_data?: boolean;
  postcode: string;
};

export const getAreaIntelligence = async ({ force_fetch_market_data = false, ...rest }: Params) => {
  const { data, status } = await axios.get<IMarketIntelligence>(
    "market-data/get-area-intelligence/",
    { params: { ...rest, force_fetch_market_data } },
  );

  return { data, status };
};
