import axios, { AxiosRequestConfig } from "axios";
import { makeUseAxios, ResponseValues } from "axios-hooks";
import { AppConstant } from "../xlconstants";

const useAxios = makeUseAxios({
  axios: axios.create({
    baseURL: AppConstant.BASE_API_URL,
  }),
});

export enum HTTPMethods {
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
  GET = "GET",
}

interface IUseMakeRequestConfig {
  url: string;
  method?: HTTPMethods;
  id?: string | number;
  data?: any;
}

const executRequest = (url: string, makeRequest: (config: AxiosRequestConfig) => any) => {
  return (requestConfig: IUseMakeRequestConfig) => {
    // This is the function that is finally executed when a user makes request
    const { url: configURL, id, method, data } = requestConfig;
    let finalURL = url || configURL;

    // if we have an id, then the method is an update method
    if (typeof id === "string" || typeof id === "number") {
      finalURL = `${url}${id}/`;
    }

    return makeRequest({
      url: finalURL,
      method: method,
      data: data,
    });
  };
};

const useMakeRequest: (
  url: string,
) => [
  ResponseValues<any, any, any>,
  (config: IUseMakeRequestConfig) => AxiosRequestConfig,
  () => void,
] = (url: string) => {
  // Initialize axios, but keep the request config in memory until we are done with the hook
  const useAxiosResult = useAxios({}, { manual: true });

  const [responseValues, makeRequest] = useAxiosResult;

  // Return standard useAxios return type/format
  return [responseValues, executRequest(url, makeRequest), () => {}];
};

export default useMakeRequest;
