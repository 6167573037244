import { CallCentreContext } from "contexts/callCentreContext";
import { useContext } from "react";

export const useForwardCallToCallCentre = (phoneNumber?: string, bmtId?: string) => {
  const callCenterContext = useContext(CallCentreContext);

  const handleForwardToCalCentre = (showAmazonConnectContainer = true) => {
    callCenterContext?.handleSetCallCentreState({
      showMatchedUser: false,
      showAmazonConnectContainer: showAmazonConnectContainer,
      callTriggeredFromBMT: {
        bmtIdToLink: bmtId,
        contactPhoneNumber: phoneNumber,
      },
    });
  };

  return handleForwardToCalCentre;
};
