const { format } = new Intl.NumberFormat("en-GB", {
  currency: "GBP",
  maximumFractionDigits: 0,
  style: "currency",
});

export const formatCurrency = (start: number, end?: number) => {
  const minPrice = format(start);

  if (end) {
    const maxPrice = format(end);
    return `${minPrice} - ${maxPrice}`;
  }

  return minPrice;
};
