import { registerPushNotifications } from "./register-push-notifications";

export const registerValidServiceWorker = (swUrl: string) => {
  window.navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.onupdatefound = () => {
        const { installing } = registration;
        if (installing) {
          installing.onstatechange = () => {
            if (installing.state === "installed") {
              if (window.navigator.serviceWorker.controller) {
                // At this point, the old content will have been purged and
                // the fresh content will have been added to the cache.
                // It's the perfect time to display a "New content is
                // available; please refresh." message in your web app.
                console.info("New content is available; please refresh.");
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.info("Content is cached for offline use.");
              }
            }
          };
        }
      };

      return registration;
    })
    .then(registerPushNotifications)
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
};
