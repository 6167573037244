import * as React from "react";
import { Popover as MUIPopover, PopoverOrigin } from "@mui/material";
import styled from "styled-components";

type Props = {
  id?: string;
  triggerElement?: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  anchorEl: Element | null;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
};

const UnstyledPopover: React.FC<Props> = ({
  id,
  children,
  open,
  anchorEl,
  onClose,
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
}) => {
  return (
    <MUIPopover
      id={id}
      data-testid={`Popover-popver-${id}`}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      {children}
    </MUIPopover>
  );
};

export const Popover = styled(UnstyledPopover)``;
