import { IXeniaLetsBaseAPIAddress } from "api/properties/types";
import { XenialetsAPIAttachments } from "./attachments";
import { IXeniaLetsLandlord } from "./landlord";
import { ILSRAPIData } from "./lsr";
import { SCAN_TYPE } from "./area-intelligence";

export type IXeniaLetsAPIPropertyFeatures = IXeniaLetsPropertyFeature[];

export interface IXeniaLetsPropertyFeature {
  id: string;
  name: string;
  property_feature_group: {
    id: string;
    name: string;
  };
}

export type IXeniaLetsPropertyFeatures = IXeniaLetsPropertyFeature[];

export enum CouncilTaxBand {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  H = "H",
  I = "I",
}

export interface IXeniaLetsAPIBaseProperty {
  id?: string;
  name?: string;
  reception_rooms_count?: string;
  bathroom_count?: string;
  bedroom_count?: string;
  property_sub_type?: string;
  entrance_floor?: string;
  floors_count?: string;
  landlord_group?: string | null;
  property_type?: string;
  address?: IXeniaLetsPropertyAddress;
  features?: IXeniaLetsPropertyFeatures | string[]; // API accetps a string array, but returns array of objects
  attachments?: XenialetsAPIAttachments | any[];
  attachment_documents?: XenialetsAPIAttachments | any[];
  attachment_files?: XenialetsAPIAttachments | any[];
  attachment_urls?: XenialetsAPIAttachments | any[];
  attachment_images?: XenialetsAPIAttachments | any[];
  tenancy?: ILSRAPIData;
  llsrs_attributed_to?: string[];
  llsr_attributed_to?: string;
  reception_count?: number;
  primary_landlord?: IXeniaLetsLandlord;
  pkid?: number;
  active_letting_service_request?: string;
  council_tax_band?: CouncilTaxBand;
}

type TempAverageRentPrice = {
  [k in keyof Pick<IXeniaLetsAPIBaseProperty, "bedroom_count">]: number;
};

export type IDynamicMap = {
  address: string;
  property_url: string;
  bedroom_count: number;
  latitude: number;
  longitude: number;
  postcode: string;
  actual_price: number;
  let_agreed_on: string;
  first_listed_on: string;
  scan_type: string;
  scan_type_enum: keyof typeof SCAN_TYPE;
};

export type IDynamicMapUI = {
  actualPrice: string;
  address: string;
  bedroomCount: number;
  date: string;
  isCenter: boolean;
  latitude: number;
  longitude: number;
  postcode: string;
  propertyUrl: string;
  scanTypeEnum: keyof typeof SCAN_TYPE;
  scanType: string;
};

export type IAgreedListings = {
  price_data: IAverageRentPrice[];
  month: number;
};

export type IRightMoveListing = {
  price_data: IAverageRentPrice[];
};

export type IAverageRentPrice = Required<TempAverageRentPrice> &
  Pick<IXeniaLetsAPIBaseProperty, "property_type"> & {
    avg_price: number;
    min_price: number;
    max_price?: number;
    property_count: number;
  };

export type IMarketIntelligence = {
  data: {
    bedroom_count: number;
    last_scan_date: string;
    postcode: string;
    postcode_sector: string;
    results: {
      agreed_listings: IAgreedListings[];
      map_data: {
        center: Pick<IDynamicMap, "latitude" | "longitude">;
        data: IDynamicMap[];
      };
      rental_range_12_month: IAverageRentPrice[];
      rm_live_listings: IRightMoveListing;
      rm_new_instructions: IRightMoveListing;
    };
  };
  refetch_status_message?: string;
};

export type IXeniaLetsPropertyAddress = IXeniaLetsBaseAPIAddress;

export const PropertySubTypes: Record<string, { label: string; value: string }[]> = {
  "1": [
    { label: "TERRACED", value: "1" },
    { label: "SEMI_DETACHED", value: "3" },
    { label: "DETACHED", value: "4" },
    { label: "END_OF_TERRACE", value: "2" },
    { label: "TOWN_HOUSE", value: "22" },
    { label: "COTTAGE", value: "23" },
    { label: "MEWS", value: "5" },
    { label: "VILLA", value: "27" },
    { label: "HMO", value: "512" },
  ],
  "2": [
    { label: "FLAT", value: "8" },
    { label: "GROUND_FLAT", value: "7" },
    { label: "STUDIO", value: "9" },
    { label: "MAISONETTE", value: "11" },
    { label: "GROUND_MAISONETTE", value: "10" },
    { label: "PENTHOUSE", value: "29" },
    { label: "DUPLEX", value: "56" },
    { label: "TRIPLEX", value: "59" },
  ],
};

export enum PropertyFormTypes {
  PROPERTY = "PROPERTY",
  LANDLORDLEAD = "LANDLORDLEAD",
  LETTING = "LETTING",
  BASELISTING = "BASELISTING",
  SUBLISTING = "SUBLISTING",
}
