import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";

export const SLink = styled(Link)``;
export const SContainer = styled(Container)`
  padding: 0;
  overflow: auto;
`;

export const SRow = styled(Row)`
  position: relative;
  z-index: 2;
`;

export const SOutletContainer = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
`;
