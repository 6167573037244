import { useEffect, useState } from "react";
import { isAuthTokenValid } from "../utils/isAuthTokenValid";

export const useAuth = () => {
  const authToken = localStorage.getItem("access_token");
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => Boolean(authToken));

  useEffect(() => {
    if (authToken && isAuthTokenValid(authToken)) {
      setIsAuthenticated(true);
    }
  }, [authToken]);
  return isAuthenticated;
};
