import { registerValidServiceWorker } from "./register-valid-service-worker";
import { checkValidServiceWorker } from "./check-valid-service-worker";
// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);
const serviceWorkerBuildName = "service-worker.js";

export const register = () => {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in window.navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }
    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/${serviceWorkerBuildName}`;
      if (!isLocalhost) {
        // Is not local host. Just register service worker
        registerValidServiceWorker(swUrl);
      } else {
        // This is running on localhost. Lets check if a service worker still exists or not.
        checkValidServiceWorker(swUrl);
      }
    });
  }
};

export const unregister = () => {
  if ("serviceWorker" in window.navigator) {
    window.navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
};
