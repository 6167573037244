export const gridLines = {
  "& thead tr": {
    borderTop: "1px solid rgba(224, 224, 224, 1)",
  },
  "& tbody tr td, & thead tr th": {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
  },
  "& tbody tr td:nth-of-type(1), & thead tr th:nth-of-type(1)": {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
  },
};
