import { OutgoingDeliveryMethod } from "types/baseMessageThread";
import { BMTFormTabItem } from "./bmt-tab-item";

import { NoteTabItem } from "./note-tab-item";
import { IComposerTabs, ComposerType, IComposerTypeComponentProps } from "./types";
import { TaskFormTabItem } from "./task-tab-item";

export type TabMap = { [keyof in ComposerType]: IComposerTabs };

export const ComposerTabs: TabMap = {
  [ComposerType.Call]: {
    label: ComposerType.Call,
    Component: (props: IComposerTypeComponentProps) => (
      <BMTFormTabItem deliveryMethods={[OutgoingDeliveryMethod.PHONE]} {...props} />
    ),
  },
  [ComposerType.Email]: {
    label: ComposerType.Email,
    Component: (props: IComposerTypeComponentProps) => (
      <BMTFormTabItem deliveryMethods={[OutgoingDeliveryMethod.EMAIL]} {...props} />
    ),
  },
  [ComposerType.SMS]: {
    label: ComposerType.SMS,
    Component: (props: IComposerTypeComponentProps) => (
      <BMTFormTabItem deliveryMethods={[OutgoingDeliveryMethod.SMS]} {...props} />
    ),
  },
  [ComposerType.WhatsApp]: {
    label: ComposerType.WhatsApp,
    Component: (props: IComposerTypeComponentProps) => (
      <BMTFormTabItem deliveryMethods={[OutgoingDeliveryMethod.WHATSAPP_TEXT]} {...props} />
    ),
  },
  [ComposerType.Task]: {
    label: ComposerType.Task,
    Component: (props: IComposerTypeComponentProps) => (
      <TaskFormTabItem
        linkedObjectContentType={props.linkedObjectContentType}
        serviceRequestId={props.serviceRequest?.id as string}
        onCompleted={props.onCompleted}
      />
    ),
  },
  [ComposerType.Note]: {
    label: ComposerType.Note,
    Component: (props: IComposerTypeComponentProps) => (
      <NoteTabItem
        linkedObjectContentType={props.linkedObjectContentType}
        serviceRequestId={props.serviceRequest?.id as string}
        onCompleted={props.onCompleted}
      />
    ),
  },
};

// Use number indexed array referencing the key referenced maps for easy access
export const ComposeTabKeys = [
  ComposerTabs[ComposerType.Call],
  ComposerTabs[ComposerType.Email],
  ComposerTabs[ComposerType.WhatsApp],
  ComposerTabs[ComposerType.SMS],
  ComposerTabs[ComposerType.Task],
  ComposerTabs[ComposerType.Note],
];
