import { Box, TextFieldProps } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { ChangeEvent } from "react";
import {
  Control,
  Controller,
  ControllerProps,
  FieldError,
  FieldValues,
  Path,
} from "react-hook-form";

interface IPhoneNumberField<T extends FieldValues = FieldValues>
  extends Omit<TextFieldProps, "name"> {
  validation?: ControllerProps["rules"];
  name: Path<T>;
  parseError?: (error: FieldError) => string;
  control?: any; //TODO: Aleem construct useful type for props
  testid?: string;
}

const PhoneNumberField = <TFieldValues extends FieldValues = FieldValues>({
  validation = {},
  parseError,
  type,
  required,
  name,
  control,
  ...rest
}: IPhoneNumberField<TFieldValues>) => {
  if (required && !validation.required) {
    validation.required = "This field is required";
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field: { value, onChange, onBlur }, fieldState: { invalid, error } }) => (
        <Box my={1}>
          <MuiPhoneNumber
            {...rest}
            name={name}
            value={value ?? ""}
            onChange={ev => {
              onChange(ev);
              if (typeof rest.onChange === "function") {
                rest.onChange(ev as ChangeEvent<HTMLTextAreaElement | HTMLInputElement>);
              }
            }}
            onBlur={onBlur}
            required={required}
            type={type}
            error={invalid}
            inputProps={{ "data-testid": rest.testid }}
            helperText={
              error
                ? typeof parseError === "function"
                  ? parseError(error)
                  : error.message
                : rest.helperText
            }
            defaultCountry="gb"
            variant="outlined"
          />
        </Box>
      )}
    />
  );
};

export { PhoneNumberField };
